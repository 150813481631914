export const ECOMMERCE_CMS_SEEDZ_TECH = "@seedztech.ecommerce.cms"
export const DARK = "dark"
export const BASE = "base"
export const SYS_MODULE = "@ECOMMERCE";

export const LOCALSTORAGE_ROUTES = {
  moduleECOMMERCE: "/moduleECOMMERCE",
  language: "/language",
  theme: "/theme",
  user: "/user",
  email: "/email",
  permissions: "/permissions",
  _id: "/_id",
  profile: "/profile",
  company: "/company"
};

export const GENERIC_ID_ = "GENERIC_ID_";
export const SELECT_EMPHASIS_CATEGORY = "Selecione categorias em destaque";