import { createTheme } from "@mui/material";

import { TEMA_SYS } from "../layout/index.layout";
import { BASE } from "../utils/_index.utils";


export function muiThemeDatePicker() {
  let datePickerTheme = (TEMA_SYS === BASE ? "#000" : "#fff");
  let backgroundJsonColor = (TEMA_SYS === BASE ? "#D6D6D6" : "#444444");
  let themeJsonViewer = (TEMA_SYS === BASE ? "bright:inverted" : "bright");

  const themeDatePicker = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color: datePickerTheme,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: datePickerTheme,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: "#9b999988",
              borderWidth: "2px"
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: datePickerTheme,
            },
            height: "5vh",
            maxWidth: "100%"
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: datePickerTheme,
          },
        },
      },
    },
  });

  return {
    themeDatePicker,
    backgroundJsonColor,
    themeJsonViewer
  };
};