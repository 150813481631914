import { useState } from "react";
import { TTextareaProps } from "../Textarea";

type TTextareaHooksProps = TTextareaProps & {};
type TTTextareaHooksStates = TTextareaHooksProps & {
  classeTextarea: string;
  placeholderTextarea: string;
};

export const TextareaHooks = (props: TTextareaHooksProps): TTTextareaHooksStates => {
  const classeTextarea = `
    bg-cellgrid-background rounded-md w-full h-full p-3 min-h-[19vh] max-w-full relative
    ${props.inValue === true ? "ring-2 ring-[#a53e3e]" : "ring-0"}
  `;

  const placeholderTextarea = "..."

  return {
    ...props,
    classeTextarea,
    placeholderTextarea,
  } as TTTextareaHooksStates;
};