import { toast } from "react-toastify";
import { UpdateCategory } from "../../../../services/categories.services";
import { TCategoriesConfirmEmphasisProps } from "../CategoriesConfirmEmphasis";
import { TCatgoriesEmphasis } from "../../../_index.pages";


type TCategoriesConfirmEmphasisHooksProps = TCategoriesConfirmEmphasisProps & {};
export type TCategoriesConfirmEmphasisHooksStates = TCategoriesConfirmEmphasisHooksProps & {
  updateEmphasisCategory: () => void;
};

export const CategoriesConfirmEmphasisHooks = ({ ...props }: TCategoriesConfirmEmphasisHooksProps): TCategoriesConfirmEmphasisHooksStates => {

  async function updateEmphasisCategory() {

    props.category.isEmphasis = true;
    const resp = await UpdateCategory(props.category._id, props.category);
    if (resp.success) {
      toast.success(`Parabéns! Já está disponível em seu site a categoria ${props.category.name} como destaque.`)
    } else {
      props.confirmEmphasisModal.close();
      props.emphasisModal.close();
      return toast.error(resp.message);
    }

    props.setCategoriesEmphasis([
      ...props.categoriesEmphasis,
      {
        _id: props.category?._id,
        img: props.category?.image,
        category: props.category.name
      } as TCatgoriesEmphasis
    ])

    props.setReloadList(true);
    props.confirmEmphasisModal.close();
    props.emphasisModal.close();
  };


  return {
    ...props,
    updateEmphasisCategory,
  }
};