import { useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  Footer,
  Header,
  SystemMenu,
} from "../components/_index.components";
import { Icons } from "../assets/icons/_index.icons";

type TStructurePages = {
  module: React.ReactNode;
};

export default function StructurePages(props: TStructurePages) {
  window.scrollTo(0, 0);

  const [activeMenu, setActiveMenu] = useState<boolean>(false);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {
        !props.module &&
        <Navigate to="/home" replace={true} />
      }
      <Header
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
      />
      <div className="bg-none w-auto h-auto overflow-y-scroll pl-16 nd:pl-2 bg-slate-100 nd:text-xss relative min-h-screen">
        <div className={`${activeMenu ? "w-[16%]" : "w-[5%] nd:w-[15%]"} absolute top-0 left-0 h-full text-white space-y-5 flex flex-col items-center bg-none nd:hidden`}
          style={{
            boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(5.5px)"
          }}
        >
          <SystemMenu
            setActiveMenu={setActiveMenu}
            activeMenu={activeMenu}
          />
        </div>
        <div className={
          `w-10 h-auto min-w-8 min-h-8 bg-[#c5fff2] p-2 rounded-lg flex justify-center 
          items-center nd:hidden absolute z-20
          ${activeMenu ? "ml-56" : "ml-3"}`
        }>
          <button
            className="bg-none h-full"
            onClick={() => setActiveMenu(!activeMenu)}
          >
            {
              activeMenu
                ? (
                  <Icons.retract size={17} color="#26c59f" />
                ) : (
                  <Icons.expand size={17} color="#26c59f" />
                )
            }
          </button>
        </div>
        <div className="flex justify-end w-full h-auto">
          <div className={`flex justify-start items-start h-auto w-full bg-none p-1 ${!activeMenu ? "" : "blur-sm"}`}
            style={{
              width: !activeMenu ? "100%" : "88%"
            }}
          >
            <div className="flex flex-col w-full h-full rounded-md items-center justify-center pl-[2rem] xl:p-2 nd:pl-0 nd:pb-20">
              {
                props.module
              }
            </div>
          </div>
        </div>
      </div >
      <div
        className="sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden h-[7vh] bg-slate-200 z-10 fixed bottom-0"
        style={{
          boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)"
        }}
      >
        <SystemMenu
          setActiveMenu={setActiveMenu}
          activeMenu={activeMenu}
        />
      </div>
      {/* <Footer /> */}
    </>
  )
};