import { Spinner } from "../../../components/_index.components";
import {
  formatCurrency,
  formatDate
} from "../../../utils/_index.utils";

import {
  SubscribersDetailsPaymentsHooks,
  TSubscribersDetailsHooksStates,
  TSubscribersDetailsPaymentsHooksStates
} from "../../_index.pages";

export type TSubscribersDetailsPaymentsProps = TSubscribersDetailsHooksStates & {};

export default function SubscribersDetailsPayments({ ...props }: TSubscribersDetailsPaymentsProps) {

  const states: TSubscribersDetailsPaymentsHooksStates = SubscribersDetailsPaymentsHooks({ ...props } as TSubscribersDetailsPaymentsProps);

  return (
    <>
      <div className="p-5 w-full h-full nd:p-2">
        <table className="w-full">
          <thead className="bg-gray-300">
            <tr>
              <th className="px-4 py-2">Valor</th>
              <th className="px-4 py-2">Data</th>
            </tr>
          </thead>
          <tbody className="text-center w-full">
            {
              !states.loadingPayments
                ? states.subscriberPayments
                  ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                  ?.map((p, j) => (
                    <tr
                      className={`${j % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'
                        }`}
                      key={j}
                    >
                      <td className="px-4 py-2 text-center">{formatCurrency(p.value)}</td>
                      <td className="px-4 py-2 text-center">{formatDate(p.created_at, { onlyDate: true })}</td>
                    </tr>
                  ))
                : <Spinner />
            }
          </tbody>
        </table>
      </div>
    </>
  )
};