import { useEffect, useState } from "react";
import { TProductsFormProps } from "../ProductsForm";
import { ICategory, IProduct } from "../../../../interfaces/_index.interfaces";
import { GetCategoryList, ProductRegister, UpdateProduct } from "../../../../services/_index.services";
import { PRODUCT_UNIT_MEASUREMENT } from "../../../../utils/products.utils";
import { toast } from "react-toastify";

type TProductsFormHooksProps = TProductsFormProps & {};
export type TProductsFormHooksStates = TProductsFormHooksProps & {
  handleRecordProduct: () => void;
  productCategory: string;
  productName: string;
  productDescription: string;
  productEan: string;
  // productSlug: string;
  productErpId: string;
  productDepartment: string;
  productBrand: string;
  productRealPrice: string;
  productPromotionPrice: string;
  productEmphasis: boolean;
  productTechnicalInformation: string;
  productTag: string[];
  productImage: any[];
  productUnitMeasurement: string;
  productWeight: string;
  productWidth: string;
  productHeigth: string;
  productDepth: string;
  productVisible: boolean;
  categoryList: ICategory[];
  setProductCategory: (d: string) => void;
  setProductName: (d: string) => void;
  setProductDescription: (d: string) => void;
  setProductEan: (d: string) => void;
  // setProductSlug: (d: string) => void;
  setProductErpId: (d: string) => void;
  setProductDepartment: (d: string) => void;
  setProductBrand: (d: string) => void;
  setProductRealPrice: (d: string) => void;
  setProductPromotionPrice: (d: string) => void;
  setProductEmphasis: (d: boolean) => void;
  setProductTechnicalInformation: (d: string) => void;
  setProductTag: (d: string[]) => void;
  setProductImage: (d: any[]) => void;
  setProductUnitMeasurement: (d: string) => void;
  setProductWeight: (d: string) => void;
  setProductWidth: (d: string) => void;
  setProductHeigth: (d: string) => void;
  setProductDepth: (d: string) => void;
  setProductVisible: (d: boolean) => void;
};

export const ProductsFormHooks = ({ ...props }: TProductsFormHooksProps): TProductsFormHooksStates => {
  const initialValue = 0;

  const [productCategory, setProductCategory] = useState<string>("");
  // const [productCompany, setProductCompany] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [productDescription, setProductDescription] = useState<string>("");
  const [productEan, setProductEan] = useState<string>("");
  // const [productSlug, setProductSlug] = useState<string>("");
  const [productErpId, setProductErpId] = useState<string>("");
  const [productDepartment, setProductDepartment] = useState<string>("");
  const [productBrand, setProductBrand] = useState<string>("");
  const [productRealPrice, setProductRealPrice] = useState<string>(initialValue.toFixed(2));
  const [productPromotionPrice, setProductPromotionPrice] = useState<string>(initialValue.toFixed(2));
  const [productEmphasis, setProductEmphasis] = useState<boolean>(false);
  const [productTechnicalInformation, setProductTechnicalInformation] = useState<string>("");
  const [productTag, setProductTag] = useState<string[]>([]);
  const [productImage, setProductImage] = useState<any[]>([]);
  const [productUnitMeasurement, setProductUnitMeasurement] = useState<string>(PRODUCT_UNIT_MEASUREMENT.G);
  const [productWeight, setProductWeight] = useState<string>("");
  const [productWidth, setProductWidth] = useState<string>("");
  const [productHeigth, setProductHeigth] = useState<string>("");
  const [productDepth, setProductDepth] = useState<string>("");
  const [productVisible, setProductVisible] = useState<boolean>(true);
  // const [productCreatedAt, setProductCreatedAt] = useState<string>("");
  // const [productUpdatedAt, setProductUpdatedAt] = useState<string>("");

  const [categoryList, setCategoryList] = useState<ICategory[]>([]);


  useEffect(() => {
    async function getCategoryList() {
      const resp = await GetCategoryList();
      setCategoryList(resp);
    };

    getCategoryList();
  }, []);


  async function handleRecordProduct() {
    const obj = {
      _id: props.featuredProduct?._id,
      brand: productBrand,
      category: productCategory,
      company: props.authState?.user?.company,
      department: productDepartment,
      depth: productDepth,
      description: productDescription,
      ean: productEan,
      emphasis: productEmphasis,
      erpId: productErpId,
      heigth: productHeigth,
      name: productName,
      promotionPrice: productPromotionPrice ? parseFloat(productPromotionPrice) : null,
      realPrice: productRealPrice ? parseFloat(productRealPrice) : null,
      tag: productTag,
      technicalInformation: productTechnicalInformation,
      unitMeasurement: productUnitMeasurement,
      visible: productVisible,
      weight: productWeight,
      width: productWidth,
      image: productImage,
    } as IProduct;

    let resp: any;
    if (!obj._id) {
      delete obj._id;

      resp = await ProductRegister(obj);

    } else {
      resp = await UpdateProduct(obj._id, obj);
      props.close();
    }

    toast.success(resp?.message);
    if (resp?.data?.product)
      props.setFeaturedProduct(resp?.data?.product);

    props.setReloadList(true);
  };


  useEffect(() => {
    if (!props.featuredProduct)
      return;

    const item: IProduct = props.featuredProduct;

    setProductCategory(item.category ?? "");
    setProductName(item.name ?? "");
    setProductDescription(item.description ?? "");
    setProductEan(item.ean ?? "");
    setProductErpId(item.erpId ?? "");
    setProductDepartment(item.department ?? "");
    setProductBrand(item.brand ?? "");
    setProductRealPrice(item.realPrice?.toFixed(2) ?? "");
    setProductPromotionPrice(item.promotionPrice?.toFixed(2) ?? "");
    setProductEmphasis(item.emphasis);
    setProductTechnicalInformation(item.technicalInformation ?? "");
    setProductTag(item.tag ?? []);
    setProductImage(item.image ?? "");
    setProductUnitMeasurement(item.unitMeasurement ?? "");
    setProductWeight(item.weight ?? "");
    setProductWidth(item.width ?? "");
    setProductHeigth(item.heigth ?? "");
    setProductDepth(item.depth ?? "");
    setProductVisible(item.visible);

  }, [props.featuredProduct]);

  return {
    ...props,
    handleRecordProduct,
    productCategory,
    productName,
    productDescription,
    productEan,
    // productSlug,
    productErpId,
    productDepartment,
    productBrand,
    productRealPrice,
    productPromotionPrice,
    productEmphasis,
    productTechnicalInformation,
    productTag,
    productImage,
    productUnitMeasurement,
    productWeight,
    productWidth,
    productHeigth,
    productDepth,
    productVisible,
    categoryList,
    setProductCategory,
    setProductName,
    setProductDescription,
    setProductEan,
    // setProductSlug,
    setProductErpId,
    setProductDepartment,
    setProductBrand,
    setProductRealPrice,
    setProductPromotionPrice,
    setProductEmphasis,
    setProductTechnicalInformation,
    setProductTag,
    setProductImage,
    setProductUnitMeasurement,
    setProductWeight,
    setProductWidth,
    setProductHeigth,
    setProductDepth,
    setProductVisible,
  };
};