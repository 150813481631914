import { Icons } from "../../../assets/icons/_index.icons";
import { SELECT_EMPHASIS_CATEGORY } from "../../../utils/_index.utils";
import { CategoriesEmphasisCardHooks } from "./_index.partials";
import { Image } from "../../../components/_index.components";

import {
  TCategoriesPageHooksStates,
  TCatgoriesEmphasis
} from "../_index.categories";


import ImgNoImageAvaible from "../../../assets/img/noImage.png";

export type TCategoriesEmphasisCardProps = TCategoriesPageHooksStates & {
  categoryEmphasis: TCatgoriesEmphasis;
};

export default function CategoriesEmphasisCard(props: TCategoriesEmphasisCardProps) {

  const states = CategoriesEmphasisCardHooks({ ...props } as TCategoriesEmphasisCardProps);

  return (
    <div className="w-full relative">
      <div
        onClick={states.handleOpenModal}
        className="w-full max-h-full rounded-md inline-flex gap-8 ring-2 ring-slate-200 text-center items-center hover:filter hover:brightness-50 cursor-pointer"
      >
        <div className="h-[8vh] w-[8vh]">
          {
            states.categoryEmphasis.img?.imageThumbUrl
              ? <Image
                path={process.env.REACT_APP_BUCKET_URL + states.categoryEmphasis.img?.imageThumbUrl}
              />
              : <Image path={ImgNoImageAvaible} />
          }
        </div>
        <span className={
          `${SELECT_EMPHASIS_CATEGORY === states.categoryEmphasis.category ? "text-md" : "text-3xl xl:text-xl"}  
           whitespace-nowrap text-ellipsis overflow-hidden inline-flex gap-4 justify-center items-center text-center`}
        >
          <strong>
            {states.categoryEmphasis.category}
          </strong>
          {
            SELECT_EMPHASIS_CATEGORY === states.categoryEmphasis.category &&
            <Icons.register size={20} />
          }
        </span>

      </div>
      {
        SELECT_EMPHASIS_CATEGORY !== states.categoryEmphasis.category &&
        <button
          className="absolute top-2 right-2 z-20"
          onClick={states.handleRemoveEmphasis}
        >
          <Icons.delete color="#770505" />
        </button>
      }
    </div>
  )
};