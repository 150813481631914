export const STOCK_METHODS = {
  MANUAL: "MANUAL",
  AUTHOMETIC: "AUTHOMETIC",
};

export const STOCK_TRANSATION = {
  INCLUDE: "INCLUDE",
  WITHDRAW: "WITHDRAW"
};


export const STOCK_METHODS_LIST = Object.entries(STOCK_METHODS).map(item => {
  return {
    value: item[1],
    label: item[1]
  }
});

export const STOCK_TRANSATION_LIST = Object.entries(STOCK_TRANSATION).map(item => {
  return {
    value: item[1],
    label: item[1]
  }
});