import { useState } from "react";
import { TProductsFormInfosProps } from "../ProductsFormInfos";

type TProductsFormInfosHooksProps = TProductsFormInfosProps & {};
export type TProductsFormInfosHooksStates = TProductsFormInfosHooksProps & {

};

export const ProductsFormInfosHooks = ({ ...props }: TProductsFormInfosHooksProps): TProductsFormInfosHooksStates => {


  return {
    ...props,

  }
};