import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../pages/_index.pages";

type THeaderPerfilHooksProps = {}

type THeaderPerfilHooksStates = THeaderPerfilHooksProps & {
  ref: any;
  mostrarDadoUsuario: boolean;
  nomeUsuario: string,
  emailUsuario: string,
  navigateLogout: boolean;
  setNavigateLogout: (data: boolean) => void;
  setMostrarDadosUsuario: (data: boolean) => void;
  t: (data: string) => any;
};

export const HeaderPerfilHooks = (props: THeaderPerfilHooksProps): THeaderPerfilHooksStates => {
  const { authState } = useAuth();

  const [mostrarDadoUsuario, setMostrarDadosUsuario] = useState<boolean>(false);
  const [nomeUsuario, setNomeUsuario] = useState<string>("Anônimo");
  const [emailUsuario, setEmailUsuario] = useState<string>("");

  const [navigateLogout, setNavigateLogout] = useState<boolean>(false);

  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNomeUsuario(authState.user?.name + " " + authState.user?.lastname as string);
    setEmailUsuario(authState.user?.email as string);

    function manusearClickNaTela(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node) && mostrarDadoUsuario) {
        setMostrarDadosUsuario(false);
      }
    }

    document.addEventListener("mousedown", manusearClickNaTela);

    return () => {
      document.removeEventListener("mousedown", manusearClickNaTela);
    };
  }, [mostrarDadoUsuario]);

  return {
    ref,
    mostrarDadoUsuario,
    nomeUsuario,
    emailUsuario,
    setMostrarDadosUsuario,
    navigateLogout,
    setNavigateLogout,
    t,
  } as THeaderPerfilHooksStates;
}