import { toast } from "react-toastify";

import { api } from "./_axios.services";

import {
  IStock,
  IStockComplete,
  IStockSimplified,
} from "../interfaces/_index.interfaces";

export type TGetOrdersListProps = {
  company: string;
  initDate: string;
  endDate: string;
}

export async function GetOrdersList(item: TGetOrdersListProps) {
  try {
    const response = await api.get("orders", { params: item })
    const data: IStockComplete[] = response.data.data.orders_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetOrder(_id: string) {
  try {

    const response = await api.get("orders/" + _id)
    const data: IStockComplete = response.data.data.order

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function ChargebackOrder(_id: string) {
  try {
    const response = await api.post("orders/chargeback", { _id: _id })
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function CreateOrder(item: IStockSimplified) {
  try {

    const response = await api.post("orders", item)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};