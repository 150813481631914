import { Icons } from "../../../assets/icons/_index.icons";

import {
  Button,
  Input,
  Textarea,
  Image,
} from "../../../components/_index.components";

import {
  CompanyInfosFormHooks,
  TCompanyFormsHooksStates
} from "../../_index.pages";


export type TCompanyInfosFormProps = TCompanyFormsHooksStates & {}

export default function CompanyInfosForm({ ...props }: TCompanyInfosFormProps) {

  const states = CompanyInfosFormHooks({ ...props } as TCompanyInfosFormProps);

  return (
    <>
      <div className="flex flex-col w-full h-auto">
        <div className="inline-flex w-full h-auto p-2 nd:flex nd:flex-col">
          <div className="flex flex-col w-[70%] p-2 gap-32 xl:gap-6 nd:w-full nd:gap-8">
            <div className="flex flex-col w-full gap-5">
              <div className="w-full inline-flex gap-4 nd:flex nd:flex-col">
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="fantasy_name" className="w-auto whitespace-nowrap">Nome Fantasia:</label>
                  <Input
                    id={"teste"}
                    name={"fantasy_name"}
                    type={"text"}
                    placeholder="Digite aqui a Razão Social da empresa..."
                    setValue={states.setFantasyName}
                    value={states.fantasyName}
                  />
                </div>
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="company_name" className="w-auto whitespace-nowrap">Razão Social:</label>
                  <Input
                    id={"teste"}
                    name={"company_name"}
                    placeholder="Digite aqui o Nome Fantasia da empresa..."
                    type={"text"}
                    setValue={states.setCompanyName}
                    value={states.companyName}
                  />
                </div>
              </div>
              <div className="w-full inline-flex gap-4">
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="company_document" className="w-auto whitespace-nowrap">CNPJ:</label>
                  <Input
                    id={"teste"}
                    name={"company_document"}
                    placeholder="Digite aqui o CNPJ da empresa..."
                    type={"text"}
                    setValue={states.setDocument}
                    value={states.document}
                  />
                </div>
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="company_phone" className="w-auto whitespace-nowrap">Telefone:</label>
                  <Input
                    id={"teste"}
                    name={"company_phone"}
                    placeholder="Digite aqui o Telefone da empresa..."
                    type={"text"}
                    setValue={states.setPhoneNumber}
                    value={states.phoneNumber}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col gap-2 ">
                <label htmlFor="company_email" className="w-auto whitespace-nowrap">Email:</label>
                <Input
                  id={"teste"}
                  name={"company_email"}
                  placeholder="Digite aqui o Email da empresa..."
                  type={"text"}
                  setValue={states.setEmail}
                  value={states.email}
                />
              </div>
            </div>
            <div className="m-2">
              <h3 className="text-2xl">Um pouco mais sobre a empresa...</h3>
              <div className="pt-2 nd:mt-5">
                <label htmlFor="slogan">Slogan</label>
                <Input
                  id={"slogan"}
                  name={"slogan"}
                  placeholder="Digite o Slogan da empresa..."
                  type={"text"}
                  setValue={states.setSlogan}
                  value={states.slogan}
                />
              </div>
            </div>
          </div>
          <div className="w-[30%] p-2 h-[40vh] nd:w-full">
            <div className="flex flex-col justify-center items-center bg-slate-200 shadow-2xl rounded-md w-full h-full">
              <div className="w-[95%] h-[90%] p-2 mt-2 ring-1 ring-header rounded-md">
                {
                  states.featuredCompany?.image &&
                  <Image
                    path={process.env.REACT_APP_BUCKET_URL + states.featuredCompany?.image.imageThumbUrl}
                  />
                }
              </div>
              <div
                className="cursor-pointer inline-flex justify-center text-white bg-[#26c59f] rounded-md hover:text-white hover:bg-blue-600
                          m-2 items-center text-center w-[95%]"
                onClick={states.handleFile}
              >
                <Icons.upload
                  className="p-2"
                  size={30}
                />
                <p className="px-2">Incluir</p>
                <input
                  type="file"
                  hidden
                  ref={states.fileRef}
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      states.setNewFile(files[0]);
                      states.setFileKey(states.fileKey + 1)
                    }
                  }}
                // id={ID.company_file}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-2 nd:mt-5">
          <label htmlFor="description">Conte para seus clientes um pouco mais sobre a sua empresa...</label>
          <Textarea
            name="description"
            setValue={states.setDescription}
            placeholder="Conte a seus clientes um pouco mais sobre a empresa. Ao fornecer informações sobre a empresa, sua história, missão, valores e conquistas, você ajuda a construir confiança com o cliente. Isso permite que eles entendam melhor quem você é, o que você representa e como você opera."
            value={states.description}
            id={""}
          />
        </div>
        <div className="w-full h-auto justify-end flex p-4 bottom-0">
          <Button
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
            customStyleIcon="text-white"
            text="Salvar"
            icon={Icons.save}
            type="submit"
            _onClick={states.handleRecordCompany}
          />
        </div>
      </div>
    </>
  )
}