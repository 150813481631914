import { useState } from "react";
import { TSubscribersDetailsProps } from "../SubscribersDetails";

type TSubscribersDetailsHooksProps = TSubscribersDetailsProps & {};
export type TSubscribersDetailsHooksStates = TSubscribersDetailsHooksProps & {
  addPayment: string;
  setAddPayment: (d: string) => void;
};

export const SubscribersDetailsHooks = ({ ...props }: TSubscribersDetailsHooksProps): TSubscribersDetailsHooksStates => {
  const [addPayment, setAddPayment] = useState<string>("");

  return {
    ...props,
    addPayment,
    setAddPayment,
  };
};
