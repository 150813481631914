import { Icons } from "../../assets/icons/_index.icons";

type TToggleProps = {
  toggleStatus: boolean;
  setToggleStatus: (d: boolean) => void;
};

export default function Toggle(props: TToggleProps) {

  return (
    <>
      <button
        className="w-auto h-auto"
        onClick={(e) => {
          e.preventDefault();
          props.setToggleStatus(!props.toggleStatus);
        }}
      >
        {
          props.toggleStatus
            ? <Icons.toggleON size={40} className="text-[#26c59f]" />
            : <Icons.toggleOFF size={40} className="text-gray-400" />
        }
      </button>
    </>
  )
};