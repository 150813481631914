import { useEffect, useState } from "react";
import { TInputsNewProps } from "../InputsNew";
import { convertDate, validateRequiredFields } from "../../../../utils/_index.utils";
import { IInput } from "../../../../interfaces/IInput.i";
import { CreateInput, UpdateInput } from "../../../../services/inputs.services";
import { toast } from "react-toastify";
import { RoutesPath } from "../../../../routes/AppRoutes.routes";


type TInputsNewHooksProps = TInputsNewProps & {};
export type TInputsNewHooksStates = TInputsNewHooksProps & {
  formIngredient: string;
  formCategory: string;
  formUnitValue: number;
  formAmount: number;
  formPurchaseDate: string;
  inFormIngredient: boolean;
  inFormUnitValue: boolean;
  inFormAmount: boolean;
  inFormPurchaseDate: boolean;
  inFormCategory: boolean;
  setInFormIngredient: (d: boolean) => void;
  setInFormUnitValue: (d: boolean) => void;
  setInFormAmount: (d: boolean) => void;
  setInFormPurschaseDate: (d: boolean) => void;
  setInFormCategory: (d: boolean) => void;
  setFormIngredient: (d: string) => void;
  setFormCategory: (d: string) => void;
  setFormUnitValue: (d: number) => void;
  setFormAmount: (d: number) => void;
  setFormPurschaseDate: (d: string) => void;
  handleRecordInput: () => void;
};

export const InputsNewHooks = ({ ...props }: TInputsNewHooksProps): TInputsNewHooksStates => {
  const [formIngredient, setFormIngredient] = useState<string>("");
  const [formUnitValue, setFormUnitValue] = useState<number>(0);
  const [formAmount, setFormAmount] = useState<number>(0);
  const [formPurchaseDate, setFormPurschaseDate] = useState<string>(convertDate(new Date()));
  const [formCategory, setFormCategory] = useState<string>("");

  const [inFormIngredient, setInFormIngredient] = useState<boolean>(false);
  const [inFormUnitValue, setInFormUnitValue] = useState<boolean>(false);
  const [inFormAmount, setInFormAmount] = useState<boolean>(false);
  const [inFormPurchaseDate, setInFormPurschaseDate] = useState<boolean>(false);
  const [inFormCategory, setInFormCategory] = useState<boolean>(false);

  useEffect(() => {
    const item = props.featuredInput;
    if (!item) return;

    setFormIngredient(item.name);
    setFormUnitValue(item.unitValue);
    setFormAmount(item.amount);
    setFormPurschaseDate(convertDate(new Date(item.purchaseDate)));
    setFormCategory(item.inputCategory);

  }, [props.featuredInput]);

  async function handleRecordInput() {
    let obj = {
      name: formIngredient,
      amount: formAmount,
      company: props.authState.user.company,
      purchaseDate: new Date(formPurchaseDate),
      unitValue: formUnitValue,
      inputCategory: formCategory
    } as IInput;


    const listaCamposObrigatorios = [
      { parameter: "name", setFunction: setInFormIngredient },
      { parameter: "unitValue", setFunction: setInFormUnitValue },
      { parameter: "amount", setFunction: setInFormAmount },
      { parameter: "purchaseDate", setFunction: setInFormPurschaseDate },
      { parameter: "inputCategory", setFunction: setInFormCategory },
    ];

    if (validateRequiredFields({ requiredFieldsList: listaCamposObrigatorios, objForValidation: obj }))
      return toast.warning("Por favor, verifique os campos obrigatórios!");

    if (props.featuredInput?._id)
      var resp = await UpdateInput(obj);
    else
      var resp = await CreateInput(obj);

    if (resp && resp.success) {
      toast.success(resp.message);
      props.setReloadList(true);
      props.setFeaturedRoute(RoutesPath.inputs);
    } else
      toast.error(resp.message);

  };


  return {
    ...props,
    formIngredient,
    formUnitValue,
    formAmount,
    formPurchaseDate,
    formCategory,
    inFormIngredient,
    inFormUnitValue,
    inFormAmount,
    inFormPurchaseDate,
    inFormCategory,
    setInFormIngredient,
    setInFormUnitValue,
    setInFormAmount,
    setInFormPurschaseDate,
    setInFormCategory,
    setFormCategory,
    setFormIngredient,
    setFormUnitValue,
    setFormAmount,
    setFormPurschaseDate,
    handleRecordInput,
  };
};