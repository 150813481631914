import { Icons } from "../../../assets/icons/_index.icons";
import { identifiesBrightness } from "../../../utils/_index.utils";

import {
  ColorPicker,
  Dropdown
} from "../../../components/_index.components";

import {
  CompanyFormLayoutHooks,
  TCompanyFormLayoutHooksStates,
  TCompanyFormsHooksStates
} from "../../_index.pages"

export type TCompanyFormLayoutProps = TCompanyFormsHooksStates & {}

export default function CompanyFormLayout({ ...props }: TCompanyFormLayoutProps) {

  const states: TCompanyFormLayoutHooksStates = CompanyFormLayoutHooks({ ...props } as TCompanyFormLayoutProps);

  return (
    <>
      <div className="w-full h-auto justify-between inline-flex p-10 gap-8 nd:flex nd:flex-col nd:p-2">
        <div className="flex w-full flex-col gap-8 h-full">
          <div className="inline-flex gap-5 w-full items-center nd:grid nd:grid-cols-2 ">
            <p className="whitespace-nowrap text-2xl nd:text-lg">Cor Primaria Escura:</p>
            <button
              onClick={(e: any) => {
                e.preventDefault();
                states.overlappingModalPrimaryDark?.toggle();
              }}
              className="w-full h-[3vh] rounded-md relative flex justify-center items-center"
              style={{
                background: states.primaryColorDark
                  ? states.primaryColorDark
                  : states.featuredCompany
                    ?.layout
                    ?.colors
                    ?.primaryColorDark,
                border: "1px solid",
                borderColor: identifiesBrightness(states.primaryColorDark) ? "#818080" : "transparent",
              }}
            >
              <Icons.edit
                style={{
                  color: identifiesBrightness(states.primaryColorDark) ? "#000" : "#fff"
                }}
              />
              <Dropdown
                open={states.overlappingModalPrimaryDark?.open}
                toggle={states.overlappingModalPrimaryDark?.toggle}
                close={states.overlappingModalPrimaryDark?.close}
                style="mt-[37vh] w-auto ml-[7vw] absolute z-20 text-primary mt-4"
                module={
                  <>
                    <ColorPicker
                      close={states.overlappingModalPrimaryDark?.close}
                      color={states.primaryColorDark}
                      setColor={states.setPrimaryColorDark}
                    />
                  </>
                }
              />
            </button>
          </div>
          <div className="inline-flex gap-5 w-full items-center nd:grid nd:grid-cols-2 ">
            <p className="whitespace-nowrap text-2xl nd:text-lg">Cor Secundaria Escura:</p>
            <button
              onClick={(e: any) => {
                e.preventDefault();
                states.overlappingModalSecondaryDark?.toggle();
              }}
              className="w-full h-[3vh] rounded-md relative flex justify-center items-center"
              style={{
                background: states.secondaryColorDark
                  ? states.secondaryColorDark
                  : states.featuredCompany
                    ?.layout
                    ?.colors
                    ?.secondaryColorDark,
                border: "1px solid",
                borderColor: identifiesBrightness(states.secondaryColorDark) ? "#818080" : "transparent",
              }}
            >
              <Icons.edit
                style={{
                  color: identifiesBrightness(states.secondaryColorDark) ? "#000" : "#fff"
                }}
              />
              <Dropdown
                open={states.overlappingModalSecondaryDark?.open}
                toggle={states.overlappingModalSecondaryDark?.toggle}
                close={states.overlappingModalSecondaryDark?.close}
                style="mt-[37vh] w-auto ml-[7vw] absolute z-20 text-primary mt-4"
                module={
                  <>
                    <ColorPicker
                      close={states.overlappingModalSecondaryDark?.close}
                      color={states.secondaryColorDark}
                      setColor={states.setSecondaryColorDark}
                    />
                  </>
                }
              />
            </button>
          </div>
          <div className="inline-flex gap-5 w-full items-center nd:grid nd:grid-cols-2 ">
            <p className="whitespace-nowrap text-2xl nd:text-lg">Cor Terciária Escura:</p>
            <button
              onClick={(e: any) => {
                e.preventDefault();
                states.overlappingModalThirdDark?.toggle();
              }}
              className="w-full h-[3vh] rounded-md relative flex justify-center items-center"
              style={{
                background: states.thirdColorDark
                  ? states.thirdColorDark
                  : states.featuredCompany
                    ?.layout
                    ?.colors
                    ?.thirdColorDark,
                border: "1px solid",
                borderColor: identifiesBrightness(states.thirdColorDark) ? "#818080" : "transparent",
              }}
            >
              <Icons.edit
                style={{
                  color: identifiesBrightness(states.thirdColorDark) ? "#000" : "#fff"
                }}
              />
              <Dropdown
                open={states.overlappingModalThirdDark?.open}
                toggle={states.overlappingModalThirdDark?.toggle}
                close={states.overlappingModalThirdDark?.close}
                style="mt-[37vh] w-auto ml-[7vw] absolute z-20 text-primary mt-4"
                module={
                  <>
                    <ColorPicker
                      close={states.overlappingModalThirdDark?.close}
                      color={states.thirdColorDark}
                      setColor={states.setThirdColorDark}
                    />
                  </>
                }
              />
            </button>
          </div>

        </div>
        <div className="flex w-full flex-col gap-8 h-full">
          <div className="inline-flex gap-5 w-auto items-center nd:grid nd:grid-cols-2">
            <p className="whitespace-nowrap text-2xl nd:text-lg">Cor Primaria Clara:</p>
            <button
              onClick={(e: any) => {
                e.preventDefault();
                states.overlappingModalPrimaryLight?.toggle();
              }}
              className="w-full h-[3vh] rounded-md relative flex justify-center items-center"
              style={{
                background: states.primaryColorLight
                  ? states.primaryColorLight
                  : states.featuredCompany
                    ?.layout
                    ?.colors
                    ?.primaryColorLight,
                border: "1px solid",
                borderColor: identifiesBrightness(states.primaryColorLight) ? "#818080" : "transparent",
              }}
            >
              <Icons.edit
                style={{
                  color: identifiesBrightness(states.primaryColorLight) ? "#000" : "#fff"
                }}
              />
              <Dropdown
                open={states.overlappingModalPrimaryLight?.open}
                toggle={states.overlappingModalPrimaryLight?.toggle}
                close={states.overlappingModalPrimaryLight?.close}
                style="mt-[37vh] w-auto ml-[7vw] absolute z-20 text-primary mt-4"
                module={
                  <>
                    <ColorPicker
                      close={states.overlappingModalPrimaryLight?.close}
                      color={states.primaryColorLight}
                      setColor={states.setPrimaryColorLight}
                    />
                  </>
                }
              />
            </button>
          </div>
          <div className="inline-flex gap-5 w-full items-center nd:grid nd:grid-cols-2">
            <p className="whitespace-nowrap text-2xl nd:text-lg">Cor Secundaria Clara:</p>
            <button
              onClick={(e: any) => {
                e.preventDefault();
                states.overlappingModalSecondaryLight?.toggle();
              }}
              className="w-full h-[3vh] rounded-md relative flex justify-center items-center"
              style={{
                background: states.secondaryColorLight
                  ? states.secondaryColorLight
                  : states.featuredCompany
                    ?.layout
                    ?.colors
                    ?.secondaryColorLight,
                border: "1px solid",
                borderColor: identifiesBrightness(states.secondaryColorLight) ? "#818080" : "transparent",
              }}
            >
              <Icons.edit
                style={{
                  color: identifiesBrightness(states.secondaryColorLight) ? "#000" : "#fff"
                }}
              />
              <Dropdown
                open={states.overlappingModalSecondaryLight?.open}
                toggle={states.overlappingModalSecondaryLight?.toggle}
                close={states.overlappingModalSecondaryLight?.close}
                style="mt-[37vh] w-auto ml-[7vw] absolute z-20 text-primary mt-4"
                module={
                  <>
                    <ColorPicker
                      close={states.overlappingModalSecondaryLight?.close}
                      color={states.secondaryColorLight}
                      setColor={states.setSecondaryColorLight}
                    />
                  </>
                }
              />
            </button>
          </div>
          <div className="inline-flex gap-5 w-full items-center nd:grid nd:grid-cols-2">
            <p className="whitespace-nowrap text-2xl nd:text-lg">Cor Terciária Clara:</p>
            <button
              onClick={(e: any) => {
                e.preventDefault();
                states.overlappingModalThirdLight?.toggle();
              }}
              className="w-full h-[3vh] rounded-md relative flex justify-center items-center"
              style={{
                background: states.thirdColorLight
                  ? states.thirdColorLight
                  : states.featuredCompany
                    ?.layout
                    ?.colors
                    ?.thirdColorLight,
                border: "1px solid",
                borderColor: identifiesBrightness(states.thirdColorLight) ? "#818080" : "transparent",
              }}
            >
              <Icons.edit
                style={{
                  color: identifiesBrightness(states.thirdColorLight) ? "#000" : "#fff"
                }}
              />
              <Dropdown
                open={states.overlappingModalThirdLight?.open}
                toggle={states.overlappingModalThirdLight?.toggle}
                close={states.overlappingModalThirdLight?.close}
                style="mt-[37vh] w-auto ml-[7vw] absolute z-20 text-primary mt-4"
                module={
                  <>
                    <ColorPicker
                      close={states.overlappingModalThirdLight?.close}
                      color={states.thirdColorLight}
                      setColor={states.setThirdColorLight}
                    />
                  </>
                }
              />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-10 gap-8 h-auto nd:p-2 nd:mt-10">
        <h1 className="text-5xl xl:text-3xl nd:text-2xl">Previa...</h1>
        <div className="w-full h-auto flex justify-center items-center max-h-full">
          <div className="w-[70%] h-[40vh] rounded-md ring ring-slate-200 nd:w-full nd:h-[25vh]">
            <div className="w-full h-[15%] rounded-t-md flex justify-center items-center" style={{
              background: states.primaryColorDark
                ? states.primaryColorDark
                : states.featuredCompany
                  ?.layout
                  ?.colors
                  ?.primaryColorDark
            }}>
              <span style={{
                color: identifiesBrightness(
                  states.primaryColorDark
                    ? states.primaryColorDark
                    : states.featuredCompany
                      ?.layout
                      ?.colors
                      ?.primaryColorDark
                )
                  ? "#000"
                  : "#fff"
              }}>
                Header - Cor Primaria Escura
              </span>
            </div>
            <div className="w-full h-[70%] flex justify-center items-center relative" style={{
              background: states.primaryColorLight
                ? states.primaryColorLight
                : states.featuredCompany
                  ?.layout
                  ?.colors
                  ?.primaryColorLight
            }}>
              <h1
                className="font-bold cursor-pointer whitespace-nowrap text-5xl font-mono absolute top-2 left-2 nd:text-3xl"
                style={{
                  backgroundImage: `linear-gradient(to right, 
                      ${states.primaryColorLight
                      ? states.thirdColorLight
                      : states.featuredCompany
                        ?.layout
                        ?.colors
                        ?.thirdColorLight}, 
                          ${states.thirdColorDark
                      ? states.thirdColorDark
                      : states.featuredCompany
                        ?.layout
                        ?.colors
                        ?.thirdColorDark})`,
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                Variação de cor
              </h1>
              <span style={{
                color: identifiesBrightness(
                  states.primaryColorLight
                    ? states.primaryColorLight
                    : states.featuredCompany
                      ?.layout
                      ?.colors
                      ?.primaryColorLight
                )
                  ? "#000"
                  : "#fff"
              }}>
                Body - Cor Primaria Clara
              </span>
            </div>
            <div className="w-full h-[15%] rounded-b-md flex justify-center items-center" style={{
              background: states.primaryColorDark
                ? states.primaryColorDark
                : states.featuredCompany
                  ?.layout
                  ?.colors
                  ?.primaryColorDark
            }}>
              <span style={{
                color: identifiesBrightness(
                  states.primaryColorDark
                    ? states.primaryColorDark
                    : states.featuredCompany
                      ?.layout
                      ?.colors
                      ?.primaryColorDark
                )
                  ? "#000"
                  : "#fff"
              }}>
                Footer - Cor Primaria Escura
              </span>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}