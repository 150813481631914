import { Icons } from "../../../assets/icons/_index.icons";
import { formatDate } from "../../../utils/_index.utils";

import {
  Input,
  Toggle
} from "../../../components/_index.components";

import {
  CategoriesFormSubHooks,
  TCategoriesFormHooksStates,
  TCategoriesFormSubHooksStates
} from "../../_index.pages";

export type TCategoriesFormSubProps = TCategoriesFormHooksStates & {};

export default function CategoriesFormSub({ ...props }: TCategoriesFormSubProps) {

  const states: TCategoriesFormSubHooksStates = CategoriesFormSubHooks({ ...props } as TCategoriesFormSubProps);

  return (
    <>
      <div className="flex flex-col rounded-md w-full h-full pb-2">
        {
          states.subCategoryList?.length > 0
            ? <div className="inline-flex w-full h-full p-2 gap-2 nd:flex nd:flex-col">
              <div className="flex flex-col w-[80%] nd:w-full h-full ring-1 ring-slate-200 p-2 rounded-md">
                <div className="flex flex-col w-full h-full gap-5">
                  <div className="w-full inline-flex gap-4 items-end">
                    <div className="w-1/2 flex flex-col gap-2 ">
                      <label htmlFor="sub_category" className="w-auto whitespace-nowrap">SubCategoria:</label>
                      <Input
                        id={"teste"}
                        name={"sub_category"}
                        type={"text"}
                        placeholder="Gel..."
                        setValue={states.setSubCategoryName}
                        value={states.subCategoryName}
                      />
                    </div>
                    <div className="w-auto inline-flex gap-4 items-center">
                      <span className="w-auto whitespace-nowrap">Status:</span>
                      <Toggle
                        setToggleStatus={states.setSubCatgoryStatus}
                        toggleStatus={states.subCategoryStatus}
                      />
                    </div>
                  </div>
                  <div className="w-full h-full justify-end items-end flex p-4 bottom-0">
                    <div
                      onClick={states.handleRecordBtn}
                      className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                    >
                      <Icons.register />
                      <span>Gravar SubCategoria</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[20%] nd:w-full h-full ring-1 ring-slate-200 p-2 rounded-md">
                <div className="flex flex-col gap-2">
                  <div className="inline-flex justify-between w-full items-center">
                    <h3>SubCategorias</h3>
                    <div
                      className="w-auto cursor-pointer"
                      onClick={states.includeNewSubCategory}>
                      <Icons.register className="text-[#26c59f]" size={30} />
                    </div>
                  </div>
                  <hr className="h-px w-full" />
                </div>
                {
                  states.subCategoryList.length > 0 && states.subCategoryList
                    ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                    .map((item, i) => (
                      <div
                        key={i}
                        className={
                          `flex w-full cursor-pointer rounded-md mt-1 
                          ${item?._id === states.idSubCategorySelected ? "bg-[#26c59f] text-white" : "bg-none text-black"}`
                        }
                      >
                        <div className="inline-flex justify-between w-full">
                          <div className="flex text-sm w-full"
                            onClick={() => states.handleSubCategory(item?._id)}
                          >
                            <Icons.subCategory
                              className="m-2"
                              size={15}
                            />
                            <p className="p-1 text-ellipsis whitespace-nowrap">
                              {
                                formatDate(item.created_at ?? new Date(), { onlyDate: true })
                              }
                            </p>
                            <p className="p-1 text-ellipsis whitespace-nowrap overflow-hidden">
                              {
                                "- " + item?.subCategoryName
                              }
                            </p>
                          </div>
                          <div
                            className="flex w-[10%]"
                            onClick={() => states.removeSubCategory(item._id)}
                          >
                            <Icons.delete
                              className="m-2"
                              size={15}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>
            : <div className="w-full h-full flex justify-center items-center">
              <div
                className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                onClick={states.includeNewSubCategory}>
                <Icons.register size={30} />
                <span className="text-xl">Incluir</span>
              </div>
            </div>
        }
      </div>
    </>
  )
};