import { useEffect, useState } from "react";
import { IProduct } from "../../../../interfaces/_index.interfaces";
import { TCategoriesFormProductsProps } from "../CategoriesFormProducts";

import {
  GetProductListWithFilter,
  TProductsFilter
} from "../../../../services/_index.services";


type TCategoriesFormProductsHooksProps = TCategoriesFormProductsProps & {};
export type TCategoriesFormProductsHooksStates = TCategoriesFormProductsHooksProps & {
  categoryProductList: IProduct[];
  loadingList: boolean;
}

export const CategoriesFormProductsHooks = ({ ...props }: TCategoriesFormProductsHooksProps): TCategoriesFormProductsHooksStates => {
  const [categoryProductList, setCategoryProductList] = useState<IProduct[]>([]);
  const [loadingList, setLoadingList] = useState<boolean>(true);

  useEffect(() => {
    if (!props.featuredCategory)
      return setLoadingList(false);

    async function getCategoryProductList() {
      const resp = await GetProductListWithFilter({ category: props.featuredCategory._id } as TProductsFilter);
      setCategoryProductList(resp);
      setLoadingList(false);
    }

    getCategoryProductList();
  }, [props.featuredCategory]);


  return {
    ...props,
    categoryProductList,
    loadingList,
  };
};