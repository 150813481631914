import { ReactNode } from 'react';
import { BgModal, ModalHooks } from './_index.modal';

export type TModalProps = {
  open: boolean;
  module?: ReactNode;
  style?: string;
  text?: string;
  header?: boolean;
  toggle: () => void;
  close: () => void;
};

export default function Modal(props: TModalProps) {

  const states = ModalHooks({ ...props } as TModalProps);

  return (
    <>
      {
        states.open && (
          <div className={`absolute z-20 text-primary ${states.style ?? states.style}`}>
            {

              <div
                className={`fixed top-1/2 left-1/2 -translate-x-1/2 bg-opacity-50 -translate-y-1/2 h-screen w-full bg-black z-10 backdrop-blur-sm overflow-y-auto`}
              >
                <BgModal
                  close={states.close}
                  text={states.text}
                  module={states.module}
                  header={states.header}
                />
              </div>
            }
          </div>
        )
      }
    </>
  );
};
