import { Button } from "../../../components/_index.components";
import { ICategory } from "../../../interfaces/_index.interfaces";
import { TCategoriesEmphasisHooksStates } from "../../_index.pages";

import {
  CategoriesConfirmEmphasisHooks,
  TCategoriesConfirmEmphasisHooksStates
} from "./Hooks/CategoriesConfirmEmphasisHooks";

export type TCategoriesConfirmEmphasisProps = TCategoriesEmphasisHooksStates & {
  category: ICategory;
};

export default function CategoriesConfirmEmphasis(props: TCategoriesConfirmEmphasisProps) {

  const states: TCategoriesConfirmEmphasisHooksStates = CategoriesConfirmEmphasisHooks({ ...props } as TCategoriesConfirmEmphasisProps);

  return (
    <>
      <div className="w-[30vw] h-[25vh] text-lg bg-white rounded-b-md p-2 gap-4 flex flex-col justify-center items-center text-center">
        <p>Você está a um passo de tornar o seu site ainda mais interessante destacando uma categoria!</p>
        <div className="inline-flex gap-2">
          <p className="flex-wrap">Deseja prosseguir com a categoria: <strong>{states.category?.name}</strong> como destaque?</p>
        </div>
        <div className="inline-flex gap-8">
          <Button
            text="Sim"
            _onClick={states.updateEmphasisCategory}
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#20a14b] text-white"
            customStyleIcon="text-white"
          />
          <Button
            text="Não"
            _onClick={states.confirmEmphasisModal.close}
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#b12924] text-white"
            customStyleIcon="text-white"
          />
        </div>
      </div>
    </>
  )
}