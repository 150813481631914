import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IStock } from "../../../../interfaces/_index.interfaces";
import { TStockFormProps } from "../StockForm";

import {
  STOCK_METHODS,
  STOCK_TRANSATION
} from "../../../../utils/_index.utils";

import {
  GetStockCtrlProduct,
  GetStockListWithFilter,
  StockRegister,
  TStocksFilter
} from "../../../../services/stock.services";
import { UpdateProduct } from "../../../../services/_index.services";
import { HooksModal, THooksModalStates } from "../../../../hooks/_index.hooks";

type TStockFormHooksProps = TStockFormProps & {};
export type TStockFormHooksStates = TStockFormHooksProps & {
  stockProductList: IStock[];
  stockProductListDisplayed: IStock[];
  insertStockAmount: string;
  withdrawStockAmount: string;
  stockProductBalance: number;
  reloadList: boolean;
  inProductStockRegister: boolean;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  productStockRegister: string;
  stockProductBatch: string;
  stockProductReason: string;
  EnableProduct: THooksModalStates;
  setProductStockRegister: (d: string) => void;
  setCurrentPage: (d: number) => void;
  setInsertStockAmount: (d: string) => void;
  setWithdrawStockAmount: (d: string) => void;
  handleProductStock: (d: string) => void;
  setStockProductBatch: (d: string) => void;
  setStockProductReason: (d: string) => void;
  setInProductStockRegister: (d: boolean) => void;
  handleEnableProduct: () => void;
};

export const StockFormHooks = ({ ...props }: TStockFormHooksProps): TStockFormHooksStates => {
  const EnableProduct = HooksModal();

  const [stockProductListDisplayed, setStockProductListDisplayed] = useState<IStock[]>([]);
  const [stockProductList, setStockProductList] = useState<IStock[]>([]);
  const [stockProductBalance, setStockProductBalance] = useState<number>(0);
  const [reloadList, setReloadList] = useState<boolean>(true);

  const [productStockRegister, setProductStockRegister] = useState<string>("");
  const [inProductStockRegister, setInProductStockRegister] = useState<boolean>(false);

  const [insertStockAmount, setInsertStockAmount] = useState<string>("");
  const [withdrawStockAmount, setWithdrawStockAmount] = useState<string>("");
  const [stockProductBatch, setStockProductBatch] = useState<string>("");
  const [stockProductReason, setStockProductReason] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [serachedItem, setSearchedItem] = useState<string>("");

  const itensPerPage = 10;
  const totalItens = stockProductList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    if (!stockProductList.length)
      return;

    const newListStockLog: IStock[] = serachedItem
      ? stockProductList
        ?.filter((item) => {
          const buscaUpperCase = serachedItem.toUpperCase();
          const { method, transation } = item;
          return (
            method?.toUpperCase().includes(buscaUpperCase) ||
            transation?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : stockProductList?.slice(indexOfFirstIten, indexOfLastIten);

    setStockProductListDisplayed(newListStockLog);
  }, [serachedItem, stockProductList, indexOfFirstIten, indexOfLastIten]);


  useEffect(() => {
    if (!props.featuredProduct?._id || !reloadList)
      return setReloadList(false);

    async function getStockCtrlProduct() {
      const resp = await GetStockCtrlProduct(props.featuredProduct._id);
      const balance = resp?.balance

      setStockProductBalance(balance);

      if (balance || balance === 0)
        if (balance > 0 && !props.featuredProduct.visible)
          return EnableProduct.toggle();
        else if (balance === 0)
          return toast.info("O produto foi ocultado da visualização dos clientes por falta de estoque.")
        else if (balance < 0)
          return toast.warning("O estoque está negativo! Por favor, ajuste para continuar as vendas.")
    };

    async function getStockFilterList() {
      const resp = await GetStockListWithFilter({
        product: props.featuredProduct._id
      } as TStocksFilter);

      setStockProductList(resp);
      if (resp?.length) {
        await getStockCtrlProduct();
      } else {
        setStockProductBalance(0);
      }

      setReloadList(false);
    };

    getStockFilterList();
  }, [
    props.featuredProduct,
    reloadList
  ]);

  async function handleProductStock(stockTransation: string) {
    if (!props.featuredProduct && !productStockRegister) {
      toast.warning("Verifique os campos obrigatórios!")
      return setInProductStockRegister(true);
    };

    const amount = stockTransation === STOCK_TRANSATION.INCLUDE
      ? parseInt(insertStockAmount)
      : parseInt(withdrawStockAmount)

    if (!amount)
      return toast.warning("Por favor, insira a quantidade que deseja inserir ou retirar do estoque!");

    const resp = await StockRegister({
      amount: amount,
      method: STOCK_METHODS.MANUAL,
      product: props.featuredProduct?._id ?? productStockRegister,
      company: props.featuredProduct.company,
      transation: stockTransation,
      batch: stockProductBatch,
      reason: stockProductReason,
      user: null,
    });

    toast.success(resp?.message);

    if (productStockRegister)
      props.close();

    setInsertStockAmount("");
    setWithdrawStockAmount("");
    setProductStockRegister("");
    setStockProductBatch("");
    setStockProductReason("");
    setReloadList(true);
    props.setReloadList(true);
  };


  async function handleEnableProduct() {
    const product = props.featuredProduct;

    const resp = await UpdateProduct(product._id, { visible: true });
    toast.success("Produto habilitado com sucesso!");
    // props.setFeaturedProduct(resp.data.product);
    props.setReloadList(true);
    EnableProduct.close();
  };

  return {
    ...props,
    stockProductList,
    stockProductListDisplayed,
    insertStockAmount,
    withdrawStockAmount,
    stockProductBalance,
    reloadList,
    itensPerPage,
    totalItens,
    currentPage,
    productStockRegister,
    inProductStockRegister,
    stockProductBatch,
    stockProductReason,
    EnableProduct,
    setStockProductBatch,
    setStockProductReason,
    setProductStockRegister,
    setCurrentPage,
    setInsertStockAmount,
    setWithdrawStockAmount,
    handleProductStock,
    setInProductStockRegister,
    handleEnableProduct,
  }
};