import { useEffect, useState } from "react";
import { TSubscriberPageProps } from "../SubscriberPage";
import { ISubscriptionModel } from "../../../interfaces/ISubscribers.i";
import { GetSubscriber, GetSubscribersList } from "../../../services/_index.services";
import { HooksModal } from "../../../hooks/_index.hooks";
import { _ID } from "../../../utils/_index.utils";
import { TAuthState, useAuth } from "../../_index.pages";

type TSubscriberPageHooksProps = TSubscriberPageProps & {};
export type TSubscriberPageHooksStates = TSubscriberPageHooksProps & {
  subscribersList: ISubscriptionModel[];
  subscribersListDisplayed: ISubscriptionModel[];
  reloadList: boolean;
  waitingInfos: boolean;
  open: boolean;
  featuredSubscribe: ISubscriptionModel
  featuredSubscribeId: string;
  searchBar: string;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  filterPaymentStatus: string;
  featuredTab: string;
  featuredRoute: string;
  authState: TAuthState;
  setFeaturedRoute: (d: string) => void;
  setFeaturedTab: (d: string) => void;
  setFilterPaymentStatus: (d: string) => void;
  setCurrentPage: (d: number) => void;
  setSearchBar: (d: string) => void;
  setFeaturedSubscribe: (d: ISubscriptionModel) => void;
  setFeaturedSubscribeId: (d: string) => void;
  setWaitingInfos: (d: boolean) => void;
  setReloadList: (d: boolean) => void;
  close: () => void;
  toggle: () => void;
};

export const SubscriberPageHooks = ({ ...props }: TSubscriberPageHooksProps): TSubscriberPageHooksStates => {
  const { authState } = useAuth();

  const { close, open, toggle } = HooksModal();
  const [featuredRoute, setFeaturedRoute] = useState<string>("");
  const [featuredTab, setFeaturedTab] = useState<string>(_ID.tabInfos);

  const [subscribersList, setSubscribersList] = useState<ISubscriptionModel[]>([]);
  const [subscribersListDisplayed, setSubscribersListDisplayed] = useState<ISubscriptionModel[]>([]);

  const [reloadList, setReloadList] = useState<boolean>(true);
  const [waitingInfos, setWaitingInfos] = useState<boolean>(true);

  const [featuredSubscribe, setFeaturedSubscribe] = useState<ISubscriptionModel>();
  const [featuredSubscribeId, setFeaturedSubscribeId] = useState<string>("");

  const [searchBar, setSearchBar] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterPaymentStatus, setFilterPaymentStatus] = useState<string>("false");

  const itensPerPage = 10;
  const totalItens = subscribersList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    let subscriberListOrdered = subscribersList
      ?.sort((a, b) => a.user.name.localeCompare(b.user.name))

    const newList: ISubscriptionModel[] = searchBar
      ? subscriberListOrdered
        ?.filter((item) => {
          const buscaUpperCase = searchBar.toUpperCase();
          const { user } = item;
          return (
            user.name?.toUpperCase().includes(buscaUpperCase) ||
            user.lastname?.toUpperCase().includes(buscaUpperCase) ||
            (user.name + ' ' + user.lastname)?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : subscriberListOrdered
        ?.slice(indexOfFirstIten, indexOfLastIten);

    setSubscribersListDisplayed(newList);
  }, [searchBar, subscribersList, indexOfFirstIten, indexOfLastIten]);

  useEffect(() => {
    setReloadList(true);
  }, [filterPaymentStatus]);

  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    async function getSubscribersList() {
      let paidOut = filterPaymentStatus !== "Todos" ? filterPaymentStatus : null;

      const resp = await GetSubscribersList({ company: authState?.user?.company, paidOut: paidOut });

      setSubscribersList(resp);
      setReloadList(false);
    };
    getSubscribersList();
  }, [
    reloadList,
    authState
  ]);

  useEffect(() => {
    if (!featuredSubscribeId)
      return;

    async function getSubscribeByName() {
      const resp = await GetSubscriber(featuredSubscribeId);
      setFeaturedSubscribe(resp);
      setWaitingInfos(false);
    };

    getSubscribeByName();
  }, [
    featuredSubscribeId,
    waitingInfos
  ]);


  return {
    ...props,
    subscribersList,
    subscribersListDisplayed,
    reloadList,
    waitingInfos,
    open,
    featuredSubscribe,
    featuredSubscribeId,
    searchBar,
    itensPerPage,
    totalItens,
    currentPage,
    filterPaymentStatus,
    featuredTab,
    featuredRoute,
    authState,
    setFeaturedRoute,
    setFeaturedTab,
    setFilterPaymentStatus,
    setCurrentPage,
    setSearchBar,
    setFeaturedSubscribeId,
    setFeaturedSubscribe,
    setWaitingInfos,
    setReloadList,
    close,
    toggle,
  };
};