import { TProductsFormPackagingProps } from "../ProductsFormPackaging";

type TProductsFormPackagingHooksProps = TProductsFormPackagingProps & {};
export type TProductsFormPackagingHooksStates = TProductsFormPackagingHooksProps & {

};

export const ProductsFormPackagingHooks = ({ ...props }: TProductsFormPackagingHooksProps): TProductsFormPackagingHooksStates => {


  return {
    ...props,

  };
};