import { TCategoriesEmphasisCardProps } from "../CategoriesEmphasisCard";
import { v4 as uuidv4 } from 'uuid';

import { SELECT_EMPHASIS_CATEGORY } from "../../../../utils/_index.utils";
import ImgNoImageAvaible from "../../../../assets/img/noImage.png";
import { UpdateCategory } from "../../../../services/categories.services";
import { toast } from "react-toastify";
import { useState } from "react";

type TCategoriesEmphasisCardHooksProps = TCategoriesEmphasisCardProps & {};
export type TCategoriesEmphasisCardHooksStates = TCategoriesEmphasisCardHooksProps & {
  handleRemoveEmphasis: () => void;
  handleOpenModal: () => void;
};

export const CategoriesEmphasisCardHooks = ({ ...props }: TCategoriesEmphasisCardHooksProps): TCategoriesEmphasisCardHooksStates => {

  async function handleRemoveEmphasis() {
    const newEmphasisItem = {
      _id: uuidv4(),
      img: ImgNoImageAvaible,
      category: SELECT_EMPHASIS_CATEGORY
    };

    const indexToRemove = props.categoriesEmphasis.findIndex(item => item._id === props.categoryEmphasis._id);

    if (indexToRemove !== -1) {
      const updatedEmphasis = [...props.categoriesEmphasis];
      updatedEmphasis.splice(indexToRemove, 1, newEmphasisItem);


      const category = props.categoriesList.find(item => item._id === props.categoryEmphasis._id);

      category.isEmphasis = false;
      const resp = await UpdateCategory(props.categoryEmphasis._id, category)
      if (resp.success)
        toast.success("Categoria foi removida com sucesso dos destaques!");
      else
        toast.error(resp.message);

      props.setCategoriesEmphasis(updatedEmphasis);
    }
  }

  function handleOpenModal() {
    const index = props.categoriesEmphasisDisplayed.findIndex(item => item.category === SELECT_EMPHASIS_CATEGORY);

    if (index > -1)
      props.emphasisModal.toggle && props.emphasisModal.toggle();

  }

  return {
    ...props,
    handleRemoveEmphasis,
    handleOpenModal,
  }
};



