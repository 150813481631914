import { useTranslation } from "react-i18next";

type TSelectionProps = {
  optionsList: any[];
  value: string;
  placeholder?: string;
  setValue: (data: string) => void;
  setInValue?: (data: boolean) => void;
  inValue?: boolean;
  readonly?: boolean;
};

export default function Selection(props: TSelectionProps) {
  const { t } = useTranslation();

  return (
    <>
      <select
        className={`
          w-full h-auto min-h-[5vh] rounded-md
          bg-slate-200 ${props.inValue === true ? "ring-2 ring-[#a53e3e]" : "ring-0"}
          ${props.readonly === true ? "pointer-events-none" : ""}`
        }
        onChange={(e) => {
          props.setValue(e.target.value);
          props.setInValue && props.setInValue(false);
        }}
        value={props.value}
      >

        <option
          className="whitespace-nowrap text-ellipsis overflow-hidden"
          value="">{
            props.placeholder
              ? props.placeholder
              : "Selecione uma opção"
          }...
        </option>
        {
          props.optionsList &&
          props.optionsList?.length > 0 &&
          props.optionsList?.map((item: any, idx: number) => (
            <option
              key={idx}
              value={item.value}
            >
              {item.label}
            </option>
          ))
        }
      </select>

    </>
  )
};