import { useEffect, useState } from "react";
// import { obterUsuario } from "../../../services/_index.services";
import { THeaderProps } from "../Header";
import { useAuth } from "../../../pages/_index.pages";

type THeaderHooksProps = THeaderProps & {};
type THeaderHooksStates = THeaderHooksProps & {
  nomeUsuario: string;
};


export const HeaderHooks = (props: THeaderHooksProps): THeaderHooksStates => {
  const { authState } = useAuth();

  const [nomeUsuario, setNomeUsuario] = useState<string>("Anônimo");

  useEffect(() => {
    if (!authState)
      return;

    setNomeUsuario(`${authState.user?.name} ${authState.user?.lastname}`);
  }, [authState]);

  return {
    ...props,
    nomeUsuario: nomeUsuario,
  } as THeaderHooksStates;
};