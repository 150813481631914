import { Icons } from "../../../assets/icons/_index.icons";
import { TStockPageHooksStates } from "../../_index.pages";
import { StockFormHooks } from "./_index.partials";

import {
  Form,
  Input,
  Pagination,
  Spinner,
  Selection,
  Textarea,
  Modal,
  Button
} from "../../../components/_index.components";

import {
  STOCK_METHODS,
  STOCK_TRANSATION,
  formatDate
} from "../../../utils/_index.utils";

export type TStockFormProps = TStockPageHooksStates & {};

export default function StockForm({ ...props }: TStockFormProps) {

  const states = StockFormHooks({ ...props } as TStockFormProps);

  return (
    <>
      <Form
        className="w-full h-full max-h-full bg-slate-100 pb-2"
        onSubmit={() => null}
      >
        <div className="w-full max-h-full flex flex-col gap-3">
          <div className="w-full inline-flex gap-5 h-full nd:flex nd:flex-col">
            <div className="w-auto min-w-1/5 flex flex-col gap-5 h-full nd:w-full">
              {
                !states.featuredProduct?._id &&
                <div className="w-full flex flex-col gap-2">
                  <Selection
                    optionsList={states.productsList}
                    setValue={states.setProductStockRegister}
                    value={states.productStockRegister}
                    placeholder="Selecione um produto"
                    inValue={states.inProductStockRegister}
                    setInValue={states.setInProductStockRegister}
                  />
                </div>
              }
              <div className="w-full flex flex-col gap-2 pointer-events-none">
                <p className="w-auto whitespace-nowrap">Estoque atual:</p>
                <div className="flex w-full h-[5vh] rounded-md px-2 bg-slate-200 font-bold text-yellow-700 items-center justify-center text-center">
                  {
                    !states.reloadList
                      ? <span className="h-full text-center flex justify-center items-center text-2xl">{states.stockProductBalance}</span>
                      : <div className="flex justify-center items-center h-full w-full">
                        <div className="border-8 border-solid border-[#c5fff2] border-t-8 border-t-[#26c59f] rounded-full w-auto h-auto animate-spin" />
                      </div>
                  }
                </div>
              </div>
              <div className="w-full inline-flex gap-3 items-end">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="product_stock_add" className="w-auto whitespace-nowrap">Adicionar ao Estoque:</label>
                  <Input
                    id={"product_stock_add"}
                    name={"product_stock_add"}
                    type={"number"}
                    setValue={states.setInsertStockAmount}
                    value={states.insertStockAmount}
                    style="font-bold text-green-700 text-center"
                  />
                </div>
                <div
                  onClick={() => states.handleProductStock(STOCK_TRANSATION.INCLUDE)}
                  className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                >
                  <Icons.register />
                  <span>Incluir</span>
                </div>
              </div>
              <div className="w-full inline-flex gap-3 items-end">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="product_stock_subtract" className="w-auto whitespace-nowrap">Remover do Estoque:</label>
                  <Input
                    id={"product_stock_subtract"}
                    name={"product_stock_subtract"}
                    type={"number"}
                    setValue={states.setWithdrawStockAmount}
                    value={states.withdrawStockAmount}
                    style="font-bold text-[#a53e3e] text-center"
                  />
                </div>
                <div
                  onClick={() => states.handleProductStock(STOCK_TRANSATION.WITHDRAW)}
                  className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#a53e3e] text-white cursor-pointer"
                >
                  <Icons.subtract />
                  <span>Retirar</span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <label htmlFor="product_batch" className="w-auto whitespace-nowrap">Lote:</label>
                <Input
                  id={"product_batch"}
                  name={"product_batch"}
                  type={"text"}
                  setValue={states.setStockProductBatch}
                  value={states.stockProductBatch}
                  style="font-bold text-[#543ea5] text-center"
                />

              </div>
              <div className="w-full flex flex-col gap-2">
                <label htmlFor="product_reason" className="w-auto whitespace-nowrap">Motivo:</label>
                <Textarea
                  id={"product_reason"}
                  name={"product_reason"}
                  setValue={states.setStockProductReason}
                  value={states.stockProductReason}
                />
              </div>
            </div>
            <div className="w-full min-w-4/5 border border-slate-200 border-t-0 border-b-0 border-r-0 flex flex-col gap-5 h-full p-2 overflow-auto nd:w-full nd:hidden">
              <div className="overflow-auto">
                {
                  !states.reloadList
                    ? <table className="w-full">
                      <caption className="text-left font-semibold my-2">
                        Histórico de entradas e saídas
                      </caption>
                      <thead className="bg-gray-300">
                        <tr>
                          <th className="px-4 py-2">Transação</th>
                          <th className="px-4 py-2">Quantidade</th>
                          <th className="px-4 py-2">Produto</th>
                          <th className="px-4 py-2">Método</th>
                          <th className="px-4 py-2">Lote</th>
                          <th className="px-4 py-2">Motivo</th>
                          <th className="px-4 py-2">Usuário</th>
                          <th className="px-4 py-2">Data</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {
                          states.stockProductListDisplayed?.map((item, i) => (
                            <tr className="bg-gray-100" key={i}>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{item.transation === STOCK_TRANSATION.INCLUDE ? "Entrada" : "Baixa"}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{item.amount}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{states.featuredProduct?.name}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{item.method === STOCK_METHODS.MANUAL ? "Manual" : "Automático"}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{item.batch ?? "-"}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{item.reason ?? "-"}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{item.user?.name ?? " - "}</td>
                              <td className="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis">{formatDate(item.createdAt)}</td>
                            </tr>
                          ))
                        }
                      </tbody>

                    </table>
                    : <Spinner />
                }
              </div>
              {
                states.stockProductListDisplayed?.length > 0 &&
                <Pagination
                  itensPerPage={states.itensPerPage}
                  totalItens={states.totalItens}
                  setCurrentPage={states.setCurrentPage}
                  currentPage={states.currentPage}
                />
              }
            </div>
          </div>
        </div>
      </Form>
      <Modal
        close={states.EnableProduct.close}
        open={states.EnableProduct.open}
        toggle={states.EnableProduct.toggle}
        module={
          <>
            <div className="w-[30vw] h-[25vh] text-lg bg-white rounded-b-md shadow-2xl bg-sec-light-background p-2 gap-4 flex flex-col justify-center items-center text-center">
              <p>Parece que há estoque disponível para o produto: <strong>{states.featuredProduct?.name}</strong>!</p>
              <div className="inline-flex gap-2">
                <p className="flex-wrap">O produto não encontrasse vísivel para seus clientes neste momento. Deseja habilitar o produto como visível?</p>
              </div>
              <div className="inline-flex gap-8">
                <Button
                  text="Sim"
                  _onClick={states.handleEnableProduct}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#20a14b] text-white"
                  customStyleIcon="text-white"
                />
                <Button
                  text="Não"
                  _onClick={states.EnableProduct.close}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#b12924] text-white"
                  customStyleIcon="text-white"
                />
              </div>
            </div>
          </>
        }
      />
    </>
  )
};