import { useEffect, useState } from "react";
import { TUsersPageProps } from "../UsersPage";
import { IUser } from "../../../interfaces/IUser.i";
import HooksModal from "../../../hooks/modal.hooks";
import { TAuthState, useAuth } from "../../_index.pages";
import { GetUser, GetUserList } from "../../../services/_index.services";
import { _ID } from "../../../utils/_index.utils";

type TUserPagesHooksProps = TUsersPageProps & {};
export type TUserPagesHooksStates = TUserPagesHooksProps & {
  serachedItem: string;
  featuredUser: IUser;
  open: boolean;
  usersList: IUser[];
  usersListDisplayed: IUser[];
  reloadList: boolean;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  featuredTab: string;
  featuredRoute: string;
  authState: TAuthState;
  setFeaturedRoute: (d: string) => void;
  setFeaturedTab: (d: string) => void;
  setUsersList: (d: IUser[]) => void;
  setUsersListDisplayed: (d: IUser[]) => void;
  setReloadList: (d: boolean) => void;
  setCurrentPage: (d: number) => void;
  close: () => void;
  toggle: () => void;
  getUser: (d: string) => void;
  setSearchedItem: (d: string) => void;
  setFeaturedUser: (d: IUser) => void;
};

export const tabListUsers = [
  {
    nome: "Informações",
    _id: _ID.tabInfos
  },
  {
    nome: "Endereço",
    _id: _ID.tabAddress
  },
  {
    nome: "LGPD",
    _id: _ID.tabLGPD
  },
  {
    nome: "Pedidos",
    _id: _ID.tabOrders
  },
]

export const UserPagesHooks = ({ ...props }: TUserPagesHooksProps): TUserPagesHooksStates => {
  const { authState } = useAuth();

  const [featuredRoute, setFeaturedRoute] = useState<string>("");
  const { open, close, toggle } = HooksModal();

  const [featuredUser, setFeaturedUser] = useState<IUser>();
  const [featuredTab, setFeaturedTab] = useState<string>(_ID.tabInfos);

  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [usersListDisplayed, setUsersListDisplayed] = useState<IUser[]>([]);

  const [reloadList, setReloadList] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [serachedItem, setSearchedItem] = useState<string>("");
  const [totalItens, setTotalItens] = useState<number>(usersList?.length);

  const itensPerPage = 15;
  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    const newListUsers: IUser[] = serachedItem
      ? usersList
        ?.filter((item) => {
          const buscaUpperCase = serachedItem.toUpperCase();
          const { name, lastname } = item;
          return (
            name?.toUpperCase().includes(buscaUpperCase) ||
            lastname?.toUpperCase().includes(buscaUpperCase)
          );
        })
        ?.slice()
      : usersList?.slice(indexOfFirstIten, indexOfLastIten);

    setUsersListDisplayed(newListUsers);
    setTotalItens(serachedItem ? 1 : usersList?.length)
  }, [serachedItem, usersList, indexOfFirstIten, indexOfLastIten]);

  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    async function getUsersList() {
      const resp = await GetUserList({ company: authState.user?.company });
      setUsersList(resp);
      setReloadList(false);
    };

    getUsersList();
  }, [
    reloadList,
    authState
  ]);

  async function getUser(_id: string) {
    if (!_id) return;

    const resp = await GetUser(_id);
    setFeaturedUser(resp);
  };

  return {
    ...props,
    serachedItem,
    featuredUser,
    open,
    usersList,
    usersListDisplayed,
    reloadList,
    itensPerPage,
    totalItens,
    currentPage,
    featuredTab,
    featuredRoute,
    authState,
    setFeaturedRoute,
    setFeaturedTab,
    setUsersList,
    setUsersListDisplayed,
    setReloadList,
    setCurrentPage,
    close,
    toggle,
    getUser,
    setSearchedItem,
    setFeaturedUser,
  };
};