import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import {
  HooksModal,
  THooksModalStates
} from "../../../hooks/_index.hooks";

import {
  SELECT_EMPHASIS_CATEGORY,
  _ID
} from "../../../utils/_index.utils";

import ImgNoImageAvaible from "../../../assets/img/noImage.png";

import { GetCategoryList } from "../../../services/categories.services";
import { ICategory } from "../../../interfaces/_index.interfaces";
import { TCategoriesPageProps } from "../CategoriesPage";
import { TAuthState, useAuth } from "../../_index.pages";

type TCategoriesPageHooksProps = TCategoriesPageProps & {};
export type TCategoriesPageHooksStates = TCategoriesPageHooksProps & {
  categoriesList: ICategory[];
  categoriesListDisplayed: ICategory[];
  searchBar: string;
  featuredCategory: ICategory | null
  open: boolean;
  featuredTab: string;
  categoriesEmphasis: TCatgoriesEmphasis[];
  categoriesEmphasisDisplayed: TCatgoriesEmphasis[];
  emphasisModal: THooksModalStates;
  confirmEmphasisModal: THooksModalStates;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  reloadList: boolean;
  authState: TAuthState;
  featuredRoute: string;
  setFeaturedRoute: (d: string) => void;
  setCurrentPage: (d: number) => void;
  setFeaturedTab: (d: string) => void;
  setCategoriesEmphasis: (d: TCatgoriesEmphasis[]) => void;
  close: () => void;
  toggle: () => void;
  setFeaturedCategory: (d: ICategory | null) => void;
  setSearchBar: (d: string) => void;
  setReloadList: (d: boolean) => void;
};

export type TCatgoriesEmphasis = {
  _id: string;
  img: any;
  category: string;
}

const categoriesEmphasisDefault: TCatgoriesEmphasis[] = [
  {
    _id: uuidv4(),
    img: ImgNoImageAvaible,
    category: SELECT_EMPHASIS_CATEGORY
  },
  {
    _id: uuidv4(),
    img: ImgNoImageAvaible,
    category: SELECT_EMPHASIS_CATEGORY
  },
  {
    _id: uuidv4(),
    img: ImgNoImageAvaible,
    category: SELECT_EMPHASIS_CATEGORY
  },
  {
    _id: uuidv4(),
    img: ImgNoImageAvaible,
    category: SELECT_EMPHASIS_CATEGORY
  }
]

export const CategoriesPageHooks = ({ ...props }: TCategoriesPageHooksProps): TCategoriesPageHooksStates => {
  const { authState } = useAuth();

  const [featuredRoute, setFeaturedRoute] = useState<string>("");

  const { close, toggle, open } = HooksModal();
  const emphasisModal = HooksModal();
  const confirmEmphasisModal = HooksModal();

  const [categoriesList, setCategoriesList] = useState<ICategory[]>([]);
  const [categoriesListDisplayed, setCategoriesListDisplayed] = useState<ICategory[]>([]);
  const [categoriesEmphasis, setCategoriesEmphasis] = useState<TCatgoriesEmphasis[]>(categoriesEmphasisDefault);
  const [categoriesEmphasisDisplayed, setCategoriesEmphasisDisplayed] = useState<TCatgoriesEmphasis[]>(categoriesEmphasis);

  const [searchBar, setSearchBar] = useState<string>("");

  const [featuredCategory, setFeaturedCategory] = useState<ICategory | null>(null);
  const [featuredTab, setFeaturedTab] = useState<string>(_ID.tabInfos);

  const [reloadList, setReloadList] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [serachedItem, setSearchedItem] = useState<string>("");

  const itensPerPage = 10;
  const totalItens = categoriesList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    const newListCategories: ICategory[] = serachedItem
      ? categoriesList
        ?.filter((item) => {
          const buscaUpperCase = serachedItem.toUpperCase();
          const { name, title } = item;
          return (
            name?.toUpperCase().includes(buscaUpperCase) ||
            title?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : categoriesList?.slice(indexOfFirstIten, indexOfLastIten);

    setCategoriesListDisplayed(newListCategories);
  }, [serachedItem, categoriesList, indexOfFirstIten, indexOfLastIten]);

  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    async function getCategoriesList() {
      let resp = await GetCategoryList({ company: authState.user?.company });
      setCategoriesList(resp);

      if (!resp?.length)
        return;

      const filteredEmphasis = resp
        ?.filter(item => item.isEmphasis && item.status)
        ?.map((o: ICategory) => (
          {
            _id: o._id,
            img: o.image,
            category: o.name
          }
        ));

      setCategoriesEmphasis(prev => {
        const existingIds = new Set(prev.map(item => item._id));

        const updatedEmphasis = prev.map(item => {
          const updatedItem = filteredEmphasis.find(newItem => newItem._id === item._id);
          return updatedItem || item;
        });

        const newEmphasis = filteredEmphasis.filter(item => !existingIds.has(item._id));

        return [...updatedEmphasis, ...newEmphasis];
      });
    };

    getCategoriesList();
    setReloadList(false);
  }, [
    reloadList,
    authState
  ]);


  useEffect(() => {
    if (categoriesEmphasis === categoriesEmphasisDisplayed)
      return;


    const filteredCategories = categoriesEmphasis
      .filter(category => category.category !== SELECT_EMPHASIS_CATEGORY);

    let displayed = [];

    if (filteredCategories.length >= 4) {
      displayed = filteredCategories.slice(-4);
    } else {
      displayed = filteredCategories;
      const lastCategoryWithSelect = categoriesEmphasis
        .slice()
        .reverse()
        .find(category => category.category === SELECT_EMPHASIS_CATEGORY);

      if (lastCategoryWithSelect)
        displayed.push(lastCategoryWithSelect);
    }

    setCategoriesEmphasisDisplayed(displayed.reverse());

  }, [categoriesEmphasis]);

  return {
    ...props,
    ...props,
    categoriesList,
    searchBar,
    featuredCategory,
    open,
    featuredTab,
    categoriesEmphasis,
    emphasisModal,
    confirmEmphasisModal,
    categoriesEmphasisDisplayed,
    categoriesListDisplayed,
    itensPerPage,
    totalItens,
    currentPage,
    reloadList,
    authState,
    featuredRoute,
    setFeaturedRoute,
    setCurrentPage,
    setCategoriesEmphasis,
    setFeaturedTab,
    close,
    toggle,
    setFeaturedCategory,
    setSearchBar,
    setReloadList,
  };
};