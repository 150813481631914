import {
  TUsersFormHooksStates,
  TUsersFormLgpdHooksStates,
  UsersFormLgpdHooks
} from "./_index.partials";


export type TUsersFormLgpdProps = TUsersFormHooksStates & {};

export default function UsersFormLgpd({ ...props }: TUsersFormLgpdProps) {

  const states: TUsersFormLgpdHooksStates = UsersFormLgpdHooks({ ...props } as TUsersFormLgpdProps);

  return (
    <>
      <div className="w-full h-full flex flex-col gap-3">
        <div>LGPD</div>

      </div>
    </>
  )
};