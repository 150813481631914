import { Spinner } from "../../../components/_index.components";
import { formatCurrency, formatDate } from "../../../utils/_index.utils";
import {
  SubscribersDetailsOrdersHooks,
  TSubscribersDetailsHooksStates,
  TSubscribersDetailsOrdersHooksStates,
} from "../../_index.pages";

export type TSubscribersDetailsOrdersProps = TSubscribersDetailsHooksStates & {};

export default function SubscribersDetailsOrders({ ...props }: TSubscribersDetailsOrdersProps) {

  const states: TSubscribersDetailsOrdersHooksStates = SubscribersDetailsOrdersHooks({ ...props } as TSubscribersDetailsOrdersProps);

  return (
    <>
      <div className="p-5 w-full h-full nd:p-1">
        <table className="w-full">
          <thead className="bg-gray-300">
            <tr>
              <th className="px-4 py-2">Produto</th>
              <th className="px-4 py-2">Data</th>
              <th className="px-4 py-2">Qtde.</th>
              <th className="px-4 py-2 nd:hidden">Valor Un.</th>
              <th className="px-4 py-2">Valor Total</th>
            </tr>
          </thead>
          <tbody className="text-center w-full">
            {
              !states.loadingOrdersList
                ? states.subscriberOrders
                  ?.sort((a, b) => new Date(b.purshaseDate).getTime() - new Date(a.purshaseDate).getTime())
                  ?.map((p, j) => (
                    <tr
                      className={`${j % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'
                        }`}
                      key={j}
                    >
                      <td className="px-4 py-2 text-start">{p.name}</td>
                      <td className="px-4 py-2 text-center">{formatDate(p.purshaseDate, { onlyDate: true })}</td>
                      <td className="px-4 py-2 text-center">{p.quantity}</td>
                      <td className="px-4 py-2 text-center nd:hidden">{formatCurrency(p.value)}</td>
                      <td className="px-4 py-2 text-center">{formatCurrency(p.totalValue)}</td>
                    </tr>
                  ))
                : <Spinner />
            }
          </tbody>
        </table>
      </div>
    </>
  )
};