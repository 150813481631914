import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { ISubscribersProducts, ISubscriptionModel } from "../interfaces/_index.interfaces";

export type TGetSubscribersListProps = {
  company: string;
  paidOut: string;
};

export async function GetSubscribersList(item: TGetSubscribersListProps) {
  try {
    let query: string = "";
    if (item.company) query = `?company=${item.company}`
    if (item?.paidOut) query += query ? `&paidOut=${item.paidOut}` : `?paidOut=${item.paidOut}`;

    const response = await api.get("subscribers" + query)
    const data: ISubscriptionModel[] = response.data.data.subscribers_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetSubscriber(_id: string) {
  try {

    const response = await api.get("subscribers/" + _id)
    const data: ISubscriptionModel = response.data.data.subscriber

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function CreateSubscriber(item: ISubscriptionModel) {
  try {

    const response = await api.post("subscribers", item)
    const data = response.data.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

type TGetSubscribersOrders = {
  company?: string;
  user?: string;
  initDate?: string;
  endDate?: string;
};

export async function GetSubscribersOrders(item: TGetSubscribersOrders) {
  try {
    const response = await api.post("subscribers/orders", item)
    const data: ISubscribersProducts = response.data.data.subscribe[0]

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};