import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { TSubscribersDetailsNotesProps } from "../SubscribersDetailsNotes";
import { GENERIC_ID_ } from "../../../../utils/app.utils";
import { validateRequiredFields } from "../../../../utils/_index.utils";
import { INote } from "../../../../interfaces/_index.interfaces";
import { GetNoteList, NoteRegister } from "../../../../services/notes.services";

type TSubscribersDetailsNotesHooksProps = TSubscribersDetailsNotesProps & {};
export type TSubscribersDetailsNotesHooksStates = TSubscribersDetailsNotesHooksProps & {
  notesList: any[];
  emphasisNote: any;
  formNote: string;
  formTitle: string;
  inFormNote: boolean;
  inFormTitle: boolean;
  reloadList: boolean;
  setInFormNote: (d: boolean) => void;
  setInFormTitle: (d: boolean) => void;
  setFormNote: (d: string) => void;
  setFormTitle: (d: string) => void;
  setEmphasisNote: (d: INote) => void;
  setNotesList: (d: INote[]) => void;
  includeNewNote: () => void;
  handleNoteSave: () => void;
};

type TResponse = {
  data: any;
  success: boolean;
  message: string;
};

export const SubscribersDetailsNotesHooks = ({ ...props }: TSubscribersDetailsNotesHooksProps): TSubscribersDetailsNotesHooksStates => {

  const [notesList, setNotesList] = useState<INote[]>([]);
  const [emphasisNote, setEmphasisNote] = useState<INote>();

  const [formNote, setFormNote] = useState<string>("");
  const [formTitle, setFormTitle] = useState<string>("");

  const [inFormNote, setInFormNote] = useState<boolean>(false);
  const [inFormTitle, setInFormTitle] = useState<boolean>(false);

  const [reloadList, setRealodList] = useState<boolean>(true);

  useEffect(() => {
    if (!reloadList || !props.authState?.user)
      return;

    async function getNotes() {
      const resp = await GetNoteList({
        company: props.authState.user?.company,
        user: props.featuredSubscribe?.user?._id
      })

      setNotesList(resp);
      setRealodList(false);
    };

    getNotes();
  }, [
    reloadList,
    props.authState
  ]);

  useEffect(() => {
    if (emphasisNote)
      return;

    setEmphasisNote(
      notesList?.length
        ? notesList[notesList.length - 1]
        : null
    )
  }, [notesList]);

  useEffect(() => {
    if (!emphasisNote)
      return;

    setFormNote(emphasisNote?.note);
    setFormTitle(emphasisNote?.title);
  }, [emphasisNote]);

  async function handleNoteSave() {
    let obj = {
      _id: emphasisNote._id,
      note: formNote,
      title: formTitle,
      company: props.featuredSubscribe?.user?.company,
      user: props.featuredSubscribe?.user?._id,
    } as INote;

    const requiredFieldsList = [
      { parameter: "title", setFunction: setInFormTitle },
      { parameter: "note", setFunction: setInFormNote },
    ];

    if (validateRequiredFields({ requiredFieldsList: requiredFieldsList, objForValidation: obj }))
      return toast.warning("Por favor, verifique os campos obrigatórios!");

    delete obj._id;
    const resp = await NoteRegister(obj);
    if (resp?.success) {
      setRealodList(true);
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    };
  };

  function includeNewNote() {
    let newItem = {
      _id: GENERIC_ID_ + (notesList.length + 1).toString(),
      note: "",
      title: "",
      created_at: new Date(),
    } as INote;

    setEmphasisNote(newItem);
    setNotesList([...notesList, newItem]);
  };

  return {
    ...props,
    notesList,
    emphasisNote,
    formNote,
    formTitle,
    inFormNote,
    inFormTitle,
    reloadList,
    setInFormNote,
    setInFormTitle,
    setFormNote,
    setFormTitle,
    setEmphasisNote,
    setNotesList,
    includeNewNote,
    handleNoteSave
  };
};