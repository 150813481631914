import { SystemMenu } from "../_index.components";
import { HeaderHooks } from "./Hooks/_index.hooks";
import { Icons } from "../../assets/icons/_index.icons";

import LogoGergelim from "../../assets/img/logoGergelim.svg";

import {
  HeaderLanguage,
  HeaderProfile,
  HeaderTheme
} from "./Partials/_index.partials";

export type THeaderProps = {
  activeMenu: boolean;
  setActiveMenu: (data: boolean) => void;
};

export default function Header({ ...props }: THeaderProps) {

  const states = HeaderHooks({ ...props } as THeaderProps)

  return (
    <>
      <header className="w-full p-8 gap-8 flex justify-center items-center h-14 nd:h-[7vh] nd:p-2 bg-gray-200 z-10">
        <div className="w-full h-9 justify-end flex items-center nd:justify-between">
          <div className='inline-flex space-x-2 items-center'>
            <div className='w-auto h-auto max-w-14 nd:max-w-[20%] bg-slate-100 border border-[#26c59f] p-2 rounded-lg justify-center items-center'>
              <img
                className="w-auto h-auto nd:w-full nd:h-full "
                src={LogoGergelim}
              />
            </div>
            <h1 className='whitespace-nowrap text-[#7F7F7F]'>
              <strong>
                GERGELIM
              </strong>
            </h1>
          </div>
          <div className={`w-full inline-flex justify-between px-4`}>
            <div className="w-full h-5 justify-end inline-flex space-x-5 items-center">
              <p className="text-primary">
                {
                  states.nomeUsuario
                }
              </p>
              <HeaderProfile />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}