import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import {
  CategoriesPage,
  CompanyPages,
  DashboardPages,
  InputsPages,
  LoginPage,
  OrdersPage,
  ProductsPage,
  StockPage,
  SubscriberPage,
  UsersPage
} from "../pages/_index.pages";


export const RoutesPath = {
  login: "/login",
  dashboard: "/dashboard",
  company: "/company",
  categories: "/categories",
  products: "/products",
  users: "/users",
  orders: "/orders",
  stock: "/stock",
  subscribers: "/subscribers",
  inputs: "/inputs",
  inputsNew: "/new-input",
  categoryNew: "/new-category",
  productNew: "/new-product",
  orderNew: "/new-order",
  stockNew: "/new-stock",
  userNew: "/new-user",
  subscriberNew: "/new-subscriber",
  companyNew: "/new-company",
};

export default function AppRoutes() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path={RoutesPath.login} element={<LoginPage />} />
          <Route path={RoutesPath.dashboard} element={<DashboardPages />} />
          <Route path={RoutesPath.company} element={<CompanyPages />} />
          <Route path={RoutesPath.categories} element={<CategoriesPage />} />
          <Route path={RoutesPath.products} element={<ProductsPage />} />
          <Route path={RoutesPath.orders} element={<OrdersPage />} />
          <Route path={RoutesPath.stock} element={<StockPage />} />
          <Route path={RoutesPath.users} element={<UsersPage />} />
          <Route path={RoutesPath.subscribers} element={<SubscriberPage />} />
          <Route path={RoutesPath.inputs} element={<InputsPages />} />
        </Routes>
      </Router>
    </>
  )
};