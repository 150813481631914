import { _ID } from "../../../utils/_id.utils";

import {
  Form,
  FormAddress
} from "../../../components/_index.components";

import {
  CompanyEmployeeForm,
  CompanyFormBanner,
  CompanyFormLayout,
  CompanyFormsHooks,
  TCompanyFormsHooksStates,
  TCompanyPagesHooksStates,
} from "../../_index.pages";

import CompanyInfosForm from "./CompanyInfosForm";


export type TCompanyFormsProps = TCompanyPagesHooksStates & {}

export default function CompanyForms({ ...props }: TCompanyFormsProps) {

  const states: TCompanyFormsHooksStates = CompanyFormsHooks({ ...props } as TCompanyFormsProps);

  return (
    <>
      <Form
        className="w-full h-full bg-slate-100 pb-2 nd:mt-10"
        onSubmit={states.handleRecordCompany}
      >
        {
          states.featuredTab === _ID.tabInfos &&
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CompanyInfosForm {...states} />
        }
        {
          states.featuredTab === _ID.tabLayout &&
          <CompanyFormLayout {...states} />
        }
        {
          states.featuredTab === _ID.tabAddress &&
          <FormAddress {...states} />
        }
        {
          states.featuredTab === _ID.tabEmployees &&
          <CompanyEmployeeForm {...states} />
        }
        {
          states.featuredTab === _ID.tabBanner &&
          <CompanyFormBanner {...states} />
        }
      </Form>
    </>
  )
}