export const PRODUCT_UNIT_MEASUREMENT = {
  KG: "QUILOGRAMA",
  HG: "HECTOGRAMA",
  DAG: "DECAGRAMA",
  G: "GRAMA",
  DG: "DECIGRAMA",
  CG: "CENTIGRAMA",
  MG: "MILIGRAMA",
};


export const UNIT_MEASUREMENT_LIST = Object.entries(PRODUCT_UNIT_MEASUREMENT).map(item => {
  return {
    value: item[1],
    label: item[1]
  }
});