import { useTranslation } from "react-i18next";
import { TModalProps } from "../Modal";


type TModalHooksProps = TModalProps & {};
type TModalHooksStates = TModalHooksProps & {
  t: (data: string) => any;
};

export const ModalHooks = (props: TModalHooksProps): TModalHooksStates => {
  const { t } = useTranslation();

  return {
    t,
    ...props
  } as TModalHooksStates;
};