import axios from "axios";


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

api.defaults.timeout = 1000 * 60 * 60;
api.interceptors.request.use(async config => {
  const token = ""

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export { api }


