import { Icons } from "../../../assets/icons/_index.icons";
import { OrdersDetailsHooks, TOrdersDetailsHooksStates, TOrdersPageHooksStates } from "../../_index.pages";

import { Button, Input, Selection } from "../../../components/_index.components";

export type TOrdersDetailsProps = TOrdersPageHooksStates & {};


export default function OrdersDetails({ ...props }: TOrdersDetailsProps) {


  const states: TOrdersDetailsHooksStates = OrdersDetailsHooks({ ...props } as TOrdersDetailsProps);

  return (
    <>
      <form
        className="w-full p-2 gap-5 flex flex-col"
        onSubmit={states.registerOrder}
      >
        <div className="w-full inline-flex gap-5 nd:flex nd:flex-col">
          <div className="w-1/2 flex flex-col gap-2 nd:w-full">
            <label htmlFor="product" className="w-auto whitespace-nowrap">Produto:</label>
            <Selection
              optionsList={states.productsList}
              setValue={states.setProductOrder}
              value={states.productOrder}
            />
          </div>
          <div className="w-1/2 flex flex-col gap-2 nd:w-full">
            <label htmlFor="client" className="w-auto whitespace-nowrap">Cliente:</label>
            <Selection
              optionsList={states.usersList}
              setValue={states.setUserOrder}
              value={states.userOrder}
            />
          </div>
        </div>
        <div className="w-full inline-flex justify-end">
          <div className="w-1/4 flex flex-col gap-2 nd:w-full">
            <label htmlFor="amount" className="w-auto whitespace-nowrap">Quantidade:</label>
            <Input
              id={"teste"}
              name={"amount"}
              type={"number"}
              setValue={states.setAmountProductOrder}
              value={states.amountProductOrder}
            />
          </div>

        </div>
        <div className="w-auto h-full justify-end items-end flex bottom-0">
          <Button
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
            customStyleIcon="text-white"
            text="Registrar pedido"
            icon={Icons.save}
            type="submit"
            _onClick={states.registerOrder}
          />
        </div>
      </form>
    </>
  )
}