
export const USER_TYPE = {
  ADMIN: 'ADMIN',
  PREMIUM: 'PREMIUM',
  FREE: 'FREE',
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMER: 'CUSTOMER',
  SUBSCRIBER: 'SUBSCRIBER',
};

export const USER_STATUS = {
  BLOCK: 'BLOCK',
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED'
};


const GENDER_TYPE = {
  MASCULINE_CIS: 'MASCULINE_CIS',
  FEMININE_CIS: 'FEMININE_CIS',
  MASCULINE_TRANS: 'MASCULINE_TRANS',
  FEMININE_TRANS: 'FEMININE_TRANS',
  GENDER_NEUTRAL: 'GENDER_NEUTRAL',
  NON_BINARY: 'NON_BINARY',
  AGENDER: 'AGENDER',
  PANGENDER: 'PANGENDER',
  DONT_IDENTIFY: 'DONT_IDENTIFY',
  RATHHER_NOT_ANSWER: 'RATHHER_NOT_ANSWER',
}

export const GENDER_LIST = [
  {
    value: GENDER_TYPE.AGENDER,
    label: "Sem gênero"
  },
  {
    value: GENDER_TYPE.DONT_IDENTIFY,
    label: "Não se identifica com nenhum gênero"
  },
  {
    value: GENDER_TYPE.FEMININE_CIS,
    label: "Fêminino Cisgênero"
  },
  {
    value: GENDER_TYPE.FEMININE_TRANS,
    label: "Fêminino Transgênero"
  },
  {
    value: GENDER_TYPE.GENDER_NEUTRAL,
    label: "Gênero Neutro"
  },
  {
    value: GENDER_TYPE.MASCULINE_CIS,
    label: "Masculino Cisgênero"
  },
  {
    value: GENDER_TYPE.MASCULINE_TRANS,
    label: "Masculino Transgênero"
  },
  {
    value: GENDER_TYPE.NON_BINARY,
    label: "Não Binário"
  },
  {
    value: GENDER_TYPE.PANGENDER,
    label: "Pangênero"
  },
  {
    value: GENDER_TYPE.RATHHER_NOT_ANSWER,
    label: "Prefiro não responder"
  },
];

export const USER_TYPES_LIST = [
  {
    value: USER_TYPE.ADMIN,
    label: "Administrador"
  },
  {
    value: USER_TYPE.PREMIUM,
    label: "Premium"
  },
  {
    value: USER_TYPE.FREE,
    label: "Free"
  },
  {
    value: USER_TYPE.EMPLOYEE,
    label: "Colaborador"
  },
  {
    value: USER_TYPE.CUSTOMER,
    label: "Cliente"
  },
  {
    value: USER_TYPE.SUBSCRIBER,
    label: "Assinante"
  },
];