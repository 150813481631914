import { ECOMMERCE_CMS_SEEDZ_TECH } from '../utils/app.utils';
import base from './base';
import dark from './dark';
import { IThemes as ITema } from './utils';

export const TEMA_PADRAO: string = 'base';
export const TEMA_SYS = localStorage.getItem(ECOMMERCE_CMS_SEEDZ_TECH + '/tema') || TEMA_PADRAO;

export const tema: ITema = {
  base,
  dark,
};