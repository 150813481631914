import { useState } from 'react';

import {
  BASE,
  LOCALSTORAGE_ROUTES,
  SYS_MODULE,
  _ID
} from '../../utils/_index.utils';

type TPaginationProps = {
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  setCurrentPage: (data: number) => void;
};

export default function Pagination(props: TPaginationProps) {
  const totalPages = Math.ceil(props.totalItens / props.itensPerPage);
  const [theme] = useState<any>(localStorage.getItem(SYS_MODULE + LOCALSTORAGE_ROUTES.theme));

  function returnPagePrevious(e: any) {
    e.preventDefault();

    if (props.currentPage > 1) {
      props.setCurrentPage(props.currentPage - 1);
    };
  };

  function continueNextPage(e: any) {
    e.preventDefault();

    if (props.currentPage < totalPages) {
      props.setCurrentPage(props.currentPage + 1);
    };
  };

  function continueCustomPage(numeroPagina: number, e: any) {
    e.preventDefault();

    props.setCurrentPage(numeroPagina);
  };

  return (
    <div className='w-full justify-end flex p-2'>
      <div
        className='inline-flex rounded-lg p-1 justify-center items-center gap-2 bg-slate-200 text-primary text-xs'
      >
        <button
          onClick={returnPagePrevious}
          disabled={props.currentPage === 1}
          className='px-1 hover:font-bold cursor-pointer bg-slate-200'
        >
          &lt;
        </button>
        {
          props.currentPage >= 3 && (
            <>
              <button
                onClick={(e) => continueCustomPage(1, e)}
                className="flex p-2 flex-col justify-center items-center gap-2 rounded-lg hover:font-bold bg-slate-200"
                id={`${_ID.pageIndex}0`}
              >
                1
              </button>
              {
                props.currentPage > 2 &&
                <span className="px-1">...</span>
              }
            </>
          )
        }
        {
          props.currentPage > 1 && (
            <button
              onClick={(e) => continueCustomPage(props.currentPage - 1, e)}
              className="flex p-2 flex-col justify-center items-center gap-2 rounded-lg hover:font-bold bg-slate-200"
              id={`${_ID.pageIndex}${props.currentPage - 1}`}
            >
              {
                props.currentPage - 1
              }
            </button>
          )
        }
        <button
          onClick={(e) => continueCustomPage(props.currentPage, e)}
          className={
            `flex p-2 flex-col justify-center items-center gap-2 rounded-lg bg-slate-200
            font-bold ${theme === BASE ? "text-white" : "text-black"}`
          }
          id={`${_ID.pageIndex}${props.currentPage}`}
        >
          {
            props.currentPage
          }
        </button>
        {
          props.currentPage < totalPages && (
            <button
              onClick={(e) => continueCustomPage(props.currentPage + 1, e)}
              className="flex p-2 flex-col justify-center items-center gap-2 rounded-lg hover:font-bold bg-slate-200"
              id={`${_ID.pageIndex}${props.currentPage + 1}`}
            >
              {
                props.currentPage + 1
              }
            </button>
          )
        }
        {
          props.currentPage <= totalPages - 2 && (
            <>
              {
                props.currentPage < totalPages - 2 &&
                <span className="px-1">...</span>
              }
              <button
                onClick={(e) => continueCustomPage(totalPages, e)}
                className="flex p-2 flex-col justify-center items-center gap-2 rounded-lg hover:font-bold bg-slate-200"
                id={`${_ID.pageIndex}${totalPages}`}
              >
                {
                  totalPages
                }
              </button >
            </>
          )
        }
        <button
          onClick={continueNextPage}
          disabled={props.currentPage === totalPages}
          className='px-1 hover:font-bold cursor-pointer bg-slate-200'
        >
          &gt;
        </button>
      </div >
    </div>
  );
};
