type TImageProps = {
  path: string;
  key?: number;
};

export default function Image(props: TImageProps) {
  return (
    <img
      crossOrigin='anonymous'
      src={props.path}
      key={props.key}
      className="bg-cover w-full h-full rounded-md"
      alt="Imagem"
    />
  );
};
