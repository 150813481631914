import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import HooksModal from "../../../../hooks/modal.hooks";

import { TCompanyFormsProps } from "../CompanyForm";
import { ICompany } from "../../../../interfaces/ICompany.i";
import { IColors } from "../../../../interfaces/ILayout.i";
import { IAddress } from "../../../../interfaces/IAddress.i";

import {
  CompanyRegister,
  UpdateCompany
} from "../../../../services/company.services";
import { MediaRegister } from "../../../../services/_index.services";


type TCompanyFormsHooksProps = TCompanyFormsProps & {};
export type TCompanyFormsHooksStates = TCompanyFormsHooksProps & {
  fantasyName: string;
  companyName: string;
  document: string;
  phoneNumber: string;
  email: string;
  slogan: string;
  description: string;
  newFile: any;
  fileKey: number;
  fileRef: any;
  primaryColorLight: string;
  secondaryColorLight: string;
  thirdColorLight: string;
  primaryColorDark: string;
  secondaryColorDark: string;
  thirdColorDark: string;
  alert_disabled: string;
  alert_error: string;
  alert_warning: string;
  overlappingModal: any;
  companyAddress: IAddress[];
  setPrimaryColorLight: (data: string) => void;
  setSecondaryColorLight: (data: string) => void;
  setThirdColorLight: (data: string) => void;
  setPrimaryColorDark: (data: string) => void;
  setSecondaryColorDark: (data: string) => void;
  setThirdColorDark: (data: string) => void;
  setAlert_disabled: (data: string) => void;
  setAlert_error: (data: string) => void;
  setAlert_warning: (data: string) => void;
  handleFile: () => void;
  setNewFile: (data: any) => void;
  setFileKey: (data: number) => void;
  setCompanyName: (data: string) => void;
  setDescription: (data: string) => void;
  setSlogan: (data: string) => void;
  setEmail: (data: string) => void;
  setPhoneNumber: (data: string) => void;
  setDocument: (data: string) => void;
  setFantasyName: (data: string) => void;
  setCompanyAddress: (data: IAddress[]) => void;
  handleRecordCompany: () => void;
};

export const CompanyFormsHooks = ({ ...props }: TCompanyFormsHooksProps): TCompanyFormsHooksStates => {
  const overlappingModal = HooksModal();

  const [fantasyName, setFantasyName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [document, setDocument] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [slogan, setSlogan] = useState<string>("");
  const [description, setDescription] = useState<string>("");


  const [primaryColorLight, setPrimaryColorLight] = useState<string>("");
  const [secondaryColorLight, setSecondaryColorLight] = useState<string>("");
  const [thirdColorLight, setThirdColorLight] = useState<string>("");
  const [primaryColorDark, setPrimaryColorDark] = useState<string>("");
  const [secondaryColorDark, setSecondaryColorDark] = useState<string>("");
  const [thirdColorDark, setThirdColorDark] = useState<string>("");
  const [alert_disabled, setAlert_disabled] = useState<string>("");
  const [alert_error, setAlert_error] = useState<string>("");
  const [alert_warning, setAlert_warning] = useState<string>("");

  const [companyAddress, setCompanyAddress] = useState<IAddress[]>([]);


  const fileRef = useRef<HTMLInputElement | null>(null);
  const [newFile, setNewFile] = useState<any>();

  const [fileKey, setFileKey] = useState<number>(0);

  useEffect(() => {
    let item: ICompany | null = props.featuredCompany;
    if (!item) return;

    setFantasyName(item.fantasyName);
    setCompanyName(item.companyName);
    setDocument(item.document);
    setPhoneNumber(item.phone);
    setEmail(item.email);
    setSlogan(item.slogan);
    setDescription(item.description);

    let color: IColors = item.layout.colors;
    setPrimaryColorLight(color.primaryColorLight);
    setSecondaryColorLight(color.secondaryColorLight);
    setThirdColorLight(color.thirdColorLight);
    setPrimaryColorDark(color.primaryColorDark);
    setSecondaryColorDark(color.secondaryColorDark);
    setThirdColorDark(color.thirdColorDark);
    setAlert_disabled(color.alertDisabled);
    setAlert_error(color.alertError);
    setAlert_warning(color.alertWarning);

    setCompanyAddress(item.address);
  }, [props.featuredCompany])

  useEffect(() => {
    if (!newFile || !props.featuredCompany?._id)
      return;

    const form = new FormData();
    form.append("file", newFile);
    form.append("company", props.featuredCompany?._id);

    async function uploadMedia() {
      const resp = await MediaRegister(form);

      let company = props.featuredCompany;
      company.image = resp;

      const answer = await UpdateCompany(props.featuredCompany?._id, company);
      if (answer.success)
        toast.success(answer.message);

      if (resp)
        setFileKey(fileKey + 1);
    };

    uploadMedia();
  }, [
    newFile
  ]);

  function handleFile() {
    if (fileRef.current) {
      fileRef.current.click();
    };
  };

  async function handleRecordCompany() {
    const obj = {
      _id: props.featuredCompany?._id,
      address: companyAddress,
      companyName: companyName,
      description: description,
      document: document,
      email: email,
      employees: props.featuredCompany?.employees,
      fantasyName: fantasyName,
      layout: {
        banner: null,
        colors: {
          alertDisabled: alert_disabled,
          alertError: alert_error,
          alertWarning: alert_warning,
          primaryColorDark: primaryColorDark,
          primaryColorLight: primaryColorLight,
          secondaryColorDark: secondaryColorDark,
          secondaryColorLight: secondaryColorLight,
          thirdColorDark: thirdColorDark,
          thirdColorLight: thirdColorLight
        }
      },
      phone: phoneNumber,
      slogan: slogan,
      status: "FREE",
      type: "ECOMMERCE",
      image: null,
    } as ICompany;

    let resp: any;
    if (!obj._id) {
      delete obj._id;

      resp = await CompanyRegister(obj);

    } else
      resp = await UpdateCompany(obj._id, obj);

    toast.success(resp?.message);
  };


  return {
    ...props,
    fantasyName,
    companyName,
    document,
    phoneNumber,
    email,
    slogan,
    description,
    fileKey,
    newFile,
    fileRef,
    companyAddress,
    primaryColorLight,
    secondaryColorLight,
    thirdColorLight,
    primaryColorDark,
    secondaryColorDark,
    thirdColorDark,
    alert_disabled,
    alert_error,
    alert_warning,
    overlappingModal,
    setPrimaryColorLight,
    setSecondaryColorLight,
    setThirdColorLight,
    setPrimaryColorDark,
    setSecondaryColorDark,
    setThirdColorDark,
    setAlert_disabled,
    setAlert_error,
    setAlert_warning,
    setNewFile,
    handleFile,
    setFileKey,
    setDescription,
    setSlogan,
    setEmail,
    setPhoneNumber,
    setCompanyName,
    setFantasyName,
    setDocument,
    setCompanyAddress,
    handleRecordCompany
  }
}