import {
  CategoriesFormProductsHooks,
  TCategoriesFormHooksStates,
  TCategoriesFormProductsHooksStates
} from "./_index.partials";

import {
  Image,
  Spinner
} from "../../../components/_index.components";


export type TCategoriesFormProductsProps = TCategoriesFormHooksStates & {};

export default function CategoriesFormProducts({ ...props }: TCategoriesFormProductsProps) {

  const states: TCategoriesFormProductsHooksStates = CategoriesFormProductsHooks({ ...props } as TCategoriesFormProductsProps);

  return (
    <>
      <div className="w-full max-h-full overflow-auto flex flex-col gap-3 p-4">
        <h2 className="font-bold text-xl">Produtos da Categoria</h2>
        <div className="flex flex-col w-full h-full p-2 gap-4">
          {
            !states.loadingList
              ?
              <div className="w-full h-full overflow-scroll grid grid-cols-9 gap-2 nd:grid-cols-2">
                {
                  states.categoryProductList
                    ?.map((item, i) => (
                      <div
                        // onClick={() => states.handleProductPhoto(item)}
                        key={i}
                        className={
                          `flex flex-col gap-2 w-full bg-slate-200 rounded-md h-[20vh] cursor-pointer p-1 justify-start items-center 
                          hover:filter hover:brightness-90 text-[#26c59f]`
                        }>
                        <div className="w-full min-w-full h-[80%] rounded-md ring-2 ring-white bg-white">
                          {
                            item.image?.length &&
                            <Image
                              path={process.env.REACT_APP_BUCKET_URL + item.image[0]?.imageThumbUrl}
                            />
                          }
                        </div>
                        <p className="whitespace-nowrap overflow-hidden text-ellipsis w-full text-center px-2">
                          {item.name}
                        </p>
                      </div>
                    ))
                }
              </div>
              : <Spinner />
          }
        </div>
      </div>
    </>
  )
}