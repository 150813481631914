import { GoogleMap, LoadScript, Polyline, Marker } from '@react-google-maps/api';
// import { ID } from '../../utils/_id/_id.utils';

const GOOGLE_KEY = "AIzaSyDo8o9OpRZHpBQXF2ocLRr5gEEeRLSUGV0"
const styledMap = {
  height: "100%",
  width: "100%"
};

const path = [
  { lat: -23.593181, lng: -46.637808 },
  { lat: -23.592945, lng: -46.636982 },
  { lat: -23.599112, lng: -46.636576 }
];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 5,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  paths: path,
  zIndex: 1
};

export default function GoogleMaps() {

  return (
    <div className='flex flex-col w-full h-full items-center justify-center p-2 rounded-md bg-primary-background mt-2'>
      <LoadScript
        // id={ID.company_map}
        googleMapsApiKey={GOOGLE_KEY}
        libraries={['places']}>
        <GoogleMap
          mapContainerStyle={styledMap}
          zoom={15}
          center={path[0]}
        >

          {
            path[0] &&
            <Marker
              position={path[0]}
              label={"A"}
            />
          }
          {
            path &&
            <Marker
              position={path[(path.length - 1)]}
              label={"B"}
            />
          }

          <Polyline
            path={path}
            options={options}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  )
};