import { useEffect, useState } from "react";
import { TDashboardPagesProps } from "../DashboardPages";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Title,
  ArcElement,
  LineElement,
  PointElement
} from 'chart.js';

import { useAuth } from "../../_index.pages";
import { ICompany } from "../../../interfaces/ICompany.i";

import {
  GetCompany,
  GetConsumePerPeriod,
  GetConsumePerUser,
  GetInputsOfTheYearByMonth,
  GetInputsPerPeriod,
  GetProductsPerPeriod,
  GetRevenueOfTheYearByMonth,
  GetRevenueReceivedOfTheYearByMonth,
  TGetConsumePerPeriod
} from "../../../services/_index.services";

import {
  convertDate,
  formatDate,
  INPUT_CATEGORIES_LIST,
  pastDaysCalculateDate
} from "../../../utils/_index.utils";


ChartJS.register(LineElement, PointElement, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, Title);

type TDashboardPagesHooksProps = TDashboardPagesProps & {};
export type TDashboardPagesHooksStates = TDashboardPagesHooksProps & {
  data: any;
  dataUser: any;
  dataProducts: any;
  dataRevenues: any;
  dataInputs: any;
  dataSellingXInputs: any;
  options: any;
  optionsUser: any;
  optionsProducts: any;
  optionsRevenues: any;
  optionsInputs: any;
  optionsCompareInputsXSell: any;
  company: ICompany;
  totalPeriod: string;
  totalProductPeriod: number;
  initDatePeriod: string;
  endDatePeriod: string;
  totalExpensePeriod: string;
  totalRecived: string;
  totalDue: string;
  profitPercentPeriod: string;
  setInitDatePeriod: (d: string) => void;
  setEndDatePriod: (d: string) => void;
};

export const DashboardPagesHooks = ({ ...props }: TDashboardPagesHooksProps): TDashboardPagesHooksStates => {
  const { authState } = useAuth();

  const currentYear = new Date().getFullYear();
  const monthsBR = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const monthsEN = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];
  const currentMonth = new Date().getMonth();

  const [company, setCompany] = useState<ICompany>();
  const [revenueMonth, setRevenueMonth] = useState<any[]>([]);
  const [inputsMonth, setInputsMonth] = useState<any[]>([]);
  const [revenueReceivedMonth, setRevenueReceivedMonth] = useState<any[]>([]);

  const initalValue = 0;

  const [totalPeriod, setTotalPeriod] = useState<string>(initalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  const [totalExpensePeriod, setTotalExpensePeriod] = useState<string>(initalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  const [totalProductPeriod, setTotalProductPeriod] = useState<number>(0);
  const [totalRecived, setTotalRecived] = useState<string>(initalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  const [totalDue, setTotalDue] = useState<string>(initalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  const [profitPercentPeriod, setProfitPercentPeriod] = useState<string>("0,00%");

  const [initDatePeriod, setInitDatePeriod] = useState<string>(convertDate(pastDaysCalculateDate(30)));
  const [endDatePeriod, setEndDatePriod] = useState<string>(convertDate(new Date()));

  const [data, setData] = useState<any>({ labels: [], datasets: [] });
  const [dataUser, setDataUser] = useState<any>({ labels: [], datasets: [] });
  const [dataProducts, setDataProducts] = useState<any>({ labels: [], datasets: [] });
  const [dataRevenues, setDataRevenues] = useState<any>({ labels: [], datasets: [] });
  const [dataInputs, setDataInputs] = useState<any>({ labels: [], datasets: [] });
  const [dataSellingXInputs, setDataSellingXInputs] = useState<any>({ labels: [], datasets: [] });

  useEffect(() => {
    if (!authState?.user?.company || company)
      return;

    async function getRevenueByMonth() {
      const resp = await GetRevenueOfTheYearByMonth(authState?.user?.company);
      const revenueArray = monthsEN.map(month => resp[month] || 0);
      setRevenueMonth(revenueArray);
    };

    async function getInputsByMonth() {
      const resp = await GetInputsOfTheYearByMonth(authState?.user?.company);
      const inputsArray = monthsEN.map(month => resp[month] || 0);
      setInputsMonth(inputsArray);
    };

    async function getOtherIndicatorByMonth() {
      const resp = await GetRevenueReceivedOfTheYearByMonth(authState?.user?.company);
      const otherIndicatorArray = monthsEN.map(month => resp[month] || 0);
      setRevenueReceivedMonth(otherIndicatorArray);
    };

    async function getConsumePerUser() {
      const resp = await GetConsumePerUser({
        company: authState?.user?.company
      });

      let usersNames = resp.map(item => item.userName);
      let totalAmountPaid = resp.map(item => item.totalAmountPaid);
      let totalAmountDue = resp.map(item => item.totalAmountDue);

      let totalRecived = totalAmountPaid
        .reduce((acc, curr) => acc + curr, 0);

      let totalDue = totalAmountDue
        .reduce((acc, curr) => acc + curr, 0);


      setTotalRecived(totalRecived?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
      setTotalDue(totalDue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));

      setDataUser({
        labels: usersNames,
        datasets: [

          {
            label: `Total Pago`,
            data: totalAmountPaid,
            backgroundColor: 'rgba(60, 247, 22, 0.2)',
            borderColor: '#2eee14',
            borderWidth: 1,
          },
          {
            label: `Total Devido`,
            data: totalAmountDue,
            backgroundColor: 'rgba(252, 228, 15, 0.2)',
            borderColor: '#e3fa11',
            borderWidth: 1,
          }
        ],
      });

      setDataRevenues({
        labels: ["Recebido", "À receber"],
        datasets: [
          {
            label: `Valores`,
            data: [totalRecived, totalDue],
            backgroundColor: [
              'rgba(93, 247, 22, 0.2)',
              'rgba(23, 144, 243, 0.2)',
            ],
            borderColor: [
              '#22c55e',
              '#3b82f6',
            ],
            borderWidth: 1,
          },
        ],
      });

    };

    async function getCompany() {
      if (!authState?.user?.company) return;

      const resp = await GetCompany(authState?.user?.company);
      setCompany(resp?.data?.company);
    };

    getCompany();
    getConsumePerUser();
    getOtherIndicatorByMonth();
    getRevenueByMonth();
    getInputsByMonth();
  }, [authState?.user]);

  useEffect(() => {
    if (!authState?.user?.company) return;

    function areDatesValid() {
      const start = new Date(initDatePeriod);
      const end = new Date(endDatePeriod);
      return !isNaN(start.getTime()) && !isNaN(end.getTime());
    };

    if (!areDatesValid())
      return;

    const params = {
      company: authState?.user?.company,
      endDate: endDatePeriod,
      initDate: initDatePeriod
    } as TGetConsumePerPeriod;

    async function getConsumePerPeriod() {
      const resp = await GetConsumePerPeriod(params);

      setTotalPeriod(resp?.totalValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? "0");
      setTotalProductPeriod(resp?.totalProducts ?? 0);
    };

    async function getProductsPerPeriod() {
      const resp = await GetProductsPerPeriod(params);

      let name = resp.map(item => item.name);
      let totalConsumed = resp.map(item => item.totalConsumed);
      let totalProducts = resp.map(item => item.totalProducts);

      setDataProducts({
        labels: name,
        datasets: [

          {
            label: `Total Produtos`,
            data: totalProducts,
            backgroundColor: 'rgba(247, 22, 198, 0.2)',
            borderColor: '#f718d2',
            borderWidth: 1,
          },
        ],
      });

    };

    async function getInputsPerPeriod() {
      const resp = await GetInputsPerPeriod(params);



      let names = resp.map(obj => {
        return INPUT_CATEGORIES_LIST.find(item => item.value === obj._id)?.label;
      });

      let total = resp.map(item => item.total);

      setTotalExpensePeriod(
        total
          ?.reduce((acc, curr) => acc + curr, 0)
          ?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        ?? 0
      );


      setDataInputs({
        labels: names,
        datasets: [
          {
            label: `Total Insumos`,
            data: total,
            backgroundColor: 'rgba(247, 22, 22, 0.2)',
            borderColor: '#f71818',
            borderWidth: 1,
          },
        ],
      });

    };

    getConsumePerPeriod();
    getProductsPerPeriod();
    getInputsPerPeriod();
  }, [
    authState?.user?.company,
    endDatePeriod,
    initDatePeriod

  ])

  useEffect(() => {
    setData({
      labels: monthsBR.slice(0, currentMonth + 1),
      datasets: [
        {
          label: `Receita de Faturamento Produzida de ${currentYear}`,
          data: revenueMonth.slice(0, currentMonth + 1),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
        {
          label: `Receita de Faturamento Recebida de ${currentYear}`,
          data: revenueReceivedMonth.slice(0, currentMonth + 1),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    });
  }, [
    revenueMonth,
    revenueReceivedMonth,
  ]);

  useEffect(() => {
    setDataSellingXInputs({
      labels: monthsBR.slice(0, currentMonth + 1),
      datasets: [
        {
          label: `Insumos`,
          data: inputsMonth.slice(0, currentMonth + 1),
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
        {
          label: `Produzido`,
          data: revenueMonth.slice(0, currentMonth + 1),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
        {
          label: `Recebido`,
          data: revenueReceivedMonth.slice(0, currentMonth + 1),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    });
  }, [
    inputsMonth,
    revenueMonth,
    revenueReceivedMonth,
  ]);

  useEffect(() => {
    if (!totalPeriod || !totalExpensePeriod)
      return;

    const total = Number(totalPeriod.replace(/[^0-9,-]+/g, "").replace(",", "."));
    const totalExpense = Number(totalExpensePeriod.replace(/[^0-9,-]+/g, "").replace(",", "."));

    let profitPercentage = 0;

    if (total > 0)
      profitPercentage = ((total - totalExpense) / total) * 100;

    setProfitPercentPeriod(profitPercentage.toFixed(2).replace(".", ",") + "%");
  }, [
    totalPeriod,
    totalExpensePeriod,
  ]);


  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `R$ ${value?.toFixed(2)}`;
          }
        }
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Resumo de Faturamento Gerado e Faturamento Recebido por mês do ano de ' + new Date().getFullYear(),
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  };

  const optionsUser = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return `R$ ${value?.toFixed(2)}`;
          }
        }
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Resumo de Pagamentos e Dívidas por Assinante',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  };

  const optionsProducts = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return value;
          }
        }
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Resumo de Venda de Produtos por Período de ' + `${formatDate(initDatePeriod, { onlyDate: true })} à ${formatDate(endDatePeriod, { onlyDate: true })}`,
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  };

  const optionsRevenues = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Valores recebidos e à receber',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: R$ ${tooltipItem.raw?.toFixed(2)?.replace(".", ",")}`;
          }
        }
      }
    },
  };

  const optionsInputs = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return value;
          },
          font: {
            size: 10,
          },
        }
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Resumo de Despesas por tipos de Insumos no Período de ' + `${formatDate(initDatePeriod, { onlyDate: true })} à ${formatDate(endDatePeriod, { onlyDate: true })}`,
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  };

  const optionsCompareInputsXSell = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `R$ ${value?.toFixed(2)}`;
          },
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Resumo de Despesas Vs Faturamento Gerado Vs Faturamento Recebido por mês do ano de ' + new Date().getFullYear(),
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  };

  return {
    data,
    dataUser,
    options,
    optionsUser,
    company,
    totalPeriod,
    totalProductPeriod,
    initDatePeriod,
    endDatePeriod,
    totalRecived,
    totalDue,
    optionsProducts,
    dataProducts,
    dataRevenues,
    optionsRevenues,
    dataInputs,
    optionsInputs,
    dataSellingXInputs,
    optionsCompareInputsXSell,
    totalExpensePeriod,
    profitPercentPeriod,
    setInitDatePeriod,
    setEndDatePriod,
  };
};
