import { useEffect, useState } from "react";

import HooksModal from "../../../hooks/modal.hooks";

import { TProductsPageProps } from "../ProductsPage";
import { _ID } from "../../../utils/_index.utils";

import {
  GetProductList
} from "../../../services/_index.services";

import {
  IProduct
} from "../../../interfaces/_index.interfaces";
import { TAuthState, useAuth } from "../../_index.pages";

type TProductsPageHooksProps = TProductsPageProps & {}
export type TProductsPageHooksStates = TProductsPageHooksProps & {
  searchBar: string;
  featuredProduct: IProduct;
  productsList: IProduct[];
  productsListDisplayed: IProduct[];
  open: boolean;
  featuredTab: string;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  reloadList: boolean;
  featuredRoute: string;
  authState: TAuthState;
  setFeaturedRoute: (d: string) => void;
  setReloadList: (d: boolean) => void;
  setCurrentPage: (d: number) => void;
  setFeaturedTab: (d: string) => void;
  setSearchBar: (d: string) => void;
  setFeaturedProduct: (d: IProduct) => void;
  setProductsList: (d: IProduct[]) => void;
  setProductsListDisplayed: (d: IProduct[]) => void;
  close: () => void;
  toggle: () => void;
};

export const tabList = [
  {
    nome: "Geral",
    _id: _ID.tabInfos
  },
  {
    nome: "Emb.",
    _id: _ID.tabPackaging
  },
  {
    nome: "Fotos",
    _id: _ID.tabPhotos
  },
  {
    nome: "Rel.",
    _id: _ID.tabRelated
  },
  {
    nome: "Estoque",
    _id: _ID.tabStock
  },
]

export const ProductsPageHooks = ({ ...props }: TProductsPageHooksProps): TProductsPageHooksStates => {
  const { authState } = useAuth();

  const [featuredRoute, setFeaturedRoute] = useState<string>("");

  const { open, toggle, close } = HooksModal();

  const [searchBar, setSearchBar] = useState<string>("");

  const [featuredProduct, setFeaturedProduct] = useState<IProduct>();

  const [featuredTab, setFeaturedTab] = useState<string>(_ID.tabInfos);

  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [productsListDisplayed, setProductsListDisplayed] = useState<IProduct[]>([]);

  const [reloadList, setReloadList] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [serachedItem, setSearchedItem] = useState<string>("");

  const itensPerPage = 10;
  const totalItens = productsList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    const newListCategories: IProduct[] = serachedItem
      ? productsList
        ?.filter((item) => {
          const buscaUpperCase = serachedItem.toUpperCase();
          const { name, brand } = item;
          return (
            name?.toUpperCase().includes(buscaUpperCase) ||
            brand?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : productsList?.slice(indexOfFirstIten, indexOfLastIten);

    setProductsListDisplayed(newListCategories);
  }, [serachedItem, productsList, indexOfFirstIten, indexOfLastIten]);


  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    async function getProductList() {
      let resp = await GetProductList({ company: authState.user?.company });
      setProductsList(resp);
    };

    getProductList();
    setReloadList(false);
  }, [
    reloadList,
    authState
  ]);

  return {
    ...props,
    searchBar,
    featuredProduct,
    open,
    productsList,
    productsListDisplayed,
    featuredTab,
    itensPerPage,
    totalItens,
    currentPage,
    reloadList,
    featuredRoute,
    authState,
    setFeaturedRoute,
    setReloadList,
    setCurrentPage,
    setFeaturedTab,
    setProductsList,
    setProductsListDisplayed,
    setFeaturedProduct,
    setSearchBar,
    close,
    toggle,

  }
};