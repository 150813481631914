import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TProductFormStockProps } from "../ProductFormStock";
import { IStock } from "../../../../interfaces/_index.interfaces";

import {
  STOCK_METHODS,
  STOCK_TRANSATION
} from "../../../../utils/_index.utils";

import {
  GetStockCtrlProduct,
  GetStockListWithFilter,
  StockRegister,
  TStocksFilter,
  GetProduct,
  UpdateProduct,
} from "../../../../services/_index.services";

import HooksModal, { THooksModalStates } from "../../../../hooks/modal.hooks";

type TProductFormStockHooksProps = TProductFormStockProps & {};
export type TProductFormStockHooksStates = TProductFormStockHooksProps & {
  stockProductList: IStock[];
  stockProductListDisplayed: IStock[];
  insertStockAmount: string;
  withdrawStockAmount: string;
  stockProductBalance: number;
  reloadList: boolean;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  stockProductBatch: string;
  stockProductReason: string;
  EnableProduct: THooksModalStates;
  setCurrentPage: (d: number) => void;
  setInsertStockAmount: (d: string) => void;
  setWithdrawStockAmount: (d: string) => void;
  handleProductStock: (d: string) => void;
  setStockProductBatch: (d: string) => void;
  setStockProductReason: (d: string) => void;
  handleEnableProduct: () => void;
};

export const ProductFormStockHooks = ({ ...props }: TProductFormStockHooksProps): TProductFormStockHooksStates => {
  const EnableProduct = HooksModal();

  const [stockProductListDisplayed, setStockProductListDisplayed] = useState<IStock[]>([]);
  const [stockProductList, setStockProductList] = useState<IStock[]>([]);
  const [stockProductBalance, setStockProductBalance] = useState<number>();
  const [reloadList, setReloadList] = useState<boolean>(true);

  const [insertStockAmount, setInsertStockAmount] = useState<string>("");
  const [withdrawStockAmount, setWithdrawStockAmount] = useState<string>("");
  const [stockProductBatch, setStockProductBatch] = useState<string>("");
  const [stockProductReason, setStockProductReason] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [serachedItem, setSearchedItem] = useState<string>("");

  const itensPerPage = 9;
  const totalItens = stockProductList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    if (!stockProductList.length)
      return;

    const newListStockLog: IStock[] = serachedItem
      ? stockProductList
        ?.filter((item) => {
          const buscaUpperCase = serachedItem.toUpperCase();
          const { method, transation } = item;
          return (
            method?.toUpperCase().includes(buscaUpperCase) ||
            transation?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : stockProductList?.slice(indexOfFirstIten, indexOfLastIten);

    setStockProductListDisplayed(newListStockLog);
  }, [serachedItem, stockProductList, indexOfFirstIten, indexOfLastIten]);


  useEffect(() => {
    if (!props.featuredProduct._id || !reloadList)
      return;

    async function getStockCtrlProduct() {
      const resp = await GetStockCtrlProduct(props.featuredProduct._id);
      const balance = resp?.balance

      setStockProductBalance(balance);

      if (balance || balance === 0)
        if (balance > 0 && !props.featuredProduct.visible)
          return EnableProduct.toggle();
        else if (balance === 0)
          return toast.info("O produto foi ocultado da visualização dos clientes por falta de estoque.")
        else if (balance < 0)
          return toast.warning("O estoque está negativo! Por favor, ajuste para continuar as vendas.")
    };

    async function getStockFilterList() {
      const resp = await GetStockListWithFilter({
        product: props.featuredProduct._id
      } as TStocksFilter);

      setStockProductList(resp);
      if (resp?.length) {
        await getStockCtrlProduct();
      } else {
        setStockProductBalance(0);
      }

      setReloadList(false);
    };

    getStockFilterList();
  }, [
    props.featuredProduct,
    reloadList
  ]);

  async function handleProductStock(stockTransation: string) {
    if (!props.featuredProduct)
      return;

    const amount = stockTransation === STOCK_TRANSATION.INCLUDE
      ? parseInt(insertStockAmount)
      : parseInt(withdrawStockAmount)

    if (!amount)
      return toast.warning("Por favor, insira a quantidade que deseja inserir ou retirar do estoque!");

    const resp = await StockRegister({
      amount: amount,
      method: STOCK_METHODS.MANUAL,
      product: props.featuredProduct,
      company: props.featuredProduct?.company,
      transation: stockTransation,
      batch: stockProductBatch,
      reason: stockProductReason,
      user: null,
    });

    const respProduct = await GetProduct(props.featuredProduct._id);
    props.setFeaturedProduct(respProduct.data.product);
    props.setReloadList(true);

    toast.success(resp?.message);
    setInsertStockAmount("");
    setWithdrawStockAmount("");
    setStockProductBatch("");
    setStockProductReason("");
    setReloadList(true);
  };

  async function handleEnableProduct() {
    const product = props.featuredProduct;

    product.visible = true;
    const resp = await UpdateProduct(product._id, product);
    toast.success("Produto habilitado com sucesso!");
    props.setFeaturedProduct(resp.data.product);
    props.setReloadList(true);
    EnableProduct.close();
  };

  return {
    ...props,
    stockProductList,
    stockProductListDisplayed,
    insertStockAmount,
    withdrawStockAmount,
    stockProductBalance,
    reloadList,
    itensPerPage,
    totalItens,
    currentPage,
    stockProductBatch,
    stockProductReason,
    EnableProduct,
    setStockProductBatch,
    setStockProductReason,
    setCurrentPage,
    setInsertStockAmount,
    setWithdrawStockAmount,
    handleProductStock,
    handleEnableProduct,
  }
}