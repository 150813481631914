import { toast } from "react-toastify";
import { api } from "./_axios.services"

export interface IAuthentication {
  email: string;
  password: string;
};

export async function Authentication(item: IAuthentication) {
  try {
    const response = await api.post("auth/login", item);
    const data = response.data

    return data;
  } catch (error: any) {
    try {
      let obj = JSON.parse(error?.request?.response)
      toast.error(obj.erro?.message)
    } catch (error) {
      toast.error(error.message)
    }
  };
};