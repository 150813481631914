import { useEffect, useState } from "react";
import { TCompanyEmployeeFormProps } from "../CompanyEmployeeForm";
import { GetUser, UpdateCompanyEmployees, UpdateUser, UserRegister } from "../../../../services/_index.services";

import {
  IEmployees,
  IUser
} from "../../../../interfaces/_index.interfaces";
import { toast } from "react-toastify";
import { GENERIC_ID_ } from "../../../../utils/_index.utils";

type TCompanyEmployeeFormHooksProps = TCompanyEmployeeFormProps & {};
export type TCompanyEmployeeFormHooksStates = TCompanyEmployeeFormHooksProps & {
  employeeName: string;
  employeeLastName: string;
  employeeDocument: string;
  employeeDocumentRG: string;
  employeeBirthday: any;
  employeeGender: string;
  employeeEmail: string;
  employeePhone: string;
  employeesList: IUser[];
  idEmployeeSelected: string;
  loadingFormEmployee: boolean;
  setEmployeeName: (data: string) => void;
  setEmployeeLastName: (data: string) => void;
  setEmployeeDocument: (data: string) => void;
  setEmployeeDocumentRG: (data: string) => void;
  setEmployeeBirthday: (data: any) => void;
  setEmployeeGender: (data: string) => void;
  setEmployeeEmail: (data: string) => void;
  setEmployeePhone: (data: string) => void;
  handleEmployees: (data: number | string | undefined) => void;
  removeEmployee: (data: number | string | undefined) => void;
  handleRecordEmployee: () => void;
  includeNewEmployee: () => void;
};

export const CompanyEmployeeFormHooks = ({ ...props }: TCompanyEmployeeFormHooksProps): TCompanyEmployeeFormHooksStates => {
  const [employeeName, setEmployeeName] = useState<string>("");
  const [employeeLastName, setEmployeeLastName] = useState<string>("");
  const [employeeDocument, setEmployeeDocument] = useState<string>("");
  const [employeeDocumentRG, setEmployeeDocumentRG] = useState<string>("");
  const [employeeBirthday, setEmployeeBirthday] = useState<any>();
  const [employeeGender, setEmployeeGender] = useState<string>("");
  const [employeeEmail, setEmployeeEmail] = useState<string>("");
  const [employeePhone, setEmployeePhone] = useState<string>("");

  const [employeesList, setEmployeesList] = useState<IUser[]>([]);
  const [idEmployeeSelected, setIdEmployeeSelected] = useState<string>("");

  const [loadingFormEmployee, setLoadingFormEmployee] = useState<boolean>(true);


  useEffect(() => {
    let employees: IEmployees[] = props.featuredCompany?.employees;
    if (!employees || !employees.length)
      return setLoadingFormEmployee(false);

    async function fetchData() {
      const userList = await getUser();
      if (!userList.length)
        return;

      setEmployeesList(userList);
      setIdEmployeeSelected(userList[0]._id);
      setEmployeeName(userList[0].name)
      setEmployeeLastName(userList[0].lastname)
      setEmployeeDocument(userList[0].document)
      setEmployeeDocumentRG(userList[0].document_rg)
      setEmployeeBirthday(userList[0].birthday)
      setEmployeeGender(userList[0].gender)
      setEmployeeEmail(userList[0].email)
      setEmployeePhone(userList[0].phone)
    }

    fetchData();
  }, []);

  async function getUser() {
    let employees: IEmployees[] = props.featuredCompany?.employees;
    let userList: IUser[] = [];

    const promises = employees.forEach(async (item) => {
      if (!item.user)
        return;

      const resp = await GetUser(item.user);
      if (resp)
        userList.push(resp);
    });

    // await Promise.all(promises);
    return userList;
  }

  const employeesComponents = [
    { value: employeeName, function: setEmployeeName, tuple: "name", _id: "" },
    { value: employeeLastName, function: setEmployeeLastName, tuple: "lastname", _id: "" },
    { value: employeeDocument, function: setEmployeeDocument, tuple: "document", _id: "" },
    { value: employeeDocumentRG, function: setEmployeeDocumentRG, tuple: "document_rg", _id: "" },
    { value: employeeBirthday, function: setEmployeeBirthday, tuple: "birthday", _id: "" },
    { value: employeeGender, function: setEmployeeGender, tuple: "gender", _id: "" },
    { value: employeeEmail, function: setEmployeeEmail, tuple: "email", _id: "" },
    { value: employeePhone, function: setEmployeePhone, tuple: "phone", _id: "" },
  ];

  useEffect(() => {
    if (!idEmployeeSelected)
      return;

    for (let component of employeesComponents) {
      if (component.value || typeof component.value === "boolean") {
        setEmployeesList(prevList =>
          prevList.map((item) =>
            item._id === idEmployeeSelected
              ? { ...item, [component.tuple]: component.value }
              : item
          )
        );
      };
    };

    setLoadingFormEmployee(false);
  }, [
    employeeName,
    employeeLastName,
    employeeDocument,
    employeeDocumentRG,
    employeeBirthday,
    employeeGender,
    employeeEmail,
    employeePhone,
    idEmployeeSelected,
  ]);

  function cleanForm() {
    setEmployeeName("")
    setEmployeeLastName("")
    setEmployeeDocument("")
    setEmployeeDocumentRG("")
    setEmployeeBirthday("")
    setEmployeeGender("")
    setEmployeeEmail("")
    setEmployeePhone("")

    return;
  };

  function handleEmployees(_id: number | string | undefined) {
    if (!_id) return cleanForm();

    const index = employeesList.findIndex(obj => obj._id === _id);


    let employees: IUser[] | undefined = employeesList;
    if (!employees || !employees.length)
      return;

    setIdEmployeeSelected(employees[index]._id);
    setEmployeeName(employees[index].name);
    setEmployeeLastName(employees[index].lastname);
    setEmployeeDocument(employees[index].document);
    setEmployeeDocumentRG(employees[index].document_rg);
    setEmployeeBirthday(employees[index].birthday);
    setEmployeeGender(employees[index].gender);
    setEmployeeEmail(employees[index].email);
    setEmployeePhone(employees[index].phone);
  };

  function removeEmployee(_id: number | string | undefined) {
    const newList = employeesList.filter(item => item?._id !== _id) ?? [];

    setEmployeesList(newList);
    handleEmployees(newList.length ? newList[0]._id : null);

    toast.success("Colaborador foi foi removido com sucesso!")
  };

  async function handleRecordEmployee() {
    if (!idEmployeeSelected) return;

    const updatedEmployees = {
      _id: idEmployeeSelected,
      birthday: employeeBirthday,
      document: employeeDocument,
      document_rg: employeeDocumentRG,
      email: employeeEmail,
      gender: employeeGender,
      lastname: employeeLastName,
      name: employeeName,
      phone: employeePhone,
      status: "ACTIVE",
      type: "EMPLOYEE",
      company: props.featuredCompany._id,
    };

    const index = employeesList.findIndex(employee => employee._id === idEmployeeSelected);
    if (index === -1) {
      toast.error("Colaborador selecionado não encontrado na lista.");
      return;
    }

    const updatedEmployeesList = [...employeesList];
    updatedEmployeesList[index] = updatedEmployees;

    setEmployeesList(updatedEmployeesList);

    let resp: any;
    if (updatedEmployees._id.includes(GENERIC_ID_)) {
      delete updatedEmployees._id;

      resp = await UserRegister(updatedEmployees);

      if (resp && resp?.data.user?._id)
        updatedEmployeesList[index]._id = resp.data.user._id;

    } else {
      resp = await UpdateUser(updatedEmployees._id, updatedEmployees);
    }

    props.featuredCompany.employees = updatedEmployeesList.map(user => ({
      user: user._id,
      job: user.type
    }));

    let x = await UpdateCompanyEmployees(props.featuredCompany._id, props.featuredCompany);



    toast.success(resp?.message);
  }


  function includeNewEmployee() {
    const newEmployee = {
      _id: GENERIC_ID_ + employeesList.length?.toString(),
      birthday: null,
      document: "",
      document_rg: "",
      email: "",
      gender: "",
      lastname: "",
      name: "",
      phone: "",
      status: "ACTIVE",
      type: "EMPLOYEE",
      created_at: new Date(),
    } as IUser;

    setEmployeesList([...employeesList, newEmployee]);
    setIdEmployeeSelected(newEmployee._id);
    setEmployeeName(newEmployee.name)
    setEmployeeLastName(newEmployee.lastname)
    setEmployeeDocument(newEmployee.document)
    setEmployeeDocumentRG(newEmployee.document_rg)
    setEmployeeBirthday(newEmployee.birthday)
    setEmployeeGender(newEmployee.gender)
    setEmployeeEmail(newEmployee.email)
    setEmployeePhone(newEmployee.phone)
  };

  return {
    ...props,
    employeeName,
    employeeLastName,
    employeeDocument,
    employeeDocumentRG,
    employeeBirthday,
    employeeGender,
    employeeEmail,
    employeePhone,
    employeesList,
    idEmployeeSelected,
    loadingFormEmployee,
    setEmployeeName,
    setEmployeeLastName,
    setEmployeeDocument,
    setEmployeeDocumentRG,
    setEmployeeBirthday,
    setEmployeeGender,
    setEmployeeEmail,
    setEmployeePhone,
    handleEmployees,
    removeEmployee,
    handleRecordEmployee,
    includeNewEmployee,
  };
};