import { Icons } from "../../assets/icons/_index.icons";
import { Button, Input, Modal, Pagination, Spinner, Selection } from "../../components/_index.components";
import { RoutesPath } from "../../routes/AppRoutes.routes";
import { _ID, formatCurrency } from "../../utils/_index.utils";
import { StructurePages, SubscriberPageHooks, SubscribersDetails, TSubscriberPageHooksStates } from "../_index.pages";

export type TSubscriberPageProps = {};

const tabList = [
  {
    nome: "Infos",
    _id: _ID.tabInfos
  },
  {
    nome: "Compras",
    _id: _ID.tabOrders
  },
  {
    nome: "Pgts.",
    _id: _ID.tabPayments
  },
  {
    nome: "Notas",
    _id: _ID.tabNotes
  },
  {
    nome: "Recibo",
    _id: _ID.tabReceipt
  },
]


export default function SubscriberPage({ ...props }: TSubscriberPageProps) {

  const states: TSubscriberPageHooksStates = SubscriberPageHooks({ ...props } as TSubscriberPageProps);


  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-auto min-h-screen flex flex-col gap-5 relative overflow-hidden nd:p-0">
              <section className="flex flex-col gap-5 p-5 nd:p-2">
                <h1 className="text-bold text-3xl">Assinaturas</h1>
                <div className="w-full h-auto inline-flex gap-8 justify-center items-center">
                  <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                    <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                    <Input
                      id={"teste"}
                      name={"search"}
                      placeholder="Pesquisar"
                      type={"text"}
                      setValue={states.setSearchBar}
                      value={states.searchBar}
                      style="pl-10"
                    />
                  </div>
                  <Button
                    icon={Icons.search}
                    text="Filtros"
                    _onClick={() => { }}
                    customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                    customStyleIcon="text-[#26c59f]"
                  />
                </div>
                <div className="w-full h-auto inline-flex gap-8">
                  <div className="w-1/4 flex flex-col gap-2 nd:w-full ">
                    <label htmlFor="paymentStatus" className="w-auto whitespace-nowrap">Status pagamento:</label>
                    <Selection
                      optionsList={[{ value: true, label: "Pago" }, { value: false, label: "Pendente" }, { value: "Todos", label: "Todos" },]}
                      setValue={states.setFilterPaymentStatus}
                      value={states.filterPaymentStatus}
                    />
                  </div>
                </div>
              </section>
              <div className="w-full h-full gap-2 flex flex-col p-5 nd:p-2">
                {
                  !states.reloadList
                    ? <table className="w-full">
                      <thead className="bg-gray-300">
                        <tr>
                          <th className="px-4 py-2">Pessoa</th>
                          <th className="px-4 py-2">Valor total devido</th>
                          <th className="px-4 py-2">Status pagamento</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {states.subscribersListDisplayed
                          ?.map((item, i) => (
                            <tr
                              className={`bg-gray-100 cursor-pointer hover:filter hover:brightness-90 hover:text-[#26c59f] ${i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'
                                }`}
                              key={i}
                              onClick={() => {
                                states.setFeaturedSubscribeId && states.setFeaturedSubscribeId(item.user?._id);
                                states.setFeaturedTab(tabList[0]._id)
                                states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.subscriberNew)
                              }}
                            >
                              <td className="px-4 py-2 text-start">{item.user?.name} {item.user?.lastname}</td>
                              <td className="px-4 py-2">{formatCurrency(item.totalAmountDue)}</td>
                              <td className="px-4 py-2 text-center items-center flex justify-center">
                                {
                                  item.paidOut
                                    ? <Icons.check className="text-green-500" size={30} />
                                    : <Icons.alert className="text-yellow-500" size={30} />
                                }
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    : <Spinner />
                }
              </div>

              {
                (states.subscribersListDisplayed?.length > 0) &&
                <Pagination
                  itensPerPage={states.itensPerPage}
                  totalItens={states.totalItens}
                  setCurrentPage={states.setCurrentPage}
                  currentPage={states.currentPage}
                />
              }
              {
                states.featuredRoute === RoutesPath.subscriberNew &&
                <div className="w-full h-full max-w-full flex flex-col absolute p-5 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                  <h1 className="text-bold text-3xl">
                    {
                      states.featuredSubscribe?._id
                        ? states.featuredSubscribe?.user?.name + ' ' + states.featuredSubscribe?.user?.lastname
                        : "Novo Usuário"
                    }
                  </h1>
                  <nav className="inline-flex justify-between w-full">
                    <ul className="inline-flex text-gray-400 gap-3">
                      <li>
                        <button
                          onClick={() => {
                            states.setFeaturedSubscribe(null);
                            states.setFeaturedRoute(RoutesPath.subscribers);
                          }}
                        >
                          <span>Usuários</span>
                        </button>
                      </li>
                      <li>&gt;</li>
                      <li className="text-black">
                        {
                          states.featuredSubscribe?._id
                            ? states.featuredSubscribe?.user?.name + ' ' + states.featuredSubscribe?.user?.lastname
                            : "Novo"
                        }
                      </li>
                    </ul>
                    <Button
                      text="Voltar"
                      _onClick={() => {
                        states.setFeaturedSubscribe(null);
                        states.setFeaturedRoute(RoutesPath.subscribers);
                      }}
                      icon={Icons.back}
                    />
                  </nav>
                  <div className="mt-1 z-20 absolute nd:right-2 w-full pr-5 nd:mt-24 nd:pr-0">
                    <div className="inline-flex w-full justify-end pr-20 nd:pr-0">
                      {
                        tabList.map((item, i) => {
                          if ((
                            !states.featuredSubscribe?._id &&
                            (
                              item._id === _ID.tabPhotos ||
                              item._id === _ID.tabRelated ||
                              item._id === _ID.tabStock
                            )
                          )) return;


                          return (
                            <div
                              onClick={() => states.setFeaturedTab(item._id)}
                              className={
                                `cursor-pointer hover:text-[#67a0cf] ring-slate-200 ring-1 hover:ring-[#67a0cf] p-2 rounded-t-md
                                ${item._id === states.featuredTab ? "text-[#26c59f]" : "text-primary"}`
                              }
                              key={i}>
                              <p>
                                {
                                  item.nome
                                }
                              </p>
                            </div>
                          )
                        })
                      }
                    </div>
                    <hr className="h-px" />
                  </div>
                  <SubscribersDetails
                    {...states}
                  />
                </div>
              }
            </div>
          </>
        }
      />
    </>
  )
};