import { Icons } from "../../../assets/icons/_index.icons";
import { Input } from "../../../components/_index.components";
import {
  SubscribersDetailsInfosHooks,
  TSubscribersDetailsHooksStates,
  TSubscribersDetailsInfosHooksStates
} from "../../_index.pages";


export type TSubscribersDetailsInfosProps = TSubscribersDetailsHooksStates & {};

export default function SubscribersDetailsInfos({ ...props }: TSubscribersDetailsInfosProps) {

  const states: TSubscribersDetailsInfosHooksStates = SubscribersDetailsInfosHooks({ ...props } as TSubscribersDetailsInfosProps);

  return (
    <>
      <div className="w-full h-full p-5 flex flex-col gap-8">
        <div className="flex flex-col justify-between gap-6 w-full">
          <div className="flex flex-col gap-2">
            <label htmlFor="totalAmountConsumed">Valor total consumido</label>
            <Input
              id={"teste"}
              name={"totalAmountConsumed"}
              currency={true}
              type={"number"}
              readonly={true}
              style="text-2xl font-bold"
              setValue={() => null}
              value={props.featuredSubscribe?.totalConsumed?.toString()}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="totalAmountPaid">Valor total pago</label>
            <Input
              id={"teste"}
              name={"totalAmountPaid"}
              currency={true}
              type={"number"}
              style="text-2xl font-bold text-green-500"
              readonly={true}
              setValue={() => null}
              value={props.featuredSubscribe?.totalAmountPaid?.toString()}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="totalAmountDue">Valor total devido</label>
            <Input
              id={"teste"}
              name={"totalAmountDue"}
              currency={true}
              type={"number"}
              style="text-2xl font-bold text-yellow-500"
              readonly={true}
              setValue={() => null}
              value={props.featuredSubscribe?.totalAmountDue?.toString()}
            />
          </div>
        </div>
        <div className="w-full h-full flex justify-end">
          <div className="flex flex-col gap-2 max-w-[25%] nd:max-w-full">
            <label htmlFor="addPayment">Valor pagamento</label>
            <div className="inline-flex gap-4 justify-center items-center">
              <Input
                id={"teste"}
                name={"addPayment"}
                type={"number"}
                style="text-2xl font-bold"
                placeholder="0,00"
                setValue={states.setAddPayment}
                value={states.addPayment}
                currency={true}
              />
              <button
                onClick={states.registerPayment}
                className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
              >
                <Icons.register />
                <span>Pagar</span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
};