import { USER_TYPE, USER_TYPES_LIST } from "../../../../utils/_index.utils";
import { TUsersFormInfosProps } from "../UsersFormInfos";

type TUsersFormInfosHooksProps = TUsersFormInfosProps & {};
export type TUsersFormInfosHooksStates = TUsersFormInfosHooksProps & {
  filteredUserTypesList: any[];
};

export const UsersFormInfosHooks = ({ ...props }: TUsersFormInfosHooksProps): TUsersFormInfosHooksStates => {
  const filteredUserTypesList = USER_TYPES_LIST.filter(
    (item) => item.value !== USER_TYPE.ADMIN
  );


  return {
    ...props,
    filteredUserTypesList,
  };
};