
import './index.css';

import { AuthProvider } from './pages/_index.pages';
import { AppRoutes } from './routes/_index.routes';

export default function AppRoot() {

  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};