import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { TProductsFormPhotosProps } from "../ProductsFormPhotos";
import { IImage } from "../../../../interfaces/IImage.i";

import {
  MediaRegister,
  RemoveMedia,
  UpdateCompanyBanner,
  UpdateProductPhotos
} from "../../../../services/_index.services";

type TProductsFormPhotosHooksProps = TProductsFormPhotosProps & {};
export type TProductsFormPhotosHooksStates = TProductsFormPhotosHooksProps & {
  productPhotosList: IImage[];
  idProductPhotoSelected: string | null;

  uploadingImg: boolean;
  imageUrl: string | null;
  fileKey: number;

  fileRef: any;
  setProductPhotosList: (d: IImage[]) => void;
  setIdProductPhotoSelected: (d: string | null) => void;
  removeProductPhoto: (d: string) => void;
  handleProductPhoto: (d: IImage) => void;
  setNewProductPhoto: (d: any) => void;
  setFileKey: (d: number) => void;
  handleFile: () => void;
};

export const ProductsFormPhotosHooks = ({ ...props }: TProductsFormPhotosHooksProps): TProductsFormPhotosHooksStates => {
  const [productPhotosList, setProductPhotosList] = useState<IImage[]>([]);
  const [idProductPhotoSelected, setIdProductPhotoSelected] = useState<string | null>(null);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const [objImg, setObjImg] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>(null);

  const [fileKey, setFileKey] = useState<number>(0);
  const [uploadingImg, setUploadingImg] = useState<boolean>(false);

  const [newProductPhoto, setNewProductPhoto] = useState<any>(null);

  useEffect(() => {
    if (!objImg)
      return;

    let fileName = objImg.imageUrl;

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${fileName}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }, [objImg]);


  useEffect(() => {
    let photos: IImage[] | undefined = props.featuredProduct?.image;
    if (!photos || !photos.length)
      return;

    setProductPhotosList(photos);
    setIdProductPhotoSelected(photos[0]._id);

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${photos[0].imageUrl}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }, []);

  useEffect(() => {
    if (!newProductPhoto || !props.featuredProduct?._id)
      return;

    setUploadingImg(true);
    const form = new FormData();
    form.append("file", newProductPhoto);
    form.append("product", props.featuredProduct?._id);
    form.append("company", props.featuredProduct?.company);

    async function uploadMedia() {
      const resp = await MediaRegister(form);

      let product = props.featuredProduct;
      // let company: any = props.featuredProduct.company;

      // let companyId = null;
      // if (typeof company === "string")
      //   companyId = company;
      // else
      //   companyId = company._id;

      product.image = [...productPhotosList, resp];
      // const answer = await UpdateCompanyBanner(companyId, product);
      const answer = await UpdateProductPhotos(props.featuredProduct?._id, product);

      if (answer.success) {
        setProductPhotosList([...productPhotosList, resp])
        setIdProductPhotoSelected(resp._id);
        toast.success(answer.message);
      }

      if (resp) {
        setObjImg(resp);
        setFileKey(fileKey + 1);
      }
    };
    uploadMedia();
  }, [
    newProductPhoto
  ]);

  function handleFile() {
    if (fileRef.current) {
      fileRef.current.click();
    };
  };

  function cleanForm() {
    // setSubCategoryName("");
    // setSubCatgoryStatus(true);
    return;
  };

  function handleProductPhoto(item: IImage) {
    if (!item) return cleanForm();

    setIdProductPhotoSelected(item._id);

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${item.imageUrl}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }

  async function removeProductPhoto(_id: string) {
    setUploadingImg(true);
    const newList = productPhotosList.filter(item => item?._id !== _id) ?? [];

    setProductPhotosList(newList);
    handleProductPhoto(newList?.length ? newList[0] : null);

    const resp = await RemoveMedia(_id);

    toast.success(resp?.message);
  };


  return {
    ...props,
    productPhotosList,
    idProductPhotoSelected,
    uploadingImg,
    imageUrl,
    fileKey,
    fileRef,
    setProductPhotosList,
    setIdProductPhotoSelected,
    removeProductPhoto,
    handleProductPhoto,
    setNewProductPhoto,
    setFileKey,
    handleFile,
  }
}