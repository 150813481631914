import { Icons } from "../../../assets/icons/_index.icons";

import {
  Button,
  Form,
  Input,
  Textarea,
  Toggle
} from "../../../components/_index.components";
import { _ID } from "../../../utils/_id.utils";

import {
  CategoriesFormHooks,
  CategoriesFormProducts,
  CategoriesFormSub,
  CatgoriesFormInfos,
  TCategoriesFormHooksStates,
  TCategoriesPageHooksStates,
} from "../../_index.pages";


export type TCategoriesFormProps = TCategoriesPageHooksStates & {};

export default function CategoriesForm({ ...props }: TCategoriesFormProps) {

  const states: TCategoriesFormHooksStates = CategoriesFormHooks({ ...props } as TCategoriesFormProps);

  return (
    <Form
      className="w-full h-full max-h-full bg-slate-100 pb-2"
      onSubmit={states.handleRecordCategory}
    >
      {
        states.featuredTab === _ID.tabInfos &&
        <CatgoriesFormInfos {...states} />
      }
      {
        states.featuredTab === _ID.tabSubCategory &&
        <CategoriesFormSub {...states} />
      }
      {
        states.featuredTab === _ID.tabProducts &&
        <CategoriesFormProducts {...states} />
      }
    </Form>
  )
};