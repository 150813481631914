import { useEffect, useMemo, useState } from "react";
import { TOrdersPageProps } from "../OrdersPage";
import { HooksModal, THooksModalStates } from "../../../hooks/_index.hooks";
import { IStock } from "../../../interfaces/IStock.i";
import { ChargebackOrder, GetOrdersList, GetProductList } from "../../../services/_index.services";
import { IProduct } from "../../../interfaces/_index.interfaces";
import { toast } from "react-toastify";
import { TAuthState, useAuth } from "../../_index.pages";
import { convertDate, pastDaysCalculateDate } from "../../../utils/_index.utils";

type TOrdersPageHooksProps = TOrdersPageProps & {};
export type TOrdersPageHooksStates = TOrdersPageHooksProps & {
  open: boolean;
  ordersList: IStock[];
  ordersListDisplayed: IStock[];
  reloadList: boolean;
  searchBar: string;
  featuredOrder: IStock;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  productsList: IProduct[];
  chargebackModal: THooksModalStates;
  confirmationCode: string;
  codeProvided: string;
  featuredRoute: string;
  authState: TAuthState;
  initDatePeriod: string;
  endDatePeriod: string;
  setFeaturedRoute: (d: string) => void;
  setCurrentPage: (d: number) => void;
  setFeaturedOrder: (d: IStock) => void;
  setSearchBar: (d: string) => void;
  setCodeProvided: (d: string) => void;
  setReloadList: (d: boolean) => void;
  setInitDatePeriod: (d: string) => void;
  setEndDatePriod: (d: string) => void;
  handleConfirmation: () => void;
  close: () => void;
  toggle: () => void;
};

export const OrdersPageHooks = ({ ...props }: TOrdersPageHooksProps): TOrdersPageHooksStates => {
  const { authState } = useAuth();

  const { close, open, toggle } = HooksModal();
  const [featuredRoute, setFeaturedRoute] = useState<string>("");
  const chargebackModal: THooksModalStates = HooksModal();

  const [ordersList, setOrdersList] = useState<IStock[]>([]);
  const [ordersListDisplayed, setOrdersListDisplayed] = useState<IStock[]>([]);

  const [initDatePeriod, setInitDatePeriod] = useState<string>(convertDate(pastDaysCalculateDate(30)));
  const [endDatePeriod, setEndDatePriod] = useState<string>(convertDate(new Date()));

  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [featuredOrder, setFeaturedOrder] = useState<IStock>();

  const confirmationCode = useMemo(() => (
    Math.floor(Math.random() * (999 - 100) + 100)).toString(), []);

  const [codeProvided, setCodeProvided] = useState<string>("");
  const [releaseChargeback, setReleaseChargeback] = useState<boolean>(false);




  const [reloadList, setReloadList] = useState<boolean>(true);

  const [searchBar, setSearchBar] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItens, setTotalItens] = useState<number>(ordersList?.length);

  const itensPerPage = 14;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    const newList: IStock[] = searchBar
      ? ordersList
        ?.filter((item) => {
          const buscaUpperCase = searchBar.toUpperCase();
          const { user } = item;
          return (
            user?.name?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : ordersList?.slice(indexOfFirstIten, indexOfLastIten);

    setOrdersListDisplayed(newList);
    setTotalItens(searchBar ? 1 : ordersList?.length);
  }, [searchBar, ordersList, indexOfFirstIten, indexOfLastIten]);

  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    function areDatesValid() {
      const start = new Date(initDatePeriod);
      const end = new Date(endDatePeriod);
      return !isNaN(start.getTime()) && !isNaN(end.getTime());
    };

    if (!areDatesValid())
      return;

    async function getOrdersList() {
      const resp = await GetOrdersList({
        company: authState?.user?.company,
        endDate: endDatePeriod,
        initDate: initDatePeriod
      });

      setOrdersList(resp);
      setReloadList(false);
    };

    getOrdersList();
  }, [
    reloadList,
    authState,
    initDatePeriod,
    endDatePeriod
  ]);

  useEffect(() => {
    async function getProductList() {
      let resp = await GetProductList({ company: authState?.user?.company });
      setProductsList(resp);
    };

    getProductList();
  }, []);

  useEffect(() => {
    if (!releaseChargeback) return;

    async function chargebackOrder() {
      const resp = await ChargebackOrder(featuredOrder?._id);
      setReloadList(true);

      if (resp && resp?.success)
        return toast.success(resp.message);

      return toast.error(resp?.message);
    };

    chargebackOrder();
  }, [releaseChargeback]);

  function handleConfirmation() {
    if (codeProvided === confirmationCode) {
      chargebackModal.close();
      setReleaseChargeback(true);
    } else (
      toast.warning("Código fornecido está inválido!")
    );
  }



  return {
    ...props,
    open,
    ordersList,
    ordersListDisplayed,
    reloadList,
    searchBar,
    featuredOrder,
    itensPerPage,
    totalItens,
    currentPage,
    authState,
    productsList,
    chargebackModal,
    confirmationCode,
    codeProvided,
    featuredRoute,
    initDatePeriod,
    endDatePeriod,
    setInitDatePeriod,
    setEndDatePriod,
    setFeaturedRoute,
    handleConfirmation,
    setCodeProvided,
    setCurrentPage,
    setFeaturedOrder,
    setSearchBar,
    setReloadList,
    close,
    toggle,
  }
}