import { Icons } from "../../../assets/icons/_index.icons";

import {
  Button,
  Input,
  Textarea,
  Toggle,
  Image,
  Spinner
} from "../../../components/_index.components";

import {
  CatgoriesFormInfosHooks,
  TCategoriesFormHooksStates
} from "../../_index.pages";

export type TCatgoriesFormInfosProps = TCategoriesFormHooksStates & {};

export default function CatgoriesFormInfos({ ...props }: TCatgoriesFormInfosProps) {

  const states = CatgoriesFormInfosHooks({ ...props } as TCatgoriesFormInfosProps);

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row  w-full h-auto p-2 nd:flex nd:flex-col">
          <div className="flex flex-col w-[70%] p-2 gap-8 nd:w-full">
            <div className="flex flex-col w-full gap-5">
              <div className="w-full inline-flex gap-4 items-end">
                <div className="w-1/2 flex flex-col gap-2 ">
                  <label htmlFor="fantasy_name" className="w-auto whitespace-nowrap">Nome:</label>
                  <Input
                    id={"teste"}
                    name={"fantasy_name"}
                    type={"text"}
                    placeholder="Eletrônicos..."
                    setValue={states.setCategoryName}
                    value={states.categoryName}
                  />
                </div>
                <div className="w-auto inline-flex gap-4 items-center">
                  <span className="w-auto whitespace-nowrap">Status:</span>
                  <Toggle
                    setToggleStatus={states.setCatgoryStatus}
                    toggleStatus={states.categoryStatus}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-5">
              <div className="w-full flex flex-col gap-2 ">
                <label htmlFor="company_name" className="w-auto whitespace-nowrap">Titulo:</label>
                <Input
                  id={"teste"}
                  name={"company_name"}
                  placeholder="Os melhores gadgets tecnológicos de 2024..."
                  type={"text"}
                  setValue={states.setCatgoryTitle}
                  value={states.categoryTitle}
                />
              </div>
              <div className="w-full flex flex-col gap-2 ">
                <label htmlFor="descricao" className="w-auto whitespace-nowrap">Descrição:</label>
                <Textarea
                  id={"teste"}
                  name={"descricao"}
                  placeholder="Os melhores gadgets tecnológicos de 2024..."
                  setValue={states.setCatgoryDescription}
                  value={states.categoryDescription}
                />
              </div>
            </div>
          </div>
          <div className="w-[30%] p-2 h-[40vh] nd:w-full nd:h-[30vh]">
            <div className="flex flex-col justify-center items-center bg-slate-200 shadow-2xl rounded-md w-full h-full">
              <div className="w-[95%] h-[90%] p-2 mt-2 ring-1 ring-header rounded-md">
                {
                  !states.uploadingImg
                    ? states.imageUrl &&
                    <Image
                      key={states.fileKey}
                      path={states.imageUrl}
                    />
                    : <Spinner />
                }
              </div>
              <div
                className="cursor-pointer inline-flex justify-center text-white bg-[#26c59f] rounded-md hover:text-white hover:bg-blue-600
                          m-2 items-center text-center w-[95%]"
                onClick={states.handleFile}
              >
                <Icons.upload
                  className="p-2"
                  size={30}
                />
                <p className="px-2">Incluir</p>
                <input
                  type="file"
                  hidden
                  ref={states.fileRef}
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      states.setCatgoryImage(files[0]);
                      states.setFileKey(states.fileKey + 1)
                    }
                  }}
                // id={ID.company_file}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full justify-end items-end flex p-4 bottom-0">
          <Button
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
            customStyleIcon="text-white"
            text="Salvar"
            icon={Icons.save}
            type="submit"
            _onClick={states.handleRecordCategory}
          />
        </div>
      </div>
    </>
  )
};