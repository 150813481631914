import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from "@mui/material";
import dayjs from "dayjs";


import { Input, Spinner } from "../../../components/_index.components";
import { muiThemeDatePicker } from "../../../layout/@mui";
import { TCompanyFormLayoutProps } from "./CompanyLayoutForm";
import { Icons } from "../../../assets/icons/_index.icons";
import { formatDate } from "../../../utils/_index.utils";

import {
  CompanyEmployeeFormHooks,
  TCompanyFormsHooksStates
} from "../../_index.pages";


export type TCompanyEmployeeFormProps = TCompanyFormsHooksStates & {};


export default function CompanyEmployeeForm({ ...props }: TCompanyEmployeeFormProps) {

  const states = CompanyEmployeeFormHooks({ ...props } as TCompanyFormLayoutProps);

  return (
    <>
      <div className="flex flex-col rounded-md w-full h-full pb-2">
        {
          states.employeesList?.length > 0
            ? !states.loadingFormEmployee
              ? <>
                <div className="inline-flex w-full h-full p-2 gap-2 nd:flex nd:flex-col">
                  <div className="flex flex-col w-[80%] h-full ring-1 ring-slate-200 p-2 rounded-md gap-5 nd:w-full">
                    <div className="flex flex-row w-full gap-4 nd:flex nd:flex-col">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="name" className="w-auto whitespace-nowrap">Nome</label>
                        <Input
                          id={"teste"}
                          name={"name"}
                          type={"text"}
                          setValue={states.setEmployeeName}
                          value={states.employeeName}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="lastname" className="w-auto whitespace-nowrap">Sobrenome</label>
                        <Input
                          id={"teste"}
                          name={"lastname"}
                          type={"text"}
                          setValue={states.setEmployeeLastName}
                          value={states.employeeLastName}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-4">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="document" className="w-auto whitespace-nowrap">CPF</label>
                        <Input
                          id={"teste"}
                          name={"document"}
                          type={"text"}
                          setValue={states.setEmployeeDocument}
                          value={states.employeeDocument}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="document_rg" className="w-auto whitespace-nowrap">RG</label>
                        <Input
                          id={"teste"}
                          name={"document_rg"}
                          type={"text"}
                          setValue={states.setEmployeeDocumentRG}
                          value={states.employeeDocumentRG}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-4 nd:flex nd:flex-col">
                      <div className="w-full flex flex-col gap-2">
                        <label htmlFor="email" className="w-auto whitespace-nowrap">Email</label>
                        <Input
                          id={"teste"}
                          name={"email"}
                          type={"text"}
                          setValue={states.setEmployeeEmail}
                          value={states.employeeEmail}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="phonenumber" className="w-auto whitespace-nowrap">Telefone</label>
                        <Input
                          id={"teste"}
                          name={"phonenumber"}
                          type={"text"}
                          setValue={states.setEmployeePhone}
                          value={states.employeePhone}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-4 nd:flex nd:flex-col">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="brithday" className="w-auto whitespace-nowrap">Nascimento</label>
                        <ThemeProvider theme={muiThemeDatePicker().themeDatePicker}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disableFuture
                              format="DD/MM/YYYY"
                              value={states.employeeBirthday ? dayjs(states.employeeBirthday) : null}
                              onChange={(date: any) => states.setEmployeeBirthday(date)}
                            />
                          </LocalizationProvider>
                        </ThemeProvider>
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="gender" className="w-auto whitespace-nowrap">Gênero</label>
                        <Input
                          id={"teste"}
                          name={"gender"}
                          type={"text"}
                          placeholder="Feminino..."
                          setValue={states.setEmployeeGender}
                          value={states.employeeGender}
                        />
                      </div>
                    </div>
                    <div className="w-full justify-end flex">
                      <div
                        onClick={states.handleRecordEmployee}
                        className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                      >
                        <Icons.register />
                        <span>Gravar Colaborador</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-[20%] h-full ring-1 ring-slate-200 p-2 rounded-md nd:w-full">
                    <div className="flex flex-col gap-2">
                      <div className="inline-flex justify-between w-full items-center">
                        <h3>Colaboradores</h3>
                        <div
                          className="w-auto cursor-pointer"
                          onClick={states.includeNewEmployee}>
                          <Icons.register className="text-[#26c59f]" size={30} />
                        </div>
                      </div>
                      <hr className="h-px w-full" />
                    </div>
                    {
                      states.employeesList.length > 0 && states.employeesList
                        ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                        .map((item, i) => (
                          <div
                            key={i}
                            className={
                              `flex w-full cursor-pointer rounded-md mt-1 
                              ${item?._id === states.idEmployeeSelected ? "bg-[#26c59f] text-white" : "bg-none text-black"}`
                            }
                          >
                            <div className="inline-flex justify-between w-full">
                              <div className="flex text-sm w-full"
                                onClick={() => states.handleEmployees(item?._id)}
                              >
                                <Icons.street
                                  className="m-2"
                                  size={15}
                                />
                                <p className="p-1 text-ellipsis whitespace-nowrap">
                                  {
                                    formatDate(item.createdAt ?? new Date(), { onlyDate: true })
                                  }
                                </p>
                                <p className="p-1 text-ellipsis whitespace-nowrap overflow-hidden">
                                  {
                                    "- " + item?.name
                                  }
                                </p>
                              </div>
                              <div
                                className="flex w-[10%]"
                                onClick={() => states.removeEmployee(item._id)}
                              >
                                <Icons.delete
                                  className="m-2"
                                  size={15}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                    }
                  </div>
                </div>
              </>
              : <Spinner />
            : <div className="w-full h-full flex justify-center items-center">
              <div
                className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                onClick={states.includeNewEmployee}>
                <Icons.register size={30} />
                <span className="text-xl">Incluir</span>
              </div>
            </div>
        }
      </div>
    </>
  )
};