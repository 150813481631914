import HooksModal, { THooksModalStates } from "../../../../hooks/modal.hooks";
import { TCompanyFormLayoutProps } from "../CompanyLayoutForm";



type TCompanyFormLayoutHooksProps = TCompanyFormLayoutProps & {};
export type TCompanyFormLayoutHooksStates = TCompanyFormLayoutHooksProps & {
  overlappingModalPrimaryDark: THooksModalStates;
  overlappingModalSecondaryDark: THooksModalStates;
  overlappingModalThirdDark: THooksModalStates;
  overlappingModalPrimaryLight: THooksModalStates;
  overlappingModalSecondaryLight: THooksModalStates;
  overlappingModalThirdLight: THooksModalStates;
}

export const CompanyFormLayoutHooks = ({ ...props }: TCompanyFormLayoutHooksProps): TCompanyFormLayoutHooksStates => {

  const overlappingModalPrimaryDark = HooksModal();
  const overlappingModalSecondaryDark = HooksModal();
  const overlappingModalThirdDark = HooksModal();
  const overlappingModalPrimaryLight = HooksModal();
  const overlappingModalSecondaryLight = HooksModal();
  const overlappingModalThirdLight = HooksModal();

  return {
    ...props,
    overlappingModalPrimaryDark,
    overlappingModalSecondaryDark,
    overlappingModalThirdDark,
    overlappingModalPrimaryLight,
    overlappingModalSecondaryLight,
    overlappingModalThirdLight,
  }
}