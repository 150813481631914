import { useEffect, useRef, useState } from "react";
import { TInputsPagesProps } from "../InputsPages";
import { GetInputsList } from "../../../services/inputs.services";
import { TAuthState, useAuth } from "../../_index.pages";
import { IInput } from "../../../interfaces/_index.interfaces";


type TInputsPagesHooksProps = TInputsPagesProps & {};
export type TInputsPagesHooksStates = TInputsPagesHooksProps & {
  focusedLine: any;
  showMore: boolean;
  showMoreItem: any;
  ref: any;
  inputsList: IInput[];
  inputsListDisplayed: IInput[];
  searchBar: string;
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  featuredRoute: string;
  featuredInput: IInput;
  authState: TAuthState;
  reloadList: boolean;
  setReloadList: (d: boolean) => void;
  setFeaturedInput: (d: IInput) => void;
  setFeaturedRoute: (d: string) => void;
  setShowMoreItem: (d: any) => void;
  setShowMore: (d: boolean) => void;
  setFocusedLine: (d: any) => void;
  setCurrentPage: (d: number) => void;
  setSearchBar: (d: string) => void;
};

export const InputsPagesHooks = ({ ...props }: TInputsPagesHooksProps): TInputsPagesHooksStates => {
  const { authState } = useAuth();

  const ref = useRef<HTMLDivElement>(null);

  const [featuredRoute, setFeaturedRoute] = useState<string>("");

  const [inputsList, setInputsList] = useState<IInput[]>([]);

  const [inputsListDisplayed, setInputsListDisplayed] = useState<IInput[]>([]);

  const [focusedLine, setFocusedLine] = useState<any>();
  const [featuredInput, setFeaturedInput] = useState<IInput>();

  const [showMore, setShowMore] = useState<boolean>(false);
  const [showMoreItem, setShowMoreItem] = useState<any>();
  const [reloadList, setReloadList] = useState<boolean>(true);

  const [searchBar, setSearchBar] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const itensPerPage = 18;
  const totalItens = inputsList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    let inputsListOrdered = inputsList
      // ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())

    const newList: any[] = searchBar
      ? inputsListOrdered
        ?.filter((item) => {
          const buscaUpperCase = searchBar.toUpperCase();
          const { name } = item;
          return (
            name?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : inputsListOrdered
        ?.slice(indexOfFirstIten, indexOfLastIten);

    setInputsListDisplayed(newList);
  }, [searchBar, inputsList, indexOfFirstIten, indexOfLastIten]);

  useEffect(() => {
    function manusearClickNaTela(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node) && showMore) {
        setShowMore(false);
      }
    }

    document.addEventListener("mousedown", manusearClickNaTela);

    return () => {
      document.removeEventListener("mousedown", manusearClickNaTela);
    };
  }, [showMore]);

  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    async function getInputList() {
      if (!authState?.user?.company)
        return;

      const resp = await GetInputsList({ company: authState.user.company });
      setInputsList(resp);
      setReloadList(false);
    };

    getInputList();
  }, [
    authState,
    reloadList
  ]);

  return {
    ...props,
    focusedLine,
    showMore,
    showMoreItem,
    ref,
    searchBar,
    itensPerPage,
    totalItens,
    currentPage,
    inputsList,
    inputsListDisplayed,
    featuredRoute,
    featuredInput,
    authState,
    reloadList,
    setReloadList,
    setFeaturedInput,
    setFeaturedRoute,
    setCurrentPage,
    setSearchBar,
    setShowMoreItem,
    setFocusedLine,
    setShowMore
  };
};