import StructurePages from "../StructurePages";

import { Icons } from "../../assets/icons/_index.icons";
import { _ID, formatDate } from "../../utils/_index.utils";

import {
  Button,
  Input,
  Modal,
  Spinner
} from "../../components/_index.components";

import {
  CompanyForms,
  CompanyPagesHooks,
  TCompanyPagesHooksStates,
  useAuth
} from "../_index.pages";
import { RoutesPath } from "../../routes/AppRoutes.routes";

export type TCompanyPagesProps = {};

const tabList = [
  {
    nome: "Geral",
    _id: _ID.tabInfos
  },
  {
    nome: "End.",
    _id: _ID.tabAddress
  },
  {
    nome: "Func.",
    _id: _ID.tabEmployees
  },
  {
    nome: "Layout",
    _id: _ID.tabLayout
  },
  {
    nome: "Banner",
    _id: _ID.tabBanner
  },
]

export default function CompanyPages({ ...props }: TCompanyPagesProps) {

  const { authState } = useAuth();


  const states: TCompanyPagesHooksStates = CompanyPagesHooks({ ...props } as TCompanyPagesProps);

  return (
    <StructurePages
      module={
        <>
          <div className="w-full h-auto min-h-screen flex flex-col gap-5 relative overflow-hidden nd:p-0">
            <section className="w-full h-auto inline-flex gap-8 justify-center items-center p-5 nd:p-2">
              <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                <Input
                  id={"teste"}
                  name={"search"}
                  placeholder="Pesquisar"
                  type={"text"}
                  setValue={states.setSearchBar}
                  value={states.searchBar}
                  style="pl-10"
                />
              </div>
              <Button
                icon={Icons.search}
                text="Filtros"
                _onClick={() => {
                  states.setFeaturedCompany && states.setFeaturedCompany(null);

                  // return states.toggle && states.toggle()
                }}
                customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                customStyleIcon="text-[#26c59f]"
              />
            </section>
            <div className="w-full h-screen flex flex-col gap-3 p-5 nd:p-2">
              <div className="w-full inline-flex justify-between items-center">
                <h1 className="text-2xl">
                  <strong>
                    Empresas
                  </strong>
                </h1>
                {
                  !authState.user?.company &&
                  <Button
                    icon={Icons.register}
                    text="Cadastrar"
                    _onClick={() => {
                      states.setFeaturedCompany && states.setFeaturedCompany(null);
                      states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.companyNew);
                    }}
                    customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
                    customStyleIcon="text-white"
                  />
                }
              </div>
              <div className="w-full h-full gap-2 flex flex-col">
                {
                  (states.companyList?.length > 0)
                    ? states.companyList?.map((item, i) => (
                      <div
                        key={i}
                        className="w-full h-auto min-h-[10vh] rounded-md justify-between gap-5 inline-flex p-2 hover:text-[#26c59f] cursor-pointer bg-slate-200"
                        style={{
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          backdropFilter: "blur(5.5px)",
                        }}
                        onClick={() => {
                          states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos)
                          states.setFeaturedCompany && states.setFeaturedCompany(item);
                          states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.companyNew);
                        }}
                      >
                        <div className="w-auto h-full flex flex-col max-w-full overflow-hidden">
                          <div className="inline-flex gap-4">
                            <span>Empresa:</span>
                            <span className="overflow-hidden text-ellipsis">{item.fantasyName}</span>
                          </div>
                          <div className="inline-flex gap-4">
                            <span>CNPJ:</span>
                            <span>{item.document}</span>
                          </div>
                          <div className="inline-flex gap-4 ">
                            <span>Email:</span>
                            <span className="overflow-hidden text-ellipsis">{item.email}</span>
                          </div>
                        </div>
                        <div className="w-auto h-full flex flex-col">
                          <div className="inline-flex gap-4">
                            <span>Registrado:</span>
                            <span>{formatDate(item.created_at, { onlyDate: true })}</span>
                          </div>
                          <div className="inline-flex gap-4">
                            <span>Atualizado:</span>
                            <span>{formatDate(item.updated_at, { onlyDate: true })}</span>
                          </div>
                          <div className="inline-flex gap-4">
                            <span>Status:</span>
                            <span>{item.status}</span>
                          </div>
                        </div>
                      </div>

                    ))
                    : <Spinner />
                }

              </div>
            </div>
            {
              states.featuredRoute === RoutesPath.companyNew &&
              <div className="w-full h-full max-w-full flex flex-col absolute p-2 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                <h1 className="text-bold text-3xl">{states.featuredCompany?._id ? states.featuredCompany?.companyName : "Nova Empresa"}</h1>
                <nav className="inline-flex justify-between w-full">
                  <ul className="inline-flex text-gray-400 gap-3">
                    <li>
                      <button
                        onClick={() => {
                          states.setFeaturedCompany(null);
                          states.setFeaturedRoute(RoutesPath.company);
                        }}
                      >
                        <span>Empresas</span>
                      </button>
                    </li>
                    <li>&gt;</li>
                    <li className="text-black">{states.featuredCompany?._id ? states.featuredCompany?.companyName : "Nova"}</li>
                  </ul>
                  <Button
                    text="Voltar"
                    _onClick={() => {
                      states.setFeaturedCompany(null);
                      states.setFeaturedRoute(RoutesPath.company);
                    }}
                    icon={Icons.back}
                  />
                </nav>
                <div className="mt-1 z-20 absolute nd:right-2 w-full pr-5 nd:mt-24 nd:pr-0">
                  <div className="inline-flex w-full justify-end pr-20 nd:pr-0">
                    {
                      tabList.map((item, i) => {
                        return (
                          <div
                            onClick={() => states.setFeaturedTab(item._id)}
                            className={
                              `cursor-pointer hover:text-[#67a0cf] ring-slate-200 ring-1 hover:ring-[#67a0cf] p-2 rounded-t-md
                            ${item._id === states.featuredTab ? "text-[#26c59f]" : "text-primary"}`
                            }
                            key={i}>
                            <p>
                              {
                                item.nome
                              }
                            </p>
                          </div>
                        )
                      })
                    }
                  </div>
                  <hr className="h-px" />
                </div>
                <CompanyForms
                  {...states}
                />
              </div>
            }
          </div>
        </>
      }
    />
  )
}