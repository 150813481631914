export function pastDaysCalculateDate(qtdeDias: number) {
  let hoje = new Date();
  let novaData = new Date();
  let dataFormatada = new Date(novaData.setDate(hoje.getDate() - qtdeDias));

  return dataFormatada;
};


export function formatDate(date: any, configs?: any) {
  if (!date) {
    return undefined;
  };

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZone: 'America/Sao_Paulo'
  };

  if (configs) {
    if (configs.onlyDate) {
      delete options.hour
      delete options.minute
      delete options.second
    } else if (configs.onlyTime) {
      delete options.year
      delete options.month
      delete options.day
    } else if (configs.onlyHourAndMinutes) {
      delete options.year
      delete options.month
      delete options.day
      delete options.second
    };
  };

  return new Intl.DateTimeFormat('pt-BR', options)
    .format(new Date(date))
    .toString()
};

export function convertDate(date: Date) {
  if (!date)
    return null

  return date
    ?.toISOString()
    ?.split('T')[0]
};