import { TUsersFormLgpdProps } from "../UsersFormLgpd";

type TUsersFormLgpdHooksProps = TUsersFormLgpdProps & {};
export type TUsersFormLgpdHooksStates = TUsersFormLgpdHooksProps & {

};

export const UsersFormLgpdHooks = ({ ...props }: TUsersFormLgpdHooksProps): TUsersFormLgpdHooksStates => {


  return {
    ...props,
  };
};