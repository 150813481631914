import { Navigate } from "react-router-dom";
import { Icons } from "../../../assets/icons/_index.icons";
// import { ID } from "../../../utils/_index.utils";
import { HeaderPerfilHooks } from "./Hooks/Header_PerfilHooks";
import { RoutesPath } from "../../../routes/AppRoutes.routes";
import { useAuth } from "../../../pages/_index.pages";

export default function HeaderProfile() {
  const { authState, logout } = useAuth();

  const states = HeaderPerfilHooks({});

  return (
    <>
      {!authState.isAuthenticated && <Navigate to={RoutesPath.login} replace={true} />}
      <div
        className="flex justify-center items-center cursor-pointer w-9 h-9 nd:w-[50%] rounded-full flex-col overflow-hidden bg-[#26c59f]"
        ref={states.ref}
        onClick={() => {
          states.setMostrarDadosUsuario(!states.mostrarDadoUsuario);
        }}
      >
        <div className="flex flex-col items-center text-center overflow-hidden text-ellipsis" >
          <button
            type="button"
            className="flex p-1 w-auto rounded-full text-sm items-center text-center"
            // id={ID.header_perfil}
            aria-expanded="false"
            aria-haspopup="true"
          >
            <Icons.profile
              size={20}
              className="text-white"
            />
          </button>
        </div>
        {
          states.mostrarDadoUsuario ? (
            <div
              className={
                `absolute z-50 mt-52 w-48 h-40 -ml-40 rounded-md shadow-lg 
                ring-1 ring-primary ring-opacity-5 focus:outline-none backdrop-blur-3xl 
                text-black bg-slate-200
                `
              }
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                zIndex: 1000
              }}
              // id={ID.header_perfil}
              role="menu"
              aria-orientation="vertical"
            >
              <div className="p-4">
                <h3
                  className="block px-4 pt-2 text-md whitespace-nowrap text-ellipsis overflow-hidden text-primary"
                  role="menuitem"
                  tabIndex={1}
                // id={ID.header_perfil_nome}
                >
                  <strong>
                    {
                      states.nomeUsuario
                    }
                  </strong>
                </h3>
                <p
                  className="block px-4 text-sm whitespace-nowrap text-ellipsis overflow-hidden text-primary"
                  role="menuitem"
                  tabIndex={1}
                // id={ID.header_perfil_email}
                >
                  {
                    states.emailUsuario
                  }
                </p>
              </div>
              <div className="flex h-auto min-h-20 w-full bg-[#c5fff2] items-end flex-col px-5">
                <div
                  className="inline-flex justify-start items-center text-center w-full hover:font-bold cursor-pointer "
                  onClick={() => null}
                >
                  <Icons.permissions className="text-[#26c59f]" />
                  <a
                    href="#"
                    className="block text-center px-4 py-2 text-sm md:font-bold text-[#26c59f]"
                    role="menuitem"
                    tabIndex={1}
                  // id={ID.header_perfil_sair_btn}
                  >
                    Permissões
                  </a>
                </div>
                <div
                  className="inline-flex justify-start items-center text-center w-full hover:font-bold cursor-pointer "
                  onClick={() => null}
                >
                  <Icons.configs className="text-[#26c59f]" />
                  <a
                    href="#"
                    className="block text-center px-4 py-2 text-sm md:font-bold text-[#26c59f]"
                    role="menuitem"
                    tabIndex={1}
                  // id={ID.header_perfil_sair_btn}
                  >
                    Configurações
                  </a>
                </div>
                <div
                  className="inline-flex justify-center items-center text-center w-full hover:font-bold cursor-pointer "
                  onClick={() => logout()}
                >
                  <a
                    href="#"
                    className="block text-center px-4 py-2 text-sm md:font-bold text-[#26c59f]"
                    role="menuitem"
                    tabIndex={1}
                  // id={ID.header_perfil_sair_btn}
                  >
                    Sair
                  </a>
                  <Icons.logout className="text-[#26c59f]" />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        }
      </div>
    </>
  );
};
