import { useEffect, useState } from "react"

import { GetCompanyList } from "../../../services/_index.services";
import { ICompany } from "../../../interfaces/_index.interfaces";
import { TCompanyPagesProps } from "../CompanyPages";
import { HooksModal } from "../../../hooks/_index.hooks";
import { _ID } from "../../../utils/_id.utils";
import { useAuth } from "../../_index.pages";

type TCompanyPagesHooksProps = TCompanyPagesProps & {}
export type TCompanyPagesHooksStates = TCompanyPagesHooksProps & {
  companyList: ICompany[];
  searchBar: string;
  featuredCompany: ICompany | null
  open: boolean;
  featuredTab: string;
  featuredRoute: string;
  setFeaturedRoute: (d: string) => void;
  setFeaturedTab: (data: string) => void;
  close: () => void;
  toggle: () => void;
  setFeaturedCompany: (data: ICompany | null) => void;
  setSearchBar: (data: string) => void;
}

export const CompanyPagesHooks = ({ ...props }: TCompanyPagesHooksProps): TCompanyPagesHooksStates => {
  const { authState } = useAuth();
  const [featuredRoute, setFeaturedRoute] = useState<string>("");
  const { close, toggle, open } = HooksModal();

  const [companyList, setCompanyList] = useState<ICompany[]>([]);

  const [searchBar, setSearchBar] = useState<string>("");

  const [featuredCompany, setFeaturedCompany] = useState<ICompany | null>(null);
  const [featuredTab, setFeaturedTab] = useState<string>(_ID.tabInfos);


  useEffect(() => {
    if (companyList.length || !authState?.user)
      return;

    async function getCompanyList() {
      let resp = await GetCompanyList({ company: authState.user?.company });
      setCompanyList(resp);
    };

    getCompanyList();
  }, [authState]);

  return {
    ...props,
    companyList,
    searchBar,
    featuredCompany,
    open,
    featuredTab,
    featuredRoute,
    setFeaturedRoute,
    setFeaturedTab,
    close,
    toggle,
    setFeaturedCompany,
    setSearchBar,
  }
}