import { useEffect, useState } from "react";
import { TOrdersDetailsProps } from "../OrdersDetails";
import { IUser } from "../../../../interfaces/IUser.i";
import { CreateOrder, GetUserList } from "../../../../services/_index.services";
import { toast } from "react-toastify";
import { IStockSimplified } from "../../../../interfaces/IStock.i";
import { STOCK_METHODS, STOCK_TRANSATION } from "../../../../utils/_index.utils";

type TOrdersDetailsHooksProps = TOrdersDetailsProps & {};
export type TOrdersDetailsHooksStates = TOrdersDetailsHooksProps & {
  usersList: IUser[];
  productOrder: string;
  userOrder: string;
  amountProductOrder: string;
  setUserOrder: (d: string) => void;
  setProductOrder: (d: string) => void;
  setAmountProductOrder: (d: string) => void;
  registerOrder: () => void;
};

export const OrdersDetailsHooks = ({ ...props }: TOrdersDetailsHooksProps): TOrdersDetailsHooksStates => {

  const [usersList, setUsersList] = useState<IUser[]>([]);

  const [productOrder, setProductOrder] = useState<string>("");
  const [userOrder, setUserOrder] = useState<string>("");
  const [amountProductOrder, setAmountProductOrder] = useState<string>("");

  useEffect(() => {

    async function getUsersList() {
      const resp = await GetUserList({ company: props.authState?.user?.company });
      setUsersList(resp);
    };

    getUsersList();
  }, []);

  async function registerOrder() {
    if (!productOrder || !userOrder || !amountProductOrder)
      return toast.warning("Por favor preencha todos os campos para registrar o pedido!");

    let obj = {
      amount: parseInt(amountProductOrder),
      company: props.authState?.user?.company,
      method: STOCK_METHODS.AUTHOMETIC,
      product: productOrder,
      transation: STOCK_TRANSATION.WITHDRAW,
      reason: new Date().toString(),
      user: userOrder
    } as IStockSimplified;

    const resp = await CreateOrder(obj);

    if (resp && resp?.success) {
      toast.success(resp?.message);
      props.setReloadList && props.setReloadList(true);
      props.close && props.close();

    } else {
      toast.error(resp?.message)
    }
  };

  return {
    ...props,
    usersList,
    productOrder,
    userOrder,
    amountProductOrder,
    setUserOrder,
    setProductOrder,
    setAmountProductOrder,
    registerOrder
  }
}