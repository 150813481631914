import { toast } from "react-toastify";
import { IPayment } from "../../../../interfaces/_index.interfaces";
import { CreatePayment } from "../../../../services/_index.services";
import { TSubscribersDetailsInfosProps } from "../SubscribersDetailsInfos";
import { PAYMENT_METHOD } from "../../../../utils/_index.utils";

type TSubscribersDetailsInfosHooksProps = TSubscribersDetailsInfosProps & {};
export type TSubscribersDetailsInfosHooksStates = TSubscribersDetailsInfosHooksProps & {
  registerPayment: (d: any) => void;
};

export const SubscribersDetailsInfosHooks = ({ ...props }: TSubscribersDetailsInfosHooksProps): TSubscribersDetailsInfosHooksStates => {

  async function registerPayment(e: any) {
    e.preventDefault();

    if (!props.addPayment)
      return toast.warning("Adicione o valor do pagamento!");

    let obj = {
      card: null,
      company: props.featuredSubscribe.user?.company,
      method: PAYMENT_METHOD.PIX,
      user: props.featuredSubscribe.user?._id,
      value: props.addPayment ? parseFloat(props.addPayment) : 0,
    } as IPayment;

    const resp = await CreatePayment(obj);
    if (resp && resp?.success) {
      props.setWaitingInfos && props.setWaitingInfos(true);
      props.setReloadList && props.setReloadList(true);
      toast.success(resp?.message);
    } else {
      toast.error(resp?.message);
    }
  };

  return {
    ...props,
    registerPayment,
  };
};