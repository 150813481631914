import { InputHooks } from "../_index.components";


export type TInputProps = {
  type: string;
  id: string;
  name: string;
  inValue?: boolean;
  readonly?: boolean;
  placeholder?: string;
  value: string | number;
  setValue: (data: string | number) => void;
  setInValue?: (data: boolean) => void;
  style?: string;
  currency?: boolean;
}

export default function Input(props: TInputProps) {

  const states = InputHooks({ ...props } as TInputProps);

  return (
    <>
      <div className="w-full relative">
        <input
          type={states.type}
          id={states.id}
          name={states.name}
          placeholder={states.placeholder}
          className={`
            w-full h-auto min-h-[5vh] rounded-md px-2 
            bg-slate-200 ${props.inValue === true ? "ring-2 ring-[#a53e3e]" : "ring-0"}
            ${props.readonly === true ? "pointer-events-none" : ""}
            ${props.style ?? props.style}
            ${states.currency ? "pl-8" : "pl-none"}`
          }
          onChange={(e) => {
            states.modelDataStructure(e.target.value)
            states.setInValue && states.setInValue(false);
          }}
          // value={!states.currency ? states.value : parseFloat(states.value).toFixed(2)}
          value={states.value}
        // step={states.currency ? "0.01" : null}
        />
        {
          states.currency &&
          <span className="absolute left-2 top-3">R$</span>
        }
      </div>

    </>
  )
};