import { Icons } from "../../../assets/icons/_index.icons";
import { RoutesPath } from "../../../routes/AppRoutes.routes";

import {
  Button,
  Input,
  Selection,
} from "../../../components/_index.components";

import {
  InputsNewHooks,
  TInputsNewHooksStates,
  TInputsPagesHooksStates
} from "../../_index.pages";
import { INPUT_CATEGORIES_LIST } from "../../../utils/inputs.utils";

export type TInputsNewProps = TInputsPagesHooksStates & {};

export default function InputsNew({ ...props }: TInputsNewProps) {

  const states: TInputsNewHooksStates = InputsNewHooks({ ...props } as TInputsNewProps);

  return (
    <>
      <div className="w-full h-full flex flex-col absolute pr-20 z-10 bg-slate-100 overflow-x-hidden gap-4 pl-2 nd:pr-3">
        <h1 className="text-bold text-3xl">
          {
            states.featuredInput?._id
              ? states.featuredInput?.name
              : "Novo insumo"
          }
        </h1>
        <nav className="inline-flex justify-between w-full">
          <ul className="inline-flex text-gray-400 gap-3">
            <li>
              <button
                onClick={() => {
                  states.setFeaturedInput(null);
                  states.setFeaturedRoute(RoutesPath.inputs);
                }}
              >
                <span>Insumos</span>
              </button>
            </li>
            <li>&gt;</li>
            <li className="text-black">
              {
                states.featuredInput?._id
                  ? states.featuredInput?.name
                  : "Novo"
              }
            </li>
          </ul>
          <Button
            text="Voltar"
            _onClick={() => {
              states.setFeaturedInput(null);
              states.setFeaturedRoute(RoutesPath.inputs);
            }}
            icon={Icons.back}
          />
        </nav>
        <form className="w-full flex flex-col gap-8">
          <div className="inline-flex gap-5 w-full nd:flex nd:flex-col">
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="ingredient" className="w-auto whitespace-nowrap">Insumo</label>
              <Input
                id={"ingredient"}
                name={"ingredient"}
                type={"text"}
                setValue={states.setFormIngredient}
                value={states.formIngredient}
                setInValue={states.setInFormIngredient}
                inValue={states.inFormIngredient}
              />
            </div>
            <div className="w-full flex flex-col gap-2">
              <label htmlFor="purchaseDate" className="w-auto whitespace-nowrap">Data de Compra</label>
              <Input
                id={"purchaseDate"}
                name={"purchaseDate"}
                type={"date"}
                setValue={states.setFormPurschaseDate}
                value={states.formPurchaseDate}
                setInValue={states.setInFormPurschaseDate}
                inValue={states.inFormPurchaseDate}
              />
            </div>
          </div>
          <div className="inline-flex gap-5 w-full nd:flex nd:flex-col">
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="amount" className="w-auto whitespace-nowrap">Quantidade</label>
              <Input
                id={"amount"}
                name={"amount"}
                type={"number"}
                setValue={states.setFormAmount}
                value={states.formAmount}
                setInValue={states.setInFormAmount}
                inValue={states.inFormAmount}
              />
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="unitValue" className="w-auto whitespace-nowrap">Valor Un.</label>
              <Input
                id={"unitValue"}
                name={"unitValue"}
                type={"number"}
                currency={true}
                setValue={states.setFormUnitValue}
                value={states.formUnitValue}
                setInValue={states.setInFormUnitValue}
                inValue={states.inFormUnitValue}
              />
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="totalValue" className="w-auto whitespace-nowrap">Valor Total</label>
              <Input
                id={"totalValue"}
                name={"totalValue"}
                type={"number"}
                currency={true}
                setValue={() => null}
                value={(states.formAmount * states.formUnitValue).toFixed(2)}
                readonly={true}
                style="font-bold"
              />
            </div>
          </div>
          <div className="w-full flex felx-col gap-2">
            <div className="w-1/4 flex flex-col gap-2 nd:w-full">
              <label htmlFor="client" className="w-auto whitespace-nowrap">Categoria</label>
              <Selection
                optionsList={INPUT_CATEGORIES_LIST}
                setValue={states.setFormCategory}
                value={states.formCategory}
                setInValue={states.setInFormCategory}
                inValue={states.inFormCategory}
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button
              customStyle="h-[4vh] w-[8vw] inline-flex rounded-lg gap-4 justify-center items-center p-1 px-5 bg-[#26c59f] text-white nd:w-auto"
              customStyleIcon="text-white"
              text="Salvar"
              icon={Icons.save}
              type="submit"
              _onClick={states.handleRecordInput}
            />
          </div>
        </form>
      </div>
    </>
  )
};