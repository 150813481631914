// export function formatCurrency(value: number) {
//   if (!value) value = 0;

//   return new Intl.NumberFormat(
//     'pt-BR',
//     { style: 'currency', currency: 'BRL' }
//   ).format(value);
// };


export function formatCurrency(value: string | number) {
  // Converte para número, se necessário
  let numberValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!numberValue)
    numberValue = 0

  // Retorna o valor formatado
  return new Intl.NumberFormat(
    'pt-BR',
    { style: 'currency', currency: 'BRL' }
  ).format(numberValue);
}
