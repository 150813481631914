import { formatCurrency } from "../../../utils/_index.utils";
import { formatDate } from "../../../utils/date.utils";

import {
  SubscribersReceiptHooks,
  TSubscribersDetailsHooksStates,
  TSubscribersReceiptHooksStates
} from "./_index.partials";

import { Image, Input, Spinner } from "../../../components/_index.components";
import { Icons } from "../../../assets/icons/_index.icons";

export type TSubscribersReceiptProps = TSubscribersDetailsHooksStates & {};

export default function SubscribersReceipt({ ...props }: TSubscribersReceiptProps) {

  const states: TSubscribersReceiptHooksStates = SubscribersReceiptHooks({ ...props } as TSubscribersReceiptProps);

  return (
    <>
      {
        states.releaseReceipt &&
        <button
          className="absolute right-0 p-5 mt-52 nd:mt-48"
          onClick={states.generateReceiptPDF}
        >
          <Icons.share size={20} />
        </button>
      }
      <div className="inline-flex gap-5 mt-10 w-full">
        <div className="w-full flex flex-col gap-2">
          <label htmlFor="initDate" className="w-auto whitespace-nowrap">Data Inicial:</label>
          <Input
            id={"initDate"}
            name={"initDate"}
            type={"date"}
            setValue={states.setInitDatePeriod}
            value={states.initDatePeriod}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <label htmlFor="finalDtae" className="w-auto whitespace-nowrap">Data Final:</label>
          <Input
            id={"finalDtae"}
            name={"finalDtae"}
            type={"date"}
            setValue={states.setEndDatePriod}
            value={states.endDatePeriod}
          />
        </div>
      </div>
      {
        states.releaseReceipt
          ? <div
            ref={states.receiptRef}
            className="w-auto bg-orange-50 p-5 flex flex-col gap-3 rounded-md nd:text-xs nd:p-1 mt-10"
          >
            <div className="flex w-full text-center justify-center">
              <h1 className="text-center text-3xl nd:text-lg">Recibo {states.company?.fantasyName}</h1>
            </div>
            <div className="w-full inline-flex justify-between">
              <div className="flex flex-col w-full">
                <p className="text-xl mt-10 nd:text-sm nd:mt-5">
                  Data: <strong>
                    {formatDate(new Date)}
                  </strong>
                </p>
                <p className="text-xl nd:text-sm">
                  Nome: <strong>
                    {states.featuredSubscribe?.user?.name + ' ' + states.featuredSubscribe?.user?.lastname}
                  </strong>
                </p>
              </div>
              <div className="max-w-[10vw] max-h-[30vh] nd:max-w-[25vw]">
                {
                  states.company?.image &&
                  <Image
                    path={process.env.REACT_APP_BUCKET_URL + states.company?.image?.imageThumbUrl}
                  />
                }
              </div>
            </div>
            <div className="w-full h-auto flex flex-col justify-center gap-3 mt-10 nd:mt-0">
              <h2 className="text-center text-lg nd:text-md">Produtos</h2>
              <ul className="w-full flex flex-col">
                <li className="bg-orange-100 grid grid-cols-5">
                  <p className="px-4 py-2 text-start">Produto</p>
                  <p className="px-4 py-2 text-center">Data Compra</p>
                  <p className="px-4 py-2 text-center">Qtde.</p>
                  <p className="px-4 py-2 text-center">Valor Unitário</p>
                  <p className="px-4 py-2 text-center">Valor Total</p>
                </li>
                {
                  states.subscriberOrders
                    ?.sort((b, a) => new Date(b.purshaseDate).getTime() - new Date(a.purshaseDate).getTime())
                    ?.map((p, j) => (
                      <li
                        className={`${j % 2 === 0 ? 'bg-orange-50' : 'bg-orange-100'} grid grid-cols-5`}
                        key={j}
                      >
                        <p className="px-4 py-2 text-start">{p.name}</p>
                        <p className="px-4 py-2 text-center">{formatDate(p.purshaseDate, { onlyDate: true })}</p>
                        <p className="px-4 py-2 text-center">{p.quantity}</p>
                        <p className="px-4 py-2 text-center">{formatCurrency(p.value)}</p>
                        <p className="px-4 py-2 text-center">{formatCurrency(p.totalValue)}</p>
                      </li>
                    ))

                }
              </ul>
            </div>
            <div className="w-full h-auto grid grid-cols-5 justify-end gap-3 mt-10 nd:grid-cols-3">
              <hr className="nd:hidden border-0" />
              <hr className="nd:hidden border-0" />
              <hr className="border-0" />
              <h2 className="px-4 py-2 text-center text-lg nd:text-xs nd:whitespace-nowrap">Valor total consumido:</h2>
              <p className="px-4 py-2 text-lg nd:text-xs text-center"><strong>{formatCurrency(states.totalConsumed)}</strong></p>
            </div>
            <div className="w-full h-auto flex flex-col justify-center gap-3 mt-10">
              <h2 className="text-center text-lg nd:text-md">Pagamentos</h2>
              <ul className="w-full flex flex-col">
                <li className="bg-orange-100 grid grid-cols-5">
                  <p className="px-4 py-2 text-center">-</p>
                  <p className="px-4 py-2 text-center">-</p>
                  <p className="px-4 py-2 text-center">-</p>
                  <p className="px-4 py-2 text-center">Data Pagamento</p>
                  <p className="px-4 py-2 text-center">Valor</p>
                </li>
                {
                  states.subscriberPayments
                    ?.sort((b, a) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                    ?.map((p, j) => (
                      <li
                        className={`${j % 2 === 0 ? 'bg-orange-50' : 'bg-orange-100'} grid grid-cols-5`}
                        key={j}
                      >
                        <p className="px-4 py-2 text-center">-</p>
                        <p className="px-4 py-2 text-center">-</p>
                        <p className="px-4 py-2 text-center">-</p>
                        <p className="px-4 py-2 text-center">{formatDate(p.created_at, { onlyDate: true })}</p>
                        <p className="px-4 py-2 text-center">{formatCurrency(p.value)}</p>
                      </li>
                    ))
                }
              </ul>
            </div>
            <div className="w-full h-auto grid grid-cols-5 justify-end gap-3 mt-10 nd:grid-cols-3">
              <hr className="nd:hidden border-0" />
              <hr className="nd:hidden border-0" />
              <hr className="border-0" />
              <h2 className="px-4 py-2 text-center text-lg nd:text-xs nd:whitespace-nowrap">Valor total pago:</h2>
              <p className="px-4 py-2 text-lg nd:text-xs text-center"><strong>{formatCurrency(states.totalAmountPaid)}</strong></p>
            </div>
            <hr className="h-px w-full bg-orange-100" />
            <div className="w-full h-auto grid grid-cols-5 justify-end gap-3 mt-10 nd:grid-cols-3 items-center">
              <hr className="nd:hidden border-0" />
              <hr className="nd:hidden border-0" />
              <hr className="border-0" />
              <h2 className="px-4 py-2 text-center text-lg nd:text-xs nd:whitespace-nowrap">Valor total devido:</h2>
              <p className="px-4 py-2 text-center text-xl"><strong>{formatCurrency(states.totalAmountDue)}</strong></p>
            </div>
          </div>
          : <Spinner />
      }
    </>
  )
};