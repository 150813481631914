import { toast } from "react-toastify";

import { api } from "./_axios.services"

export async function GetRevenueOfTheYearByMonth(company: string) {
  try {
    const response = await api.get("dashboard/revenueByMonth", { params: { company: company } })
    const data = response.data.data.dashboard[0]

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetRevenueReceivedOfTheYearByMonth(company: string) {
  try {
    const response = await api.get("dashboard/revenueRecivedPerMonth", { params: { company: company } })
    const data = response.data.data.dashboard[0]

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TGetConsumePerUserProps = {
  company: string;
};

export async function GetConsumePerUser(item: TGetConsumePerUserProps) {
  try {
    const response = await api.get("dashboard/revenuePerUser", { params: item })
    const data = response.data.data.dashboard

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TGetConsumePerPeriod = {
  company: string;
  initDate: string;
  endDate: string;
};

export async function GetConsumePerPeriod(item: TGetConsumePerPeriod) {
  try {
    const response = await api.get("dashboard/revenuePerPeriod", { params: item })
    const data = response.data.data.dashboard[0]

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetProductsPerPeriod(item: TGetConsumePerPeriod) {
  try {
    const response = await api.get("dashboard/revenueProductsPerPeriod", { params: item })
    const data = response.data.data.dashboard

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TGetInputsPerPeriodProps = {
  company: string;
  initDate: string;
  endDate: string;
};

export async function GetInputsPerPeriod(item: TGetInputsPerPeriodProps) {
  try {
    const response = await api.get("dashboard/categoriesInputsPerPeriod", { params: item })
    const data = response.data.data.dashboard

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};


export async function GetInputsOfTheYearByMonth(company: string) {
  try {
    const response = await api.get("dashboard/inputsByMonth", { params: { company: company } })
    const data = response.data.data.dashboard[0]

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};
