import { toast } from "react-toastify";
import { Spinner } from "../../../components/_index.components";
import { TSubscriberPageHooksStates } from "../../_index.pages";
import { _ID } from "../../../utils/_index.utils";
import { Icons } from "../../../assets/icons/_index.icons";

import {
  SubscribersDetailsHooks,
  SubscribersDetailsInfos,
  SubscribersDetailsNotes,
  SubscribersDetailsOrders,
  SubscribersDetailsPayments,
  SubscribersReceipt,
  TSubscribersDetailsHooksStates
} from "./_index.partials";

export type TSubscribersDetailsProps = TSubscriberPageHooksStates & {};

export default function SubscribersDetails({ ...props }: TSubscribersDetailsProps) {

  const states: TSubscribersDetailsHooksStates = SubscribersDetailsHooks({ ...props } as TSubscribersDetailsProps);

  return (
    <>
      <div className="w-full h-auto bg-slate-100 pb-2 nd:mt-10 relative">
        <div className="w-full inline-flex justify-between px-5 nd:px-2">
          <div className="w-full flex flex-col">
            <div className="w-auto inline-flex gap-2">
              <p>Nome:</p>
              <span>
                <strong>
                  {states.featuredSubscribe?.user?.name} {states.featuredSubscribe?.user?.lastname}
                </strong>
              </span>
            </div>
            <div className="w-auto inline-flex gap-2">
              <p>Telefone:</p>
              <span>
                <strong>
                  {states.featuredSubscribe?.user?.phone ?? "-"}
                </strong>
              </span>
            </div>
          </div>

        </div>
        {
          !states.waitingInfos
            ? <>
              {states.featuredTab === _ID.tabInfos && <SubscribersDetailsInfos {...states} />}
              {states.featuredTab === _ID.tabPayments && <SubscribersDetailsPayments {...states} />}
              {states.featuredTab === _ID.tabOrders && <SubscribersDetailsOrders {...states} />}
              {states.featuredTab === _ID.tabNotes && <SubscribersDetailsNotes {...states} />}
              {states.featuredTab === _ID.tabReceipt && <SubscribersReceipt {...states} />}

            </>
            : <div className="w-full h-full flex justify-center items-center">
              <Spinner />
            </div>
        }
      </div >
    </>
  )
};