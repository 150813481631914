import { useState } from "react";
import { useTranslation } from "react-i18next";

type TBotaoProps = {
  icon?: any;
  text: string;
  customStyle?: string;
  customStyleIcon?: string;
  type?: any;
  _onClick?: () => Promise<void> | void;
};

export default function Button(props: TBotaoProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const styleDefault = "h-auto w-auto inline-flex border-2 border-[#DDDDDD] rounded-lg space-x-1 justify-center items-center p-1 px-5 text-primary whitespace-nowrap";
  const styleDefaultIcon = "text-primary";

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!isLoading) {
      setIsLoading(true);

      try {
        await props._onClick?.();
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <button
      className={props.customStyle ? props.customStyle : styleDefault}
      onClick={handleClick}
      type={props.type}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="inline-flex items-center space-x-2">
          <Spinner size={20} />
          <p>Salvando...</p>
        </div>
      ) : (
        <>
          {props.icon && (
            <props.icon
              className={props.customStyleIcon ? props.customStyleIcon : styleDefaultIcon}
              size={20}
            />
          )}
          <p>
            {t(props.text)}
          </p>
        </>
      )}
    </button>
  );
}

const Spinner = ({ size }: { size: number }) => (
  <div
    style={{ width: size, height: size }}
    className="border-4 border-[#C5FFF2]border-t-transparent border-solid rounded-full animate-spin"
  />
);
