

type TColorPickerHooksProps = {}
export type TColorPickerHooksStates = TColorPickerHooksProps & {

};

export const ColorPickerHooks = ({ ...props }: TColorPickerHooksProps): TColorPickerHooksStates => {


  return {
    ...props,
  }
}