import { Icons } from "../../assets/icons/_index.icons";

import {
  Button,
  Input,
  Modal,
  Pagination,
  Spinner,
  Selection
} from "../../components/_index.components";
import { RoutesPath } from "../../routes/AppRoutes.routes";

import { formatCurrency } from "../../utils/_index.utils";

import {
  StockForm,
  StockPageHooks,
  StructurePages,
  TStockPageHooksStates
} from "../_index.pages";

export type TStockPageProps = {};

export default function StockPage({ ...props }: TStockPageProps) {

  const states: TStockPageHooksStates = StockPageHooks({ ...props } as TStockPageProps);

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-auto min-h-screen flex flex-col gap-5 relative overflow-hidden nd:p-0">
              <section className="w-full h-auto flex flex-col gap-5 justify-center items-center p-5 nd:p-2">
                <div className="w-full inline-flex gap-8 justify-center items-center">
                  <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                    <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                    <Input
                      id={"teste"}
                      name={"search"}
                      placeholder="Pesquisar"
                      type={"text"}
                      setValue={states.setSearchBar}
                      value={states.searchBar}
                      style="pl-10"
                    />
                  </div>
                  <Button
                    icon={Icons.search}
                    text="Filtros"
                    _onClick={() => {
                      states.setFeaturedProduct && states.setFeaturedProduct(null);

                      return states.toggle && states.toggle()
                    }}
                    customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                    customStyleIcon="text-[#26c59f]"
                  />
                </div>
                <div className="w-full h-auto inline-flex gap-8">
                  <div className="w-1/4 flex flex-col gap-2 nd:w-full">
                    <label htmlFor="paymentStatus" className="w-auto whitespace-nowrap">Status Produto:</label>
                    <Selection
                      optionsList={[{ value: true, label: "Visível" }, { value: false, label: "Inativo" }, { value: "Todos", label: "Todos" },]}
                      setValue={states.setFilterStatus}
                      value={states.filterStatus}
                    />
                  </div>
                </div>
              </section>
              <div className="w-full h-full flex flex-col gap-3 mt-5 p-5 nd:p-2">
                <div className="w-full inline-flex justify-between items-center">
                  <h1 className="text-2xl">
                    <strong>
                      Estoque
                    </strong>
                  </h1>
                  <Button
                    icon={Icons.register}
                    text="Cadastrar"
                    _onClick={() => {
                      // states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos);
                      states.setFeaturedProduct && states.setFeaturedProduct(null);
                      states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.stockNew);
                    }}
                    customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
                    customStyleIcon="text-white"
                  />
                </div>
                {/* <section className="w-full">
                  <div className="h-auto max-w-[15vw] w-auto flex flex-col gap-2">
                    <p className="whitespace-nowrap">Total de produtos em estoque</p>
                    <div className="flex w-full h-[5vh] rounded-md px-2 bg-slate-200 font-bold text-yellow-700 items-center justify-center text-center">
                      {
                        !states.reloadList
                          ? <span className="h-full text-center flex justify-center items-center text-2xl">{states.balanceStockProductsTotal}</span>
                          : <div className="flex justify-center items-center h-full w-full">
                            <div className="border-8 border-solid border-[#c5fff2] border-t-8 border-t-[#26c59f] rounded-full w-auto h-auto animate-spin" />
                          </div>
                      }
                    </div>
                  </div>

                </section> */}

                <div className="w-full h-full gap-2 flex flex-col">
                  {
                    !states.reloadList
                      ? <table className="w-full">
                        <thead className="bg-gray-300">
                          <tr>
                            <th className="px-4 py-2">Produto</th>
                            <th className="px-4 py-2">Quantidade</th>
                            <th className="px-4 py-2 nd:hidden">Categoria</th>
                            <th className="px-4 py-2 nd:hidden">Preço</th>
                            <th className="px-4 py-2 nd:hidden">Preço Promocional</th>
                            <th className="px-4 py-2">Situação</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {
                            states.productsListDisplayed?.map((item, i) => (
                              <tr
                                className="bg-gray-100 cursor-pointer hover:filter hover:brightness-90 hover:text-[#26c59f] "
                                key={i}
                                onClick={() => {
                                  // states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos)
                                  states.setFeaturedProduct && states.setFeaturedProduct(item);
                                  states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.stockNew);

                                  // return states.toggle && states.toggle()
                                }}
                              >
                                <td className="px-4 py-2 text-start">{item.name}</td>
                                <td className="px-4 py-2">{item.balance}</td>
                                <td className="px-4 py-2 nd:hidden">{item.category.name}</td>
                                <td className="px-4 py-2 nd:hidden">{formatCurrency(item.realPrice)}</td>
                                <td className="px-4 py-2 nd:hidden">{formatCurrency(item.promotionPrice)}</td>
                                <td
                                  className={
                                    `${item.visible ? "bg-green-300" : "bg-red-400"} 
                                        flex justify-center items-center w-auto min-w-[50%] max-h-[75%] h-auto rounded-md text-white p-1`
                                  }
                                >
                                  <p>
                                    {
                                      item.visible
                                        ? "Visivel"
                                        : "Inativo"
                                    }
                                  </p>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      : <Spinner />
                  }
                </div>
              </div>
              <Pagination
                itensPerPage={states.itensPerPage}
                totalItens={states.totalItens}
                setCurrentPage={states.setCurrentPage}
                currentPage={states.currentPage}
              />
              {
                states.featuredRoute === RoutesPath.stockNew &&
                <div className="w-full h-full max-w-full flex flex-col absolute p-2 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                  <h1 className="text-bold text-3xl">{states.featuredProduct?._id ? states.featuredProduct?.name : "Novo Estoque"}</h1>
                  <nav className="inline-flex justify-between w-full">
                    <ul className="inline-flex text-gray-400 gap-3">
                      <li>
                        <button
                          onClick={() => {
                            states.setFeaturedProduct(null);
                            states.setFeaturedRoute(RoutesPath.stock);
                          }}
                        >
                          <span>Estoques</span>
                        </button>
                      </li>
                      <li>&gt;</li>
                      <li className="text-black">{states.featuredProduct?._id ? states.featuredProduct?.name : "Novo"}</li>
                    </ul>
                    <Button
                      text="Voltar"
                      _onClick={() => {
                        states.setFeaturedProduct(null);
                        states.setFeaturedRoute(RoutesPath.stock);
                      }}
                      icon={Icons.back}
                    />
                  </nav>
                  <hr className="h-px" />
                  <StockForm
                    {...states}
                  />
                </div>
              }
            </div>

          </>
        }
      />
    </>
  )
};