import { Form } from "../../../components/_index.components";
import { _ID } from "../../../utils/_index.utils";
import {
  TUserPagesHooksStates,
  TUsersFormHooksStates,
  UsersFormHooks,
  UsersFormInfos,
  UsersFormAddress,
  UsersFormLgpd,
  UsersFormOrders
} from "../_index.users"

export type TUsersFormProps = TUserPagesHooksStates & {};

export default function UsersForm({ ...props }: TUsersFormProps) {

  const states: TUsersFormHooksStates = UsersFormHooks({ ...props } as TUsersFormProps);

  return (
    <>
      <Form
        className="w-full h-full max-h-full bg-slate-100 pb-2 nd:mt-10"
        onSubmit={states.handleRecordUser}
      >
        {
          states.featuredTab === _ID.tabInfos &&
          <UsersFormInfos {...states} />
        }
        {
          states.featuredTab === _ID.tabAddress &&
          <UsersFormAddress {...states} />
        }
        {
          states.featuredTab === _ID.tabOrders &&
          <UsersFormOrders {...states} />
        }
        {
          states.featuredTab === _ID.tabLGPD &&
          <UsersFormLgpd {...states} />
        }
      </Form>
    </>
  )
};