import { useState } from 'react';
import { Icons } from '../../../assets/icons/_index.icons';
import { TProductsPageHooksStates } from '../../_index.pages';
import { Image } from '../../../components/_index.components';
import { _ID } from '../../../utils/_index.utils';

import ImgNoImageAvaible from "../../../assets/img/noImage.png";

type TProductsEmphasisProps = TProductsPageHooksStates & {};

export default function ProductsEmphasis({ ...props }: TProductsEmphasisProps) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const productsPerPage = 12;

  const totalPages = Math.ceil(props.productsList?.filter(prod => prod.emphasis)?.length / productsPerPage);

  const nextPage = () => {
    setCurrentPage(currentPage === totalPages - 1 ? 0 : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage === 0 ? totalPages - 1 : currentPage - 1);
  };

  return (
    <>
      <section className="w-full h-full grid grid-cols-12 gap-2 relative nd:grid-cols-4">
        {
          props.productsList?.filter(prod => prod.emphasis)
            ?.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage)
            ?.map((item, i) => (
              <div
                key={i}
                className={
                  `flex flex-col w-full bg-slate-200 rounded-md h-full cursor-pointer justify-start items-center 
                hover:filter hover:brightness-90 text-[#26c59f] overflow-hidden max-w-[13vh]`
                }
                onClick={() => {
                  props.setFeaturedTab && props.setFeaturedTab(_ID.tabInfos)
                  props.setFeaturedProduct && props.setFeaturedProduct(item);

                  return props.toggle && props.toggle()
                }}
              >
                <div className="aspect-square w-full h-full max-h-[13vh] rounded-md ring-2 ring-white bg-white flex justify-center items-center overflow-hidden">
                  {
                    (item.image?.length > 0)
                      ? (
                        <Image
                          path={process.env.REACT_APP_BUCKET_URL + item.image[0]?.imageThumbUrl}
                        />
                      )
                      : <Image path={ImgNoImageAvaible} />
                  }
                </div>
                {/* <p className="whitespace-nowrap overflow-hidden text-ellipsis w-full text-center px-2">
                  {item.name}
                </p> */}
              </div>
            ))
        }
        <div className="absolute top-0 bottom-0 left-0 flex items-center">
          <button
            className="p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -ml-5"
            onClick={prevPage}
          >
            <Icons.previous size={20} />
          </button>
        </div>
        <div className="absolute top-0 bottom-0 right-0 flex items-center">
          <button
            className="p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -mr-5"
            onClick={nextPage}
          >
            <Icons.next size={20} />
          </button>
        </div>
      </section>
    </>
  );
}
