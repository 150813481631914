import { Icons } from "../../assets/icons/_index.icons";

import {
  Button,
  Input,
  Modal,
  Pagination,
  Spinner

} from "../../components/_index.components";
import { RoutesPath } from "../../routes/AppRoutes.routes";

import { formatCurrency, formatDate } from "../../utils/_index.utils";

import {
  OrdersDetails,
  OrdersPageHooks,
  StructurePages,
  TOrdersPageHooksStates
} from "../_index.pages";

export type TOrdersPageProps = {};

export default function OrdersPage({ ...props }: TOrdersPageProps) {

  const states: TOrdersPageHooksStates = OrdersPageHooks({ ...props } as TOrdersPageProps);

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full flex flex-col gap-5 relative overflow-hidden nd:p-0">
              <section className="w-full h-auto inline-flex gap-8 justify-center items-center p-5 nd:p-2 nd:flex nd:flex-col nd:gap-2">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="search" className="w-auto whitespace-nowrap">Pesquisa:</label>
                  <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                    <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                    <Input
                      id={"teste"}
                      name={"search"}
                      placeholder="Pesquisar"
                      type={"text"}
                      setValue={states.setSearchBar}
                      value={states.searchBar}
                      style="pl-10"
                    />
                  </div>
                </div>
                <div className="inline-flex gap-5 w-full">
                  <div className="w-full flex flex-col gap-2">
                    <label htmlFor="initDate" className="w-auto whitespace-nowrap">Data Inicial:</label>
                    <Input
                      id={"initDate"}
                      name={"initDate"}
                      type={"date"}
                      setValue={states.setInitDatePeriod}
                      value={states.initDatePeriod}
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <label htmlFor="finalDtae" className="w-auto whitespace-nowrap">Data Final:</label>
                    <Input
                      id={"finalDtae"}
                      name={"finalDtae"}
                      type={"date"}
                      setValue={states.setEndDatePriod}
                      value={states.endDatePeriod}
                    />
                  </div>
                  <div className="flex justify-end items-end nd:hidden">
                    <Button
                      icon={Icons.search}
                      text="Filtros"
                      _onClick={() => { }}
                      customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                      customStyleIcon="text-[#26c59f]"
                    />
                  </div>
                </div>

              </section>
              <section className="inline-flex w-full justify-between gap-5 px-5 nd:p-2">
                <h1 className="text-2xl font-bold nd:text-base whitespace-nowrap">Histórico de pedidos</h1>
                <Button
                  icon={Icons.register}
                  text="Novo pedido"
                  _onClick={() => states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.orderNew)}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f] whitespace-nowrap nd:px-2"
                  customStyleIcon="text-[#26c59f]"
                />
              </section>
              <div className="w-full h-full gap-2 flex flex-col p-5 nd:p-2">
                {
                  !states.reloadList
                    ? <table className="w-full nd:text-xs">
                      <thead className="bg-gray-300">
                        <tr>
                          <th className="px-4 py-2">Cliente</th>
                          <th className="px-4 py-2">Qtde.</th>
                          <th className="px-4 py-2">Produto</th>
                          <th className="px-4 py-2">Valor total</th>
                          <th className="px-4 py-2">Data</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {states.ordersListDisplayed
                          ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                          ?.map((item, i) => (
                            <tr
                              className={`bg-gray-100 cursor-pointer hover:filter hover:brightness-90 hover:text-[#26c59f] ${i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'
                                }`}
                              key={i}
                              onClick={() => {
                                states.setFeaturedOrder && states.setFeaturedOrder(item);
                                // states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.orderNew);
                              }}
                            >
                              <td className="px-4 py-2 text-start">{item.user?.name} {item.user?.lastname}</td>
                              <td className="px-4 py-2 text-center">{item.amount}</td>
                              <td className="px-4 py-2 text-center">{states.productsList?.find(p => p._id === item.product)?.name}</td>
                              <td className="px-4 py-2">
                                {
                                  formatCurrency(
                                    (
                                      item.amount *
                                      (
                                        states.productsList?.find(p => p._id === item.product)?.promotionPrice > 0
                                          ? states.productsList?.find(p => p._id === item.product)?.promotionPrice
                                          : states.productsList?.find(p => p._id === item.product)?.realPrice
                                      )
                                    )
                                  )
                                }
                              </td>
                              <td className="px-4 py-2 text-center">{formatDate(item.createdAt)}</td>
                              <td className="px-4 py-2 text-center">
                                <button
                                  onClick={() => {
                                    states.setFeaturedOrder(item);
                                    states.chargebackModal.toggle();
                                  }}
                                >
                                  <Icons.delete />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    : <Spinner />
                }
              </div>

              {
                (states.ordersListDisplayed?.length > 0) &&
                <Pagination
                  itensPerPage={states.itensPerPage}
                  totalItens={states.totalItens}
                  setCurrentPage={states.setCurrentPage}
                  currentPage={states.currentPage}
                />
              }
              {
                states.featuredRoute === RoutesPath.orderNew &&
                <div className="w-full h-full max-w-full flex flex-col absolute p-2 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                  <h1 className="text-bold text-3xl">Novo Pedido</h1>
                  <nav className="inline-flex justify-between w-full">
                    <ul className="inline-flex text-gray-400 gap-3">
                      <li>
                        <button
                          onClick={() => {
                            states.setFeaturedOrder(null);
                            states.setFeaturedRoute(RoutesPath.orders);
                          }}
                        >
                          <span>Pedidos</span>
                        </button>
                      </li>
                      <li>&gt;</li>
                      <li className="text-black">Novo</li>
                    </ul>
                    <Button
                      text="Voltar"
                      _onClick={() => {
                        states.setFeaturedOrder(null);
                        states.setFeaturedRoute(RoutesPath.orders);
                      }}
                      icon={Icons.back}
                    />
                  </nav>
                  <hr className="h-px" />
                  <OrdersDetails
                    {...states}
                  />
                </div>
              }
            </div>
          </>
        }
      />
      {
        <Modal
          close={states.chargebackModal?.close}
          open={states.chargebackModal?.open}
          toggle={states.chargebackModal?.toggle}
          header={false}
          module={
            <>
              <div
                className="flex flex-col text-9xl bg-none w-auto h-auto w-min-[30%] min-h-[40%] rounded-md"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="w-full h-auto min-h-[2vh] inline-flex text-primary justify-between bg-none p-2 relative">
                  <h2 className='text-xl'>
                    <strong>
                      Confirmação de estorno
                    </strong>
                  </h2>
                  <button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      states.chargebackModal.close();
                    }}
                  >
                    <Icons.close
                      className="text-red-700"
                      size={30}
                    />
                  </button>
                </div>
                <hr className="bg-slate-200 h-px" />
                <div className="flex w-full h-full p-4 flex-col gap-5">
                  <p className="text-4xl text-bold text-center">Tem certeza que deseja estornar o pedido de</p>
                  <p className="text-4xl text-bold text-center">{states.featuredOrder?.user?.name}?</p>
                  <div className="inline-flex items-center justify-center bg-slate-200 p-3 rounded-md">
                    <p className="text-sm text-green-700">* Digite os caracters ao lado na caixa de texto e confirme.</p>
                    <input
                      className="w-[90%] h-20 text-4xl rounded-md p-2 bg-primary-background text-center border-2 border-white"
                      value={states.confirmationCode}
                      readOnly
                      id={null}
                    />
                  </div>
                  <div className="inline-flex justify-between rounded-md">
                    <div className="flex justify-start px-2 w-full text-lg">
                      <Input
                        id={"code"}
                        name={"code"}
                        placeholder="Código"
                        type={"text"}
                        setValue={states.setCodeProvided}
                        value={states.codeProvided}
                        style="text-center font-bold"
                      />
                    </div>
                    <div className="flex justify-end">
                      <span className='flex justify-center items-center text-center text-xl px-2'>
                        <Button
                          _onClick={states.handleConfirmation}
                          icon={Icons.check}
                          text="Confirmar"
                          customStyle="h-auto w-auto inline-flex border-2 border-[#DDDDDD] rounded-lg space-x-2 justify-center items-center p-1 px-5 text-primary whitespace-nowrap bg-green-600 text-white"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div >
            </>
          }
        />
      }
    </>
  )
};