import { SketchPicker } from "react-color";
import { TCompanyFormLayoutHooksStates } from "../../pages/_index.pages";
import { ColorPickerHooks } from "./_index.colorPicker";
import { Icons } from "../../assets/icons/_index.icons";


export type TColorPickerProps = {
  color: string | undefined;
  close: () => void;
  setColor: (data: string) => void;
};
// export type TColorPickerProps = TCompanyFormLayoutHooksStates & {};

export default function ColorPicker({ ...props }: TColorPickerProps) {

  const states = ColorPickerHooks({ ...props } as TColorPickerProps);

  return (
    <>
      <div
        className="w-full h-auto flex flex-col rounded-md px-2 p-1 ring-2 ring-ringDate space-y-1"
        style={{
          background: "rgba(136, 136, 136, 0.20)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >
        <div className="w-full h-full flex justify-end">
          <button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              props?.close();
            }}
          >
            <Icons.close
              className="text-primary hover:text-negative"
              size={20}
            />
          </button>
        </div>
        <SketchPicker
          width="8vw"
          disableAlpha={true}
          color={props.color}
          onChange={(color: any) => props.setColor(color.hex)}
        />
      </div>
    </>
  )
}