import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ECOMMERCE_CMS_SEEDZ_TECH,
  LOCALSTORAGE_ROUTES
} from "../../../../utils/_index.utils";

type THeaderIdiomaHooksProps = {}

type THeaderIdiomaHooksStates = THeaderIdiomaHooksProps & {
  ref: any;
  mostrarIdioma: boolean;
  setMostrarIdioma: (data: boolean) => void;
  setIdioma: (data: string) => void;
  t: (data: string) => any;
};

export const HeaderLanguageHooks = (props: THeaderIdiomaHooksProps): THeaderIdiomaHooksStates => {
  const { t, i18n } = useTranslation();

  const [showLanguage, setShowLanguage] = useState(false);
  const [language, setLanguage] = useState<string>(
    localStorage.getItem(ECOMMERCE_CMS_SEEDZ_TECH + LOCALSTORAGE_ROUTES.language) ?? ""
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // i18n.changeLanguage(language);

    function manusearClickNaTela(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node) && showLanguage) {
        setShowLanguage(false);
      };
    };

    document.addEventListener("mousedown", manusearClickNaTela);

    return () => {
      document.removeEventListener("mousedown", manusearClickNaTela);
    };

  }, [language, showLanguage]);

  return {
    ...props,
    ref: ref,
    mostrarIdioma: showLanguage,
    setMostrarIdioma: setShowLanguage,
    setIdioma: setLanguage,
    t: t,
  } as THeaderIdiomaHooksStates;
};