import { Icons } from "../../../assets/icons/_index.icons";
import {
  Button,
  Input,
  Selection
} from "../../../components/_index.components";
import { GENDER_LIST, USER_TYPES_LIST } from "../../../utils/users.utils";

import {
  TUsersFormHooksStates,
  TUsersFormInfosHooksStates,
  UsersFormInfosHooks
} from "../../_index.pages";

export type TUsersFormInfosProps = TUsersFormHooksStates & {};

export default function UsersFormInfos({ ...props }: TUsersFormInfosProps) {

  const states: TUsersFormInfosHooksStates = UsersFormInfosHooks({ ...props } as TUsersFormInfosProps);

  return (
    <>
      <div className="w-full h-full flex flex-col gap-3">
        <div className="w-full flex-grow overflow-auto flex flex-col gap-3 nd:flex-col">
          <div className="w-full inline-flex gap-8 h-[49vh] nd:flex nd:flex-col">
            <div className="w-[30%] h-full flex rounded-md border nd:hidden">
              {/* Content */}
            </div>
            <div className="w-[70%] h-full flex flex-col gap-4 nd:w-full">
              <div className="flex flex-col w-full gap-4">
                <div className="inline-flex w-full gap-4">
                  <div className="w-1/2 flex flex-col gap-2">
                    <label htmlFor="user_name" className="w-auto whitespace-nowrap">Nome:</label>
                    <Input
                      id={"user_name"}
                      name={"user_name"}
                      type={"text"}
                      setValue={states.setUserName}
                      setInValue={states.setInName}
                      inValue={states.inName}
                      value={states.userName}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col gap-2">
                    <label htmlFor="user_lastname" className="w-auto whitespace-nowrap">Sobrenome:</label>
                    <Input
                      id={"user_lastname"}
                      name={"user_lastname"}
                      type={"text"}
                      setValue={states.setUserLastname}
                      value={states.userLastname}
                      setInValue={states.setInLastname}
                      inValue={states.inLastname}
                    />
                  </div>
                </div>
                <div className="w-1/3 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_nickname" className="w-auto whitespace-nowrap">Apelido:</label>
                  <Input
                    id={"user_nickname"}
                    name={"user_nickname"}
                    type={"text"}
                    setValue={states.setUserNickname}
                    value={states.userNickname}
                  />
                </div>
              </div>
              <div className="inline-flex w-full gap-4">
                <div className="w-1/4 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_document" className="w-auto whitespace-nowrap">Documento CPF:</label>
                  <Input
                    id={"user_document"}
                    name={"user_document"}
                    type={"text"}
                    setValue={states.setUserDocument}
                    value={states.userDocument}
                  />
                </div>
                <div className="w-1/4 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_document_rg" className="w-auto whitespace-nowrap">Documento RG:</label>
                  <Input
                    id={"user_document_rg"}
                    name={"user_document_rg"}
                    type={"text"}
                    setValue={states.setUserDocumentRG}
                    value={states.userDocumentRG}
                  />
                </div>
              </div>
              <div className="inline-flex w-full gap-4 nd:w-full">
                <div className="w-1/4 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_phone" className="w-auto whitespace-nowrap">Telefone:</label>
                  <Input
                    id={"user_phone"}
                    name={"user_phone"}
                    type={"text"}
                    setValue={states.setUserPhone}
                    value={states.userPhone}
                  />
                </div>
                <div className="w-1/3 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_email" className="w-auto whitespace-nowrap">Email:</label>
                  <Input
                    id={"user_email"}
                    name={"user_email"}
                    type={"text"}
                    setValue={states.setUserEmail}
                    value={states.userEmail}
                    setInValue={states.setInEmail}
                    inValue={states.inEmail}
                  />
                </div>
              </div>
              <div className="inline-flex w-full gap-4 nd:flex nd:flex-col">
                <div className="w-1/3 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_birthday" className="w-auto whitespace-nowrap">Aniversário:</label>
                  <Input
                    id={"user_birthday"}
                    name={"user_birthday"}
                    type={"date"}
                    setValue={states.setUserBirthday}
                    value={states.userBirthday}
                    setInValue={states.setInBirthday}
                    inValue={states.inBirthday}
                  />
                </div>
                <div className="w-1/3 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_gender" className="w-auto whitespace-nowrap">Gênero:</label>
                  <Selection
                    optionsList={GENDER_LIST}
                    setValue={states.setUserGender}
                    value={states.userGender}
                    setInValue={states.setInGender}
                    inValue={states.inGender}
                  />
                </div>
                <div className="w-1/3 flex flex-col gap-2 nd:w-full">
                  <label htmlFor="user_type" className="w-auto whitespace-nowrap">Tipo:</label>
                  <Selection
                    optionsList={states.filteredUserTypesList}
                    setValue={states.setUserType}
                    value={states.userType}
                    setInValue={states.setInType}
                    inValue={states.inType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end items-end p-4">
          <Button
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
            customStyleIcon="text-white"
            text="Salvar"
            icon={Icons.save}
            type="submit"
            _onClick={states.handleRecordUser}
          />
        </div>
      </div>
    </>
  );
}
