import ImgNoImageAvaible from "../../assets/img/noImage.png";

import { Icons } from "../../assets/icons/_index.icons";

import {
  _ID,
  formatDate
} from "../../utils/_index.utils";

import {
  CategoriesEmphasis,
  CategoriesEmphasisCard,
  CategoriesForm,
  CategoriesPageHooks,
  StructurePages,
  TCategoriesPageHooksStates
} from "../_index.pages";

import {
  Button,
  Image,
  Input,
  Modal,
  Pagination,
  Spinner
} from "../../components/_index.components";
import { RoutesPath } from "../../routes/AppRoutes.routes";

export type TCategoriesPageProps = {};

const tabList = [
  {
    nome: "Informações",
    _id: _ID.tabInfos
  },
  {
    nome: "SubCategoria",
    _id: _ID.tabSubCategory
  },
  {
    nome: "Produtos",
    _id: _ID.tabProducts
  }
]

export default function CategoriesPage({ ...props }: TCategoriesPageProps) {

  const states: TCategoriesPageHooksStates = CategoriesPageHooks({ ...props } as TCategoriesPageProps);

  return (
    <StructurePages
      module={
        <>
          <div className="w-full h-full flex flex-col gap-5 relative overflow-hidden nd:p-0">
            <section className="w-full h-auto inline-flex gap-8 justify-center items-center p-5 nd:p-2">
              <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                <Input
                  id={"teste"}
                  name={"search"}
                  placeholder="Pesquisar"
                  type={"text"}
                  setValue={states.setSearchBar}
                  value={states.searchBar}
                  style="pl-10"
                />
              </div>
              <Button
                icon={Icons.search}
                text="Filtros"
                _onClick={() => states.setFeaturedCategory && states.setFeaturedCategory(null)}
                customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                customStyleIcon="text-[#26c59f]"
              />
            </section>
            <section className="w-full h-auto max-h-[13vh] min-h-[10vh] flex flex-col gap-1 p-5 nd:p-2">
              <h4>Categorias destacadas no site</h4>
              <div className="w-full h-full grid grid-cols-4 gap-2 nd:flex nd:flex-col">
                {
                  states.categoriesEmphasisDisplayed?.map((item, i) => (
                    <CategoriesEmphasisCard
                      key={i}
                      categoryEmphasis={item}
                      {...states}
                    />
                  ))
                }
              </div>
            </section>
            <div className="w-full h-full flex flex-col gap-3 p-5 nd:p-2">
              <div className="w-full inline-flex justify-between items-center">
                <h1 className="text-2xl">
                  <strong>
                    Categorias
                  </strong>
                </h1>
                <Button
                  icon={Icons.register}
                  text="Cadastrar"
                  _onClick={() => {
                    states.setFeaturedCategory && states.setFeaturedCategory(null);
                    states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.categoryNew)
                  }}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
                  customStyleIcon="text-white"
                />
              </div>
              <div className="w-full h-full gap-2 flex flex-col">
                {
                  (!states.reloadList)
                    ? states.categoriesListDisplayed?.map((item, i) => (
                      <div
                        key={i}
                        className="w-full h-auto min-h-[10vh] max-h-[15vh] rounded-md justify-between inline-flex p-2 gap-8 hover:text-[#26c59f] cursor-pointer bg-slate-200"
                        style={{
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          backdropFilter: "blur(5.5px)",
                        }}
                        onClick={() => {
                          states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos)
                          states.setFeaturedCategory && states.setFeaturedCategory(item);
                          states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.categoryNew)
                        }}
                      >
                        <div className="inline-flex gap-4 w-auto">
                          <div className="aspect-square w-full max-w-[15vh] h-full max-h-[15vh] rounded-md ring-2 ring-white bg-white">
                            {
                              item.image?.imageThumbUrl
                                ? <Image
                                  path={process.env.REACT_APP_BUCKET_URL + item.image?.imageThumbUrl}
                                />
                                : <Image path={ImgNoImageAvaible} />
                            }

                          </div>
                          <div className="w-auto h-full flex flex-col">
                            <span>
                              <strong>
                                {item.name}
                              </strong>
                            </span>
                            <p className="text-ellipsis overflow-hidden line-clamp-2 nd:hidden">
                              {item.description}
                            </p>
                          </div>
                        </div>

                        <div className="w-auto h-full flex flex-col p-2">
                          <div className="inline-flex gap-4 nd:hidden">
                            <span>Registrado:</span>
                            <span>{formatDate(item.createdAt, { onlyDate: true })}</span>
                          </div>
                          <div className="inline-flex gap-4 nd:hidden">
                            <span>Atualizado:</span>
                            <span>{formatDate(item.updatedAt, { onlyDate: true })}</span>
                          </div>
                          <div className="inline-flex gap-4 justify-between">
                            <span className="nd:hidden">Visibilidade:</span>
                            <div
                              className={
                                `${item.visible ? "bg-green-300" : "bg-red-400"} 
                                flex justify-center items-center w-auto min-w-[50%] max-h-[75%] h-auto rounded-md text-white p-1`
                              }
                            >
                              <p>
                                {
                                  item.visible
                                    ? "Visivel"
                                    : "Inativo"
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : <Spinner />
                }

              </div>
            </div>
            {
              (states.categoriesListDisplayed?.length > 0) &&
              <Pagination
                itensPerPage={states.itensPerPage}
                totalItens={states.totalItens}
                setCurrentPage={states.setCurrentPage}
                currentPage={states.currentPage}
              />
            }
            {
              states.featuredRoute === RoutesPath.categoryNew &&
              <div className="w-full h-full max-w-full flex flex-col absolute p-2 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                <h1 className="text-bold text-3xl">Nova categoria</h1>
                <nav className="inline-flex justify-between w-full">
                  <ul className="inline-flex text-gray-400 gap-3">
                    <li>
                      <button
                        onClick={() => {
                          states.setFeaturedCategory(null);
                          states.setFeaturedRoute(RoutesPath.categories);
                        }}
                      >
                        <span>Categorias</span>
                      </button>
                    </li>
                    <li>&gt;</li>
                    <li className="text-black">Novo</li>
                  </ul>
                  <Button
                    text="Voltar"
                    _onClick={() => {
                      states.setFeaturedCategory(null);
                      states.setFeaturedRoute(RoutesPath.categories);
                    }}
                    icon={Icons.back}
                  />
                </nav>
                <hr className="h-px" />
                <CategoriesForm
                  {...states}
                />
              </div>
            }
          </div>
          <Modal
            open={states.emphasisModal.open}
            toggle={states.emphasisModal.toggle}
            close={states.emphasisModal.close}
            module={
              <>
                <CategoriesEmphasis {...states} />
              </>
            }
          />
        </>
      }
    />
  )
};