import {
  CategoriesEmphasisHooks,
  CategoriesConfirmEmphasis,
  TCategoriesEmphasisHooksStates,
  TCategoriesPageHooksStates
} from "../../_index.pages";

import {
  Image,
  Modal
} from "../../../components/_index.components";

import ImgNoImageAvaible from "../../../assets/img/noImage.png";

export type TCategoriesEmphasisProps = TCategoriesPageHooksStates & {}

export default function CategoriesEmphasis({ ...props }: TCategoriesEmphasisProps) {

  const states: TCategoriesEmphasisHooksStates = CategoriesEmphasisHooks({ ...props } as TCategoriesEmphasisProps);

  return (
    <>
      <div className="w-[70vw] nd:w-auto h-[45vh] nd:h-auto max-h-[75vh] nd:max-w-full bg-white rounded-b-md shadow-2xl bg-sec-light-background pb-2 p-4 gap-4 flex flex-col">
        <div className="w-full">
          <p className="whitespace-nowrap text-lg font-bold">Selecione a categoria a ser destacada</p>
          <p className="text-xs">Não está vendo alguma categoria aqui? É possível que ela esteja com o status inativo. São exibidas somente as categorias que possuirem o status ativo!</p>
        </div>
        <div className="w-full grid grid-cols-4 gap-4 nd:flex nd:flex-col">
          {
            states.categoriesList
              ?.filter(item => !item.isEmphasis && item.status)
              ?.map((category, i) => (
                <div
                  key={i}
                  className="w-full max-h-full rounded-md inline-flex gap-2 ring-2 ring-slate-200 text-center items-center hover:filter hover:brightness-50 cursor-pointer"
                  onClick={() => {
                    states.setSelectedCategory(category);
                    states.confirmEmphasisModal.toggle()
                  }}
                >
                  <div className="w-[7vh] min-w-[7vh] h-[7vh]">
                    {
                      category.image?.imageThumbUrl
                        ? <Image
                          path={process.env.REACT_APP_BUCKET_URL + category.image?.imageThumbUrl}
                        />
                        : <Image path={ImgNoImageAvaible} />
                    }
                  </div>
                  <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                    {
                      category.name
                    }
                  </span>
                </div>
              ))
          }
        </div>
      </div>
      <Modal
        open={states.confirmEmphasisModal.open}
        toggle={states.confirmEmphasisModal.toggle}
        close={states.confirmEmphasisModal.close}
        module={
          <CategoriesConfirmEmphasis
            category={states.selectedCategory}
            {...states}
          />
        }
      />
    </>
  )
}