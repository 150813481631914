import { useEffect, useState } from "react";
import { TUsersFormProps } from "../UsersForm";
import { ILgpd } from "../../../../interfaces/ILgpd.i";
import { IAddress } from "../../../../interfaces/IAddress.i";
import { IUser } from "../../../../interfaces/IUser.i";
import { UpdateUser, UserRegister } from "../../../../services/_index.services";
import { toast } from "react-toastify";
import { USER_STATUS } from "../../../../utils/users.utils";
import { validateRequiredFields } from "../../../../utils/_index.utils";

type TUsersFormHooksProps = TUsersFormProps & {};
export type TUsersFormHooksStates = TUsersFormHooksProps & {
  userName: string; userLastname: string; userNickname: string; userType: string;
  userDocument: string; userDocumentRG: string; userBirthday: string; userGender: string;
  userEmail: string; userPhone: string; userStatus: string; userImage: any;
  userAddress: IAddress[]; userLgpd: ILgpd; inBirthday: boolean; inEmail: boolean; inGender: boolean;
  inLastname: boolean; inName: boolean; inType: boolean;
  setInBirthday: (d: boolean) => void; setInEmail: (d: boolean) => void; setInGender: (d: boolean) => void;
  setInLastname: (d: boolean) => void; setInName: (d: boolean) => void; setInType: (d: boolean) => void;
  setUserName: (d: string) => void; setUserLastname: (d: string) => void; setUserNickname: (d: string) => void;
  setUserType: (d: string) => void; setUserDocument: (d: string) => void; setUserDocumentRG: (d: string) => void;
  setUserBirthday: (d: string) => void; setUserGender: (d: string) => void; setUserEmail: (d: string) => void;
  setUserPhone: (d: string) => void; setUserStatus: (d: string) => void; handleRecordUser: () => void;
  setUserImage: (d: any) => void; setUserAddress: (d: IAddress[]) => void; setUserLgpd: (d: ILgpd) => void;
};

export const UsersFormHooks = ({ ...props }: TUsersFormHooksProps): TUsersFormHooksStates => {
  const [userName, setUserName] = useState<string>("");
  const [userLastname, setUserLastname] = useState<string>("");
  const [userNickname, setUserNickname] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [userDocument, setUserDocument] = useState<string>("");
  const [userDocumentRG, setUserDocumentRG] = useState<string>("");
  const [userBirthday, setUserBirthday] = useState<string>("");
  const [userGender, setUserGender] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [userStatus, setUserStatus] = useState<string>("");
  const [userImage, setUserImage] = useState<any | null>(null);
  const [userAddress, setUserAddress] = useState<IAddress[]>();
  const [userLgpd, setUserLgpd] = useState<ILgpd | null>(null);

  const [inBirthday, setInBirthday] = useState<boolean>(false);
  const [inEmail, setInEmail] = useState<boolean>(false);
  const [inGender, setInGender] = useState<boolean>(false);
  const [inLastname, setInLastname] = useState<boolean>(false);
  const [inName, setInName] = useState<boolean>(false);
  const [inType, setInType] = useState<boolean>(false);

  useEffect(() => {
    const item: IUser = props.featuredUser;
    if (!item)
      return;


    setUserName(item.name ?? "");
    setUserLastname(item.lastname ?? "");
    setUserNickname(item.nickname ?? "");
    setUserType(item.type ?? "");
    setUserDocument(item.document ?? "");
    setUserDocumentRG(item.document_rg ?? "");
    setUserBirthday(new Date(item.birthday)?.toISOString()?.split('T')[0] ?? new Date()?.toISOString()?.split('T')[0]);
    setUserGender(item.gender ?? "");
    setUserEmail(item.email ?? "");
    setUserPhone(item.phone ?? "");
    setUserStatus(item.status ?? "");
    setUserImage(item.image ?? null);
    setUserAddress(item.address ?? []);
    setUserLgpd(item.lgpd ?? null);

  }, [props.featuredUser]);

  async function handleRecordUser() {
    let obj = {
      birthday: new Date(userBirthday),
      document: userDocument,
      document_rg: userDocumentRG,
      email: userEmail,
      gender: userGender,
      lastname: userLastname,
      name: userName,
      phone: userPhone,
      status: USER_STATUS.ACTIVE,
      type: userType,
      address: userAddress,
      image: userImage,
      company: props.authState?.user?.company,
      lgpd: {
        accept_cookies: true,
        accept_email: true,
        accept_lgpd: true,
        accept_phone: true,
        accept_rules: true,
        accept_terms: true,
      },
      nickname: userNickname,
      password: "password",
    } as IUser;

    const listaCamposObrigatorios = [
      { parameter: "birthday", setFunction: setInBirthday },
      { parameter: "email", setFunction: setInEmail },
      { parameter: "gender", setFunction: setInGender },
      { parameter: "lastname", setFunction: setInLastname },
      { parameter: "name", setFunction: setInName },
      { parameter: "type", setFunction: setInType },
    ];

    if (validateRequiredFields({ requiredFieldsList: listaCamposObrigatorios, objForValidation: obj }))
      return toast.warning("Por favor, verifique os campos obrigatórios!");

    if (props.featuredUser?._id) {
      delete obj.password;

      var resp = await UpdateUser(props.featuredUser?._id, obj);
    } else
      var resp = await UserRegister(obj);

    if (resp && resp.data?.user) {
      toast.success(resp?.message);
      props.close();
    } else {
      toast.error(resp?.message);
    }

  };

  return {
    ...props,
    userName,
    userLastname,
    userNickname,
    userType,
    userDocument,
    userDocumentRG,
    userBirthday,
    userGender,
    userEmail,
    userPhone,
    userStatus,
    userImage,
    userAddress,
    userLgpd,
    inBirthday,
    inEmail,
    inGender,
    inLastname,
    inName,
    inType,
    setInBirthday,
    setInEmail,
    setInGender,
    setInLastname,
    setInName,
    setInType,
    handleRecordUser,
    setUserName,
    setUserLastname,
    setUserNickname,
    setUserType,
    setUserDocument,
    setUserDocumentRG,
    setUserBirthday,
    setUserGender,
    setUserEmail,
    setUserPhone,
    setUserStatus,
    setUserImage,
    setUserAddress,
    setUserLgpd,
  };
};