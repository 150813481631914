import { Icons } from "../../../assets/icons/_index.icons";
import { TCompanyFormsHooksStates } from "../../../pages/_index.pages";
import { formatDate } from "../../../utils/_index.utils";

import {
  Button,
  FormAddressHooks,
  GoogleMaps,
  Input
} from "../../_index.components";

export type TFormAddressProps = TCompanyFormsHooksStates & {};

export default function FormAddress({ ...props }: TFormAddressProps) {

  const states = FormAddressHooks({ ...props } as TFormAddressProps);

  return (
    <>
      <div className="flex flex-col rounded-md w-full h-full pb-2">
        {
          states.addressesList?.length > 0
            ? <div className="inline-flex w-full h-full p-2 gap-2 nd:flex nd:flex-col">
              <div className="flex flex-col w-[80%] h-full ring-1 ring-slate-200 p-2 rounded-md nd:w-full">
                <div className="inline-flex w-full h-[50%] gap-10">
                  <div className="flex flex-col w-full gap-5">
                    <div className="flex flex-row w-full mt-2">
                      <div className="flex flex-row justify-start items-center w-full m-1 rounded-md bg-slate-200 nd:gap-5 nd:px-5">
                        <input
                          type="checkbox"
                          className="w-[10%] -ml-4 h-6 bg-slate-100"
                          checked={states.toMakeAvaible}
                          onChange={(e) => states.setToMakeAvaible(e.target.checked)}
                        />
                        <label className="flex -ml-4 p-1 text-black">Disponibilizar endereço para clientes</label>
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-4">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="postal_code" className="w-auto whitespace-nowrap">CEP</label>
                        <Input
                          id={"teste"}
                          name={"postal_code"}
                          type={"text"}
                          setValue={states.setPostaAddressCode}
                          value={states.postalAddressCode}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="nickname" className="w-auto whitespace-nowrap">Apelido</label>
                        <Input
                          id={"teste"}
                          name={"nickname"}
                          type={"text"}
                          setValue={states.setNickAddress}
                          value={states.nickAddress}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="address" className="w-auto whitespace-nowrap">Endereço</label>
                        <Input
                          id={"teste"}
                          name={"address"}
                          type={"text"}
                          setValue={states.setStreetAddress}
                          value={states.streetAddress}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-4">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="number_address" className="w-auto whitespace-nowrap">Número</label>
                        <Input
                          id={"teste"}
                          name={"number_address"}
                          type={"text"}
                          setValue={states.setNumberAddress}
                          value={states.numberAddress}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="complement" className="w-auto whitespace-nowrap">Complemento</label>
                        <Input
                          id={"teste"}
                          name={"complement"}
                          type={"text"}
                          setValue={states.setComplementAddress}
                          value={states.complementAddress}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full gap-4 nd:flex nd:flex-col">
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="city" className="w-auto whitespace-nowrap">Cidade</label>
                        <Input
                          id={"teste"}
                          name={"city"}
                          type={"text"}
                          setValue={states.setCityAddress}
                          value={states.cityAddress}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="state" className="w-auto whitespace-nowrap">Estado</label>
                        <Input
                          id={"teste"}
                          name={"state"}
                          type={"text"}
                          setValue={states.setStateAddress}
                          value={states.stateAddress}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2 ">
                        <label htmlFor="country" className="w-auto whitespace-nowrap">País</label>
                        <Input
                          id={"teste"}
                          name={"country"}
                          type={"text"}
                          setValue={states.setCountryAddress}
                          value={states.countryAddress}
                        />
                      </div>
                    </div>
                    <div className="w-full flex justify-end">
                      <div
                        onClick={states.handleRecordBtn}
                        className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                      >
                        <Icons.register />
                        <span>Gravar endereço</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-[45%] h-full flex justify-center items-center nd:hidden">
                    <GoogleMaps />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[20%] h-full ring-1 ring-slate-200 p-2 rounded-md nd:w-full">
                <div className="flex flex-col gap-2">
                  <div className="inline-flex justify-between w-full items-center">
                    <h3>Endereços</h3>
                    <div
                      className="w-auto cursor-pointer"
                      onClick={states.includeNewAddress}>
                      <Icons.register className="text-[#26c59f]" size={30} />
                    </div>
                  </div>
                  <hr className="h-px w-full" />
                </div>
                {
                  states.addressesList.length > 0 && states.addressesList
                    ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                    .map((item, i) => (
                      <div
                        key={i}
                        className={
                          `flex w-full cursor-pointer rounded-md mt-1 
                      ${item?._id === states.addressSelected ? "bg-[#26c59f] text-white" : "bg-none text-black"}`
                        }
                      >
                        <div className="inline-flex justify-between w-full">
                          <div className="flex text-sm w-full"
                            onClick={() => states.handleAddress(item?._id)}
                          >
                            <Icons.street
                              className="m-2"
                              size={15}
                            />
                            <p className="p-1 text-ellipsis whitespace-nowrap">
                              {
                                formatDate(item.created_at ?? new Date(), { onlyDate: true })
                              }
                            </p>
                            <p className="p-1 text-ellipsis whitespace-nowrap overflow-hidden">
                              {
                                "- " + item?.name_address
                              }
                            </p>
                          </div>
                          <div
                            className="flex w-[10%]"
                            onClick={() => states.removeAddress(item._id)}
                          >
                            <Icons.delete
                              className="m-2"
                              size={15}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>
            : <div className="w-full h-full flex justify-center items-center">
              <div
                className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                onClick={states.includeNewAddress}>
                <Icons.register size={30} />
                <span className="text-xl">Incluir</span>
              </div>
            </div>
        }

      </div >
    </>
  )
};