import { useEffect, useState } from "react";
import { TCategoriesFormSubProps } from "../CategoriesFormSub";
import { ISubCategory } from "../../../../interfaces/_index.interfaces";
import { toast } from "react-toastify";
import { GENERIC_ID_ } from "../../../../utils/_index.utils";

type TCategoriesFormSubHooksProps = TCategoriesFormSubProps & {};
export type TCategoriesFormSubHooksStates = TCategoriesFormSubHooksProps & {
  subCategoryList: any[];
  idSubCategorySelected: string | null;
  subCategoryName: string;
  subCategoryStatus: boolean;
  removeSubCategory: (d: number | string | undefined) => void;
  handleSubCategory: (d: number | string | undefined) => void;
  includeNewSubCategory: (d: any) => void;
  handleRecordBtn: (d: any) => void;
  setSubCategoryName: (d: string) => void;
  setSubCatgoryStatus: (d: boolean) => void;
};

export const CategoriesFormSubHooks = ({ ...props }: TCategoriesFormSubHooksProps): TCategoriesFormSubHooksStates => {

  const [subCategoryList, setSubCategoryList] = useState<ISubCategory[]>([]);
  const [idSubCategorySelected, setIdSubCategorySelected] = useState<string | null>(null);

  const [subCategoryName, setSubCategoryName] = useState<string>("");
  const [subCategoryStatus, setSubCatgoryStatus] = useState<boolean>(true);

  useEffect(() => {
    let subCategories: ISubCategory[] | undefined = props.subCategories;
    if (!subCategories || !subCategories.length)
      return;

    setSubCategoryList(subCategories);
    setIdSubCategorySelected(subCategories[0]._id);
    setSubCategoryName(subCategories[0].subCategoryName)
    setSubCatgoryStatus(subCategories[0].status);
  }, []);

  const subCategoriesComponents = [
    { value: subCategoryName, function: setSubCategoryName, tuple: "subCategoryName", _id: "" },
    { value: subCategoryStatus, function: setSubCatgoryStatus, tuple: "status", _id: "" },
  ];

  useEffect(() => {
    if (!idSubCategorySelected)
      return;

    for (let component of subCategoriesComponents) {
      if (component.value || typeof component.value === "boolean") {
        setSubCategoryList(prevList =>
          prevList.map((item) =>
            item._id === idSubCategorySelected
              ? { ...item, [component.tuple]: component.value }
              : item
          )
        );
      };
    };

  }, [
    subCategoryName,
    subCategoryStatus,
    idSubCategorySelected,
  ]);

  function cleanForm() {
    setSubCategoryName("");
    setSubCatgoryStatus(true);
    return;
  };


  function handleSubCategory(_id: number | string | undefined) {
    if (!_id) return cleanForm();

    const index = subCategoryList.findIndex(obj => obj._id === _id);


    let subCategories: ISubCategory[] | undefined = subCategoryList || props.subCategories;
    if (!subCategories || !subCategories.length)
      return;

    setIdSubCategorySelected(subCategories[index]._id);
    setSubCategoryName(subCategories[index].subCategoryName);
    setSubCatgoryStatus(subCategories[index].status);
  };

  function removeSubCategory(_id: number | string | undefined) {
    const newList = subCategoryList.filter(item => item?._id !== _id) ?? [];

    setSubCategoryList(newList);
    handleSubCategory(newList?.length ? newList[0]._id : null);

    toast.success("SubCategoria removida com sucesso!");
  };

  async function includeNewSubCategory(e: any) {
    e.preventDefault();

    const newSubCategory = {
      _id: GENERIC_ID_ + subCategoryList.length?.toString(),
      subCategoryName: "",
      status: true,
      created_at: new Date(),
    } as ISubCategory;

    setIdSubCategorySelected(newSubCategory._id);
    setSubCategoryList([...subCategoryList, newSubCategory]);
    setSubCategoryName(newSubCategory.subCategoryName)
    setSubCatgoryStatus(newSubCategory.status)
  };

  function handleRecordBtn(e: any) {
    e.preventDefault();

    if (!idSubCategorySelected) return;

    const updatedSubCategory = {
      _id: idSubCategorySelected,
      subCategoryName: subCategoryName,
      status: subCategoryStatus,
      created_at: new Date()
    } as ISubCategory;

    const index = subCategoryList.findIndex(address => address._id === idSubCategorySelected);
    if (index === -1) {
      toast.error("SubCategoria selecionada não encontrada na lista.");
      return;
    }

    const updatedSubCategoriesList = [...subCategoryList];
    updatedSubCategoriesList[index] = updatedSubCategory;

    setSubCategoryList(updatedSubCategoriesList);

    const subCategory = updatedSubCategoriesList.map(subCategory => {
      if (subCategory._id && subCategory._id.includes(GENERIC_ID_))
        delete subCategory._id;

      return subCategory;
    });


    props.setSubCategories(subCategory);

    toast.success("A subCategoria informada foi gravada com sucesso!")
  };

  return {
    ...props,
    subCategoryList,
    idSubCategorySelected,
    subCategoryName,
    subCategoryStatus,
    setSubCategoryName,
    setSubCatgoryStatus,
    removeSubCategory,
    handleSubCategory,
    includeNewSubCategory,
    handleRecordBtn,
  };
};