import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { IProduct } from "../interfaces/_index.interfaces";

export async function ProductRegister(product: IProduct) {
  try {
    const response = await api.post("product", product);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetProduct(_id: string) {
  try {
    const response = await api.get("product/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetProductList(item?: any) {
  try {
    let query: string = "";
    if (item?.company) query = `?company=${item.company}`

    const response = await api.get("product" + query)
    const data = response.data.data.product_list


    let newList = [];
    if (Array.isArray(data)) {
      newList = data.map(({ name, _id, ...obj }) => ({
        value: _id,
        label: name,
        name,
        _id,
        ...obj
      }));
    };

    return newList;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function RemoveProduct(_id: string) {
  try {
    const response = await api.delete("product/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateProduct(_id: string, product: any) {
  try {
    const response = await api.put("product/" + _id, product)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateProductPhotos(_id: string, photos: any) {
  try {
    const response = await api.patch("product/" + _id, photos)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TProductsFilter = {
  company?: string;
  category?: string;
  status?: string;
}

export async function GetProductListWithFilter(filter: TProductsFilter) {
  try {
    const response = await api.post("product/filter", filter)
    const data = response.data.data.product_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TGetProductListStockProps = {
  company: string,
  visible?: string;
};

export async function GetProductListStock(item?: TGetProductListStockProps) {
  try {
    let query: string = "";
    if (item?.company) query += `?company=${item.company}`;
    if (item?.visible) query += query ? `&visible=${item.visible}` : `?visible=${item.visible}`;

    const response = await api.get("product/stock" + query)
    const data = response.data.data.product_list

    let newList = [];
    if (Array.isArray(data)) {
      newList = data.map(({ name, _id, ...obj }) => ({
        value: _id,
        label: name,
        name,
        _id,
        ...obj
      }));
    };

    return newList;
  } catch (error: any) {
    toast.error(error.message);
  };
};
