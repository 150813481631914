import { Icons } from "../../assets/icons/_index.icons";

import {
  Button,
  Input,
  Pagination,
  Spinner,
  Image,
  Modal,
} from "../../components/_index.components";
import { RoutesPath } from "../../routes/AppRoutes.routes";
import { GENDER_LIST, USER_STATUS, USER_TYPE, USER_TYPES_LIST, _ID, formatDate } from "../../utils/_index.utils";

import {
  StructurePages,
  TUserPagesHooksStates,
  UserPagesHooks,
  UsersForm,
  tabListUsers
} from "../_index.pages";

export type TUsersPageProps = {};

export default function UsersPage({ ...props }: TUsersPageProps) {

  const states: TUserPagesHooksStates = UserPagesHooks({ ...props } as TUsersPageProps);

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full flex flex-col gap-5 relative overflow-hidden nd:p-0">
              <section className="w-full h-auto inline-flex gap-8 justify-center items-center p-5 nd:p-2">
                <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                  <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                  <Input
                    id={"teste"}
                    name={"search"}
                    placeholder="Pesquisar"
                    type={"text"}
                    setValue={states.setSearchedItem}
                    value={states.serachedItem}
                    style="pl-10"
                  />
                </div>
                <Button
                  icon={Icons.search}
                  text="Filtros"
                  _onClick={() => {
                    states.setFeaturedUser && states.setFeaturedUser(null);

                    return states.toggle && states.toggle()
                  }}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                  customStyleIcon="text-[#26c59f]"
                />
              </section>
              <div className="w-full inline-flex justify-between items-center px-5 nd:p-2">
                <h1 className="text-2xl">
                  <strong>
                    Usuários
                  </strong>
                </h1>
                <Button
                  icon={Icons.register}
                  text="Cadastrar"
                  _onClick={() => {
                    // states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos);
                    states.setFeaturedUser && states.setFeaturedUser(null);
                    states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.userNew);
                  }}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
                  customStyleIcon="text-white"
                />
              </div>
              <div className="w-full h-full gap-2 flex flex-col p-5 nd:p-2">
                {
                  !states.reloadList
                    ? <table className="w-full">
                      <thead className="bg-gray-300">
                        <tr>
                          <th className="px-4 py-2">Nome</th>
                          <th className="px-4 py-2 nd:hidden">Gênero</th>
                          <th className="px-4 py-2 nd:hidden">Aniversário</th>
                          <th className="px-4 py-2">Tipo</th>
                          <th className="px-4 py-2">Status</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {
                          states.usersListDisplayed?.map((item, i) => (
                            <tr
                              className="bg-gray-100 cursor-pointer hover:filter hover:brightness-90 hover:text-[#26c59f] "
                              key={i}
                              onClick={() => {
                                // states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos)
                                states.getUser && states.getUser(item._id);
                                states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.userNew);
                              }}
                            >
                              <td className="px-4 py-2 text-start">{item.name} {item.lastname}</td>
                              <td className="px-4 py-2 nd:hidden">{GENDER_LIST.find(g => g.value === item.gender)?.label ?? "-"}</td>
                              <td className="px-4 py-2 nd:hidden">{formatDate(item.birthday, { onlyDate: true }) ?? "-"}</td>
                              <td className="px-4 py-2">{USER_TYPES_LIST.find(u => u.value === item.type)?.label ?? "-"}</td>
                              <td
                                className={
                                  `${item.status === USER_STATUS.ACTIVE ? "bg-green-300" : "bg-red-400"} 
                                        flex justify-center items-center w-auto min-w-[50%] max-h-[75%] h-auto rounded-md text-white p-1`
                                }
                              >
                                <p>
                                  {
                                    item.status === USER_STATUS.ACTIVE
                                      ? "Ativo"
                                      : "Inativo"
                                  }
                                </p>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    : <Spinner />
                }
              </div>
              {
                (states.usersListDisplayed?.length > 0) &&
                <Pagination
                  itensPerPage={states.itensPerPage}
                  totalItens={states.totalItens}
                  setCurrentPage={states.setCurrentPage}
                  currentPage={states.currentPage}
                />
              }
              {
                states.featuredRoute === RoutesPath.userNew &&
                <div className="w-full h-full max-w-full flex flex-col absolute p-2 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                  <h1 className="text-bold text-3xl">{states.featuredUser?._id ? states.featuredUser?.name : "Novo Usuário"}</h1>
                  <nav className="inline-flex justify-between w-full">
                    <ul className="inline-flex text-gray-400 gap-3">
                      <li>
                        <button
                          onClick={() => {
                            states.setFeaturedUser(null);
                            states.setFeaturedRoute(RoutesPath.users);
                          }}
                        >
                          <span>Usuários</span>
                        </button>
                      </li>
                      <li>&gt;</li>
                      <li className="text-black">{states.featuredUser?._id ? states.featuredUser?.name : "Novo"}</li>
                    </ul>
                    <Button
                      text="Voltar"
                      _onClick={() => {
                        states.setFeaturedUser(null);
                        states.setFeaturedRoute(RoutesPath.users);
                      }}
                      icon={Icons.back}
                    />
                  </nav>
                  <div className="mt-1 z-20 absolute nd:right-2 w-full pr-5 nd:mt-24 nd:pr-0">
                    <div className="inline-flex w-full justify-end pr-20 nd:pr-0">
                      {
                        tabListUsers.map((item, i) => {
                          if (
                            (
                              (states.featuredUser?.type !== USER_TYPE.CUSTOMER) &&
                              (item._id === _ID.tabOrders)
                            ) ||
                            (
                              (states.featuredUser?.type !== USER_TYPE.CUSTOMER) &&
                              (item._id === _ID.tabLGPD)
                            )
                          ) return;


                          return (
                            <div
                              onClick={() => states.setFeaturedTab(item._id)}
                              className={
                                `cursor-pointer hover:text-[#67a0cf] ring-slate-200 ring-1 hover:ring-[#67a0cf] p-2 rounded-t-md
                                ${item._id === states.featuredTab ? "text-[#26c59f]" : "text-primary"}`
                              }
                              key={i}>
                              <p>
                                {
                                  item.nome
                                }
                              </p>
                            </div>
                          )
                        })
                      }
                    </div>
                    <hr className="h-px" />
                  </div>
                  <UsersForm
                    {...states}
                  />
                </div>
              }
            </div>
          </>
        }
      />
    </>
  )
};