import { Form } from "../../../components/_index.components";
import { _ID } from "../../../utils/_index.utils";

import {
  ProductFormStock,
  ProductsFormHooks,
  ProductsFormInfos,
  ProductsFormPackaging,
  ProductsFormPhotos,
  ProductsFormRelated,
  TProductsFormHooksStates,
  TProductsPageHooksStates
} from "../../_index.pages";

export type TProductsFormProps = TProductsPageHooksStates & {};

export default function ProductsForm({ ...props }: TProductsFormProps) {

  const states: TProductsFormHooksStates = ProductsFormHooks({ ...props } as TProductsFormProps);

  return (
    <>
      <Form
        className="w-full h-full max-h-full bg-slate-100 pb-2 nd:mt-10"
        onSubmit={states.handleRecordProduct}
      >
        {
          states.featuredTab === _ID.tabInfos &&
          <ProductsFormInfos {...states} />
        }
        {
          states.featuredTab === _ID.tabPackaging &&
          <ProductsFormPackaging {...states} />
        }
        {
          states.featuredTab === _ID.tabPhotos &&
          <ProductsFormPhotos {...states} />
        }
        {
          states.featuredTab === _ID.tabRelated &&
          <ProductsFormRelated {...states} />
        }
        {
          states.featuredTab === _ID.tabStock &&
          <ProductFormStock {...states} />
        }
      </Form>
    </>
  )
};