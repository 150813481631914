import { Icons } from "../../../assets/icons/_index.icons";
import {
  Button,
  Input,
  Selection
} from "../../../components/_index.components";
import { UNIT_MEASUREMENT_LIST } from "../../../utils/products.utils";

import {
  ProductsFormPackagingHooks,
  TProductsFormHooksStates
} from "../../_index.pages"

import "./Cube.css";

export type TProductsFormPackagingProps = TProductsFormHooksStates & {};

export default function ProductsFormPackaging({ ...props }: TProductsFormPackagingProps) {

  const states: TProductsFormHooksStates = ProductsFormPackagingHooks({ ...props } as TProductsFormPackagingProps);

  return (
    <>
      <div className="w-full max-h-full overflow-auto flex flex-col gap-3">
        <h2 className="font-bold text-xl">Embalagem</h2>
        <div className="inline-flex w-full gap-4">
          <div className="w-1/2 flex flex-col gap-2 ">
            <label htmlFor="product_weight" className="w-auto whitespace-nowrap">Peso total com o produto:</label>
            <Input
              id={"teste"}
              name={"product_weight"}
              type={"text"}
              placeholder="27..."
              setValue={states.setProductWeight}
              value={states.productWeight}
            />
          </div>
          <div className="w-1/2 flex flex-col gap-2 ">
            <label htmlFor="product_unit_measurement" className="w-auto whitespace-nowrap">Unidade de medida:</label>
            <Selection
              optionsList={UNIT_MEASUREMENT_LIST}
              setValue={states.setProductUnitMeasurement}
              value={states.productUnitMeasurement}
            />
          </div>
        </div>
        <div className="inline-flex w-full gap-4">
          <div className="w-1/3 flex flex-col gap-2 ">
            <label htmlFor="product_width" className="w-auto whitespace-nowrap">Largura:</label>
            <Input
              id={"teste"}
              name={"product_width"}
              type={"text"}
              placeholder="30..."
              setValue={states.setProductWidth}
              value={states.productWidth}
            />
          </div>
          <div className="w-1/3 flex flex-col gap-2 ">
            <label htmlFor="product_heigth" className="w-auto whitespace-nowrap">Altura:</label>
            <Input
              id={"teste"}
              name={"product_heigth"}
              type={"text"}
              placeholder="15..."
              setValue={states.setProductHeigth}
              value={states.productHeigth}
            />
          </div>
          <div className="w-1/3 flex flex-col gap-2 ">
            <label htmlFor="product_depth" className="w-auto whitespace-nowrap">Comprimento:</label>
            <Input
              id={"teste"}
              name={"product_depth"}
              type={"text"}
              placeholder="16..."
              setValue={states.setProductDepth}
              value={states.productDepth}
            />
          </div>
        </div>
        <div className="flex justify-center items-center h-screen bg-gray-100">
          <div className="perspective-600 w-52 h-52 relative">
            <div className="w-full h-full relative preserve-3d animate-rotate">
              <div className="absolute w-full h-full bg-white border transform-3d-front flex justify-center items-center">Front</div>
              <div className="absolute w-full h-full bg-white border transform-3d-back flex justify-center items-center">Back</div>
              <div className="absolute w-full h-full bg-white border transform-3d-right flex justify-center items-center">Right</div>
              <div className="absolute w-full h-full bg-white border transform-3d-left flex justify-center items-center">Left</div>
              <div className="absolute w-full h-full bg-white border transform-3d-top flex justify-center items-center">Top</div>
              <div className="absolute w-full h-full bg-white border transform-3d-bottom flex justify-center items-center">Bottom</div>
            </div>
            {/* <div className="w-full h-full relative preserve-3d animate-rotate">
              <div className="depth-edge arestas transform-3d-front"></div>
              <div className="width-edge arestas transform-3d-back"></div>
              <div className="height-edge arestas transform-3d-right"></div>
              <div className="label label-depth transform-3d-left">Profundidade</div>
              <div className="label label-width transform-3d-top">Largura</div>
              <div className="label label-height transform-3d-bottom">Altura</div>
            </div> */}

          </div>
        </div>
        <div className="w-auto h-full justify-end items-end flex p-4 bottom-0">
          <Button
            customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
            customStyleIcon="text-white"
            text="Salvar"
            icon={Icons.save}
            type="submit"
            _onClick={states.handleRecordProduct}
          />
        </div>
      </div>
    </>
  )
};