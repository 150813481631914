import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { ICategory } from "../interfaces/_index.interfaces";

export async function CategoryRegister(category: ICategory) {
  try {
    const response = await api.post("category", category);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetCategory(_id: string) {
  try {
    const response = await api.get("category/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetCategoryList(item?: any) {
  try {
    let query: string = "";
    if (item?.company) query = `?company=${item.company}`

    const response = await api.get("category" + query)
    const data = response.data.data.category_list

    let newList = [];
    if (Array.isArray(data)) {
      newList = data.map(({ name, _id, ...obj }) => ({
        value: _id,
        label: name,
        name,
        _id,
        ...obj
      }));
    };

    return newList;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function RemoveCategory(_id: string) {
  try {
    const response = await api.delete("category/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateCategory(_id: string, category: any) {
  try {
    const response = await api.put("category/" + _id, category)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};


export async function UpdateCategoryEmployees(_id: string, employees: any) {
  try {
    const response = await api.patch("category/" + _id, employees)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};