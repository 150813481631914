

export function identifiesBrightness(corHex: string | undefined) {
  if (!corHex)
    return true;


  const r = parseInt(corHex.substr(1, 2), 16);
  const g = parseInt(corHex.substr(3, 2), 16);
  const b = parseInt(corHex.substr(5), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 130;
};