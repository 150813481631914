import { Icons } from "../../../assets/icons/_index.icons";
import { Button, Input, Selection, TextEditor, Textarea, Toggle } from "../../../components/_index.components";
import { formatCurrency } from "../../../utils/_index.utils";
import { ProductsFormInfosHooks, TProductsFormHooksStates } from "../../_index.pages";

export type TProductsFormInfosProps = TProductsFormHooksStates & {};

export default function ProductsFormInfos({ ...props }: TProductsFormInfosProps) {

  const states = ProductsFormInfosHooks({ ...props } as TProductsFormInfosProps);

  return (
    <>
      <div className="w-full max-h-full overflow-auto flex flex-col gap-3">
        <div className="inline-flex w-full gap-4 nd:flex nd:flex-col">
          <div className="w-1/2 flex flex-col gap-2 nd:w-full">
            <label htmlFor="product_name" className="w-auto whitespace-nowrap">Nome:</label>
            <Input
              id={"teste"}
              name={"product_name"}
              type={"text"}
              // placeholder="Geladeira..."
              setValue={states.setProductName}
              value={states.productName}
            />
          </div>
          <div className="w-1/2 flex flex-col gap-2 nd:w-full">
            <label htmlFor="product_category" className="w-auto whitespace-nowrap">Categoria:</label>
            <Selection
              optionsList={states.categoryList}
              setValue={states.setProductCategory}
              value={states.productCategory}
            />
          </div>
        </div>
        <div className="inline-flex w-full gap-4">
          <div className="w-1/3 flex flex-col gap-2 ">
            <label htmlFor="product_ean" className="w-auto whitespace-nowrap">SKU/EAN:</label>
            <Input
              id={"teste"}
              name={"product_ean"}
              type={"text"}
              // placeholder="SKU5456705..."
              setValue={states.setProductEan}
              value={states.productEan}
            />
          </div>
          <div className="w-1/3 flex flex-col gap-2 ">
            <label htmlFor="product_erp_id" className="w-auto whitespace-nowrap">ERP ID:</label>
            <Input
              id={"teste"}
              name={"product_erp_id"}
              type={"text"}
              // placeholder="ERP_4707645..."
              setValue={states.setProductErpId}
              value={states.productErpId}
            />
          </div>
          <div className="w-1/3 flex flex-col gap-2 ">
            <label htmlFor="product_department" className="w-auto whitespace-nowrap">Departamento:</label>
            <Input
              id={"teste"}
              name={"product_department"}
              type={"text"}
              // placeholder="Construção..."
              setValue={states.setProductDepartment}
              value={states.productDepartment}
            />
          </div>
        </div>
        <div className="inline-flex w-full gap-4 nd:flex nd:flex-col">
          <div className="w-2/4 h-auto flex flex-col gap-4 nd:w-full">
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="product_brand" className="w-auto whitespace-nowrap">Marca:</label>
              <Input
                id={"teste"}
                name={"product_brand"}
                type={"text"}
                // placeholder="Seedz Tech..."
                setValue={states.setProductBrand}
                value={states.productBrand}
              />
            </div>
            <div className="inline-flex gap-3">
              <div className="w-full flex flex-col gap-2 ">
                <label htmlFor="product_real_price" className="w-auto whitespace-nowrap">Valor:</label>
                <Input
                  id={"teste"}
                  name={"product_real_price"}
                  type={"number"}
                  // placeholder="R$ 30,90..."
                  setValue={states.setProductRealPrice}
                  value={states.productRealPrice}
                  currency={true}
                />
              </div>
              <div className="w-full flex flex-col gap-2 ">
                <label htmlFor="product_promotion_price" className="w-auto whitespace-nowrap">Valor Promocional:</label>
                <Input
                  id={"teste"}
                  name={"product_promotion_price"}
                  type={"number"}
                  // placeholder="R$ 28,90..."
                  setValue={states.setProductPromotionPrice}
                  value={states.productPromotionPrice}
                  currency={true}
                />
              </div>
            </div>
            <div className="w-full h-full">
              <div className="w-full flex flex-col gap-2 h-full max-h-full overflow-auto">
                <span className="w-auto whitespace-nowrap">Descrição:</span>
                <TextEditor
                  onChange={states.setProductDescription}
                  value={states.productDescription}

                />
              </div>
            </div>
          </div>
          <div className="w-2/4 h-full nd:w-full">
            <div className="w-full flex flex-col gap-2 h-full max-h-full overflow-auto">
              <span className="w-auto whitespace-nowrap">Informações Técnicas:</span>
              <TextEditor
                onChange={states.setProductTechnicalInformation}
                value={states.productTechnicalInformation}
              />
            </div>
          </div>
        </div>
        <div className="inline-flex gap-4 w-auto h-auto justify-between nd:flex nd:flex-col nd:justify-start">
          <div className="inline-flex gap-20 justify-start nd:flex nd:flex-col nd:gap-3">
            <div className="inline-flex gap-5 items-center justify-center nd:justify-end">
              <span className="whitespace-nowrap">Produto em Destaque</span>
              <Toggle
                setToggleStatus={states.setProductEmphasis}
                toggleStatus={states.productEmphasis}
              />
            </div>
            <div className="inline-flex gap-5 items-center justify-center nd:justify-end">
              <span className="whitespace-nowrap">Produto Visivel</span>
              <Toggle
                setToggleStatus={states.setProductVisible}
                toggleStatus={states.productVisible}
              />
            </div>
          </div>
          <div className="w-auto h-full justify-end items-end flex p-4 bottom-0">
            <Button
              customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
              customStyleIcon="text-white"
              text="Salvar"
              icon={Icons.save}
              type="submit"
              _onClick={states.handleRecordProduct}
            />
          </div>
        </div>
      </div>
    </>
  )
};