import { useEffect, useRef, useState } from "react";
import { TCategoriesFormProps } from "../CategoriesForm";
import { ICategory, ISubCategory } from "../../../../interfaces/_index.interfaces";
import { toast } from "react-toastify";
import { CategoryRegister, UpdateCategory } from "../../../../services/categories.services";
import { MediaRegister } from "../../../../services/_index.services";
import { montaArquivo, montaArquivoResumido } from "../../../../utils/anexos.utils";

type TCategoriesFormHooksProps = TCategoriesFormProps & {}
export type TCategoriesFormHooksStates = TCategoriesFormHooksProps & {
  categoryName: string;
  categoryTitle: string;
  categoryDescription: string;
  categoryStatus: boolean;
  // subCategoryName: string;
  // subCategoryStatus: boolean;
  categoryImage: any;
  categoryFeatured: ICategory;
  fileRef: any;
  fileKey: number;
  imageUrl: string;
  subCategories: ISubCategory[];
  uploadingImg: boolean;
  setUploadingImg: (d: boolean) => void;
  setSubCategories: (d: ISubCategory[]) => void;
  setFileKey: (d: number) => void;
  setCategoryName: (d: string) => void;
  setCatgoryTitle: (d: string) => void;
  setCatgoryDescription: (d: string) => void;
  setCatgoryStatus: (d: boolean) => void;
  // setSubCategoryName: (d: string) => void;
  // setSubCatgoryStatus: (d: boolean) => void;
  setCatgoryImage: (d: any) => void;
  setCategoryFeatured: (d: ICategory) => void;
  handleFile: () => void;
  handleRecordCategory: () => void;
};

export const CategoriesFormHooks = ({ ...props }: TCategoriesFormHooksProps): TCategoriesFormHooksStates => {

  const [categoryName, setCategoryName] = useState<string>("");
  const [categoryTitle, setCatgoryTitle] = useState<string>("");
  const [categoryDescription, setCatgoryDescription] = useState<string>("");
  const [categoryStatus, setCatgoryStatus] = useState<boolean>(true);
  const [categoryImage, setCatgoryImage] = useState<any>(null);

  // const [subCategoryName, setSubCategoryName] = useState<string>("");
  // const [subCategoryStatus, setSubCatgoryStatus] = useState<boolean>(true);

  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);

  const [objImg, setObjImg] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>(null);

  const [categoryFeatured, setCategoryFeatured] = useState<ICategory>(null);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const [fileKey, setFileKey] = useState<number>(0);
  const [uploadingImg, setUploadingImg] = useState<boolean>(false);


  useEffect(() => {
    let item: ICategory | null = props.featuredCategory;
    if (!item) return;

    setCategoryName(item.name);
    setCatgoryTitle(item.title);
    setCatgoryDescription(item.description);
    setCatgoryStatus(item.visible);
    setSubCategories(item.subCategory);
    setObjImg(item.image);
    // setCatgoryImage(item.image);

  }, [props.featuredCategory]);

  useEffect(() => {
    if (!objImg)
      return;

    let fileName = objImg.imageUrl;

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${fileName}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }, [objImg]);


  useEffect(() => {
    if (!categoryImage || !props.featuredCategory?._id)
      return;

    setUploadingImg(true);
    const form = new FormData();
    form.append("file", categoryImage);
    form.append("category", props.featuredCategory?._id);
    form.append("company", props.featuredCategory?.company);

    async function uploadMedia() {
      const resp = await MediaRegister(form);

      let category = props.featuredCategory;
      category.image = resp._id;
      delete category.isEmphasis;
      await UpdateCategory(props.featuredCategory?._id, category);

      if (resp) {
        setObjImg(resp);
        setFileKey(fileKey + 1);
        props.setReloadList(true);
      }
    };

    uploadMedia();
  }, [
    categoryImage
  ]);

  function handleFile() {
    if (fileRef.current) {
      fileRef.current.click();
    };
  };

  async function handleRecordCategory() {
    const obj = {
      _id: props.featuredCategory?._id,
      description: categoryDescription,
      name: categoryName,
      visible: categoryStatus,
      title: categoryTitle,
      image: props.featuredCategory?.image ?? null,
      subCategory: subCategories,
      company: props.authState?.user?.company,
    } as ICategory;

    let resp: any;
    if (!obj._id) {
      delete obj._id;

      resp = await CategoryRegister(obj);

    } else
      resp = await UpdateCategory(obj._id, obj);

    toast.success(resp?.message);
    props.setReloadList(true);
    props.close();


  };

  return {
    ...props,
    categoryName,
    categoryTitle,
    categoryDescription,
    categoryStatus,
    categoryImage,
    categoryFeatured,
    fileRef,
    fileKey,
    imageUrl,
    // subCategoryName,
    // subCategoryStatus,
    // setSubCatgoryStatus,
    // setSubCategoryName,
    subCategories,
    uploadingImg,
    setUploadingImg,
    setSubCategories,
    setFileKey,
    setCategoryName,
    setCatgoryTitle,
    setCatgoryDescription,
    setCatgoryStatus,
    setCatgoryImage,
    setCategoryFeatured,
    handleFile,
    handleRecordCategory,
  };
};