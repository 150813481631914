import { useEffect, useState } from "react";
import { GENERIC_ID_, getCoordinatesFromZipcode } from "../../../../utils/_index.utils";
import { TFormAddressProps } from "../FormAddress";
import { IAddress } from "../../../../interfaces/IAddress.i";
import { toast } from "react-toastify";


type TFormAddressHooksProps = TFormAddressProps & {};
export type TFormAddressHooksStates = TFormAddressHooksProps & {
  toMakeAvaible: boolean;
  postalAddressCode: string;
  nickAddress: string;
  streetAddress: string;
  numberAddress: string;
  complementAddress: string;
  cityAddress: string;
  stateAddress: string;
  countryAddress: string;
  addressesList: IAddress[];
  addressSelected: number | string | undefined;
  fullAddress: any;
  setToMakeAvaible: (data: boolean) => void;
  setPostaAddressCode: (data: string) => void;
  setNickAddress: (data: string) => void;
  setStreetAddress: (data: string) => void;
  setNumberAddress: (data: string) => void;
  setComplementAddress: (data: string) => void;
  setCityAddress: (data: string) => void;
  setStateAddress: (data: string) => void;
  setCountryAddress: (data: string) => void;
  setAddressesList: (data: IAddress[]) => void;
  setAddressSelected: (data: number | string | undefined) => void;
  setFullAddress: (data: any) => void;
  handleAddress: (data: number | string | undefined | null) => void;
  removeAddress: (data: number | string | undefined) => void;
  handleRecordBtn: () => void;
  includeNewAddress: () => void;
}


export const FormAddressHooks = ({ ...props }: TFormAddressHooksProps): TFormAddressHooksStates => {
  const [toMakeAvaible, setToMakeAvaible] = useState<boolean>(false);
  const [postalAddressCode, setPostaAddressCode] = useState<string>("");
  const [nickAddress, setNickAddress] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [numberAddress, setNumberAddress] = useState<string>("");
  const [complementAddress, setComplementAddress] = useState<string>("");
  const [cityAddress, setCityAddress] = useState<string>("");
  const [stateAddress, setStateAddress] = useState<string>("");
  const [countryAddress, setCountryAddress] = useState<string>("");

  const [addressesList, setAddressesList] = useState<IAddress[]>([]);
  const [idAddressSelected, setIdAddressSelected] = useState<number | string | undefined>();

  const [fullAddress, setFullAddress] = useState<any>();

  useEffect(() => {
    async function getFullAddress() {
      const completedAddress = await getCoordinatesFromZipcode(postalAddressCode);

      setFullAddress(completedAddress);
    };

    if (postalAddressCode && postalAddressCode.length > 7) {
      getFullAddress();
    };
  }, [postalAddressCode])

  useEffect(() => {
    if (fullAddress) {
      setStreetAddress(fullAddress.logradouro)
      setCityAddress(fullAddress.localidade)
      setStateAddress(fullAddress.uf)
    };
  }, [fullAddress]);

  useEffect(() => {
    let address: IAddress[] | undefined = props.companyAddress;
    if (!address || !address.length)
      return;

    setAddressesList(address);
    setIdAddressSelected(address[0]._id);
    setToMakeAvaible(address[0].make_avaible)
    setPostaAddressCode(address[0].zipcode)
    setNickAddress(address[0].name_address)
    setStreetAddress(address[0].street)
    setNumberAddress(address[0].number)
    setComplementAddress(address[0].complement)
    setCityAddress(address[0].city)
    setStateAddress(address[0].state)
    setCountryAddress(address[0].country)

  }, []);

  const addressComponents = [
    { value: toMakeAvaible, function: setToMakeAvaible, tuple: "make_avaible", _id: "" },
    { value: postalAddressCode, function: setPostaAddressCode, tuple: "zipcode", _id: "" },
    { value: nickAddress, function: setNickAddress, tuple: "name_address", _id: "" },
    { value: streetAddress, function: setStreetAddress, tuple: "street", _id: "" },
    { value: numberAddress, function: setNumberAddress, tuple: "number", _id: "" },
    { value: complementAddress, function: setComplementAddress, tuple: "complement", _id: "" },
    { value: cityAddress, function: setCityAddress, tuple: "city", _id: "" },
    { value: stateAddress, function: setStateAddress, tuple: "state", _id: "" },
    { value: countryAddress, function: setCountryAddress, tuple: "country", _id: "" }
  ];

  useEffect(() => {
    if (!idAddressSelected)
      return;

    for (let component of addressComponents) {
      if (component.value || typeof component.value === "boolean") {
        setAddressesList(prevList =>
          prevList.map((item) =>
            item._id === idAddressSelected
              ? { ...item, [component.tuple]: component.value }
              : item
          )
        );
      };
    };

  }, [
    toMakeAvaible,
    postalAddressCode,
    nickAddress,
    streetAddress,
    numberAddress,
    complementAddress,
    cityAddress,
    stateAddress,
    countryAddress,
    idAddressSelected,
  ]);

  function cleanForm() {
    setToMakeAvaible(false)
    setPostaAddressCode("")
    setNickAddress("")
    setStreetAddress("")
    setNumberAddress("")
    setComplementAddress("")
    setCityAddress("")
    setStateAddress("")
    setCountryAddress("")

    return;
  };

  function handleAddress(_id: number | string | undefined | null) {
    if (!_id) return cleanForm();

    const index = addressesList.findIndex(obj => obj._id === _id);


    let address: IAddress[] | undefined = addressesList || props.companyAddress;
    if (!address || !address.length)
      return;

    setIdAddressSelected(address[index]._id);
    setToMakeAvaible(address[index].make_avaible)
    setPostaAddressCode(address[index].zipcode)
    setNickAddress(address[index].name_address)
    setStreetAddress(address[index].street)
    setNumberAddress(address[index].number)
    setComplementAddress(address[index].complement)
    setCityAddress(address[index].city)
    setStateAddress(address[index].state)
    setCountryAddress(address[index].country)
  };

  function removeAddress(_id: number | string | undefined) {
    const newList = addressesList.filter(item => item?._id !== _id) ?? [];

    setAddressesList(newList);
    handleAddress(newList?.length ? newList[0]._id : null);

    toast.success("Endereço foi foi removido com sucesso!");
  };

  function handleRecordBtn() {
    if (!idAddressSelected) return;

    const updatedAddress = {
      _id: idAddressSelected,
      city: cityAddress,
      complement: complementAddress,
      country: countryAddress,
      make_avaible: toMakeAvaible,
      name_address: nickAddress,
      number: numberAddress,
      state: stateAddress,
      street: streetAddress,
      zipcode: postalAddressCode,
      created_at: new Date()
    } as IAddress;

    const index = addressesList.findIndex(address => address._id === idAddressSelected);
    if (index === -1) {
      toast.error("Endereço selecionado não encontrado na lista.");
      return;
    }

    const updatedAddressesList = [...addressesList];
    updatedAddressesList[index] = updatedAddress;

    setAddressesList(updatedAddressesList);

    const address = updatedAddressesList.map(address => {
      if (address._id && address._id.includes(GENERIC_ID_))
        delete address._id;

      return address;
    });


    props.setCompanyAddress(address);

    toast.success("O endereço informado foi gravado com sucesso!")
  }


  function includeNewAddress() {
    const newAddress = {
      _id: GENERIC_ID_ + addressesList.length?.toString(),
      zipcode: "",
      make_avaible: false,
      name_address: "",
      street: "",
      number: "",
      complement: "",
      city: "",
      state: "",
      country: "",
      created_at: new Date(),
    } as IAddress;

    setIdAddressSelected(newAddress._id);
    setAddressesList([...addressesList, newAddress]);
    setToMakeAvaible(newAddress.make_avaible)
    setPostaAddressCode(newAddress.zipcode)
    setNickAddress(newAddress.name_address)
    setStreetAddress(newAddress.street)
    setNumberAddress(newAddress.number)
    setComplementAddress(newAddress.complement)
    setCityAddress(newAddress.city)
    setStateAddress(newAddress.state)
    setCountryAddress(newAddress.country)
  };


  return {
    ...props,
    toMakeAvaible,
    postalAddressCode,
    nickAddress,
    streetAddress,
    numberAddress,
    complementAddress,
    cityAddress,
    stateAddress,
    countryAddress,
    addressesList,
    addressSelected: idAddressSelected,
    fullAddress,
    setToMakeAvaible,
    setPostaAddressCode,
    setNickAddress,
    setStreetAddress,
    setNumberAddress,
    setComplementAddress,
    setCityAddress,
    setStateAddress,
    setCountryAddress,
    setAddressesList,
    setAddressSelected: setIdAddressSelected,
    setFullAddress,
    handleAddress,
    removeAddress,
    handleRecordBtn,
    includeNewAddress,
  }
};