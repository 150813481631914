export type TFormProps = {
  action?: any;
  method?: any;
  onSubmit: (data: any) => void;
  children: any;
  className: string;
};

export default function Form(props: TFormProps) {

  return (
    <form
      className={props.className}
      action={props.action}
      method={props.method}
      onSubmit={props.onSubmit}
    >
      {
        props.children
      }
    </form>
  );
};