export function fieldsWithoutValue(object: any) {
  if (!object) {
    return;
  };

  const invalidFields = [];
  function traverseObject(obj: any, path: string) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const field = path ? `${path}.${key}` : key;

        if (typeof value === 'object' && value !== null) {
          traverseObject(value, field);
        } else if (!value && value !== 0) {
          invalidFields.push(field);
        }
      }
    }
  };

  traverseObject(object, '');
  return invalidFields?.length ? invalidFields : undefined;
};

export type TValidationRequiredFields = {
  objForValidation: object;
  requiredFieldsList: {
    parameter: string;
    setFunction: (data: boolean) => void;
  }[];
};

export function validateRequiredFields(fields: TValidationRequiredFields) {
  let fieldInvalidated: boolean = false;

  const _fieldsWithoutValue = fieldsWithoutValue(fields.objForValidation);
  if (_fieldsWithoutValue) {
    for (let field of _fieldsWithoutValue) {
      const item = fields.requiredFieldsList.find(
        obj => obj.parameter.toLowerCase() === field.toLowerCase()
      );

      if (item) {
        item.setFunction(true);
        fieldInvalidated = true;
      };
    };

    return fieldInvalidated;
  };
};
