import { TProductsFormRelatedProps } from "../ProductsFormRelated";


type TProductsFormRelatedHooksProps = TProductsFormRelatedProps & {};
export type TProductsFormRelatedHooksStates = TProductsFormRelatedHooksProps & {

};

export const ProductsFormRelatedHooks = ({ ...props }: TProductsFormRelatedHooksProps): TProductsFormRelatedHooksStates => {


  return {
    ...props,
  };
};