import StructurePages from "../StructurePages";
import ImgNoImageAvaible from "../../assets/img/noImage.png";

import { DashboardPagesHooks, TDashboardPagesHooksStates } from "./_index.dashboard";

import { Bar, Line, Pie } from 'react-chartjs-2';

import { Image, Input } from "../../components/_index.components";

export type TDashboardPagesProps = {}

export default function DashboardPages({ ...props }: TDashboardPagesProps) {

  const states: TDashboardPagesHooksStates = DashboardPagesHooks({ ...props } as TDashboardPagesProps);

  return (
    <>
      <StructurePages

        module={
          <>
            <div className="w-full h-full p-5 flex flex-col gap-16 nd:p-2">
              <h1 className="text-3xl">Dashboard </h1>
              <section className="w-full grid grid-cols-2 nd:flex nd:flex-col -mt-12">
                <div className="max-w-[20vw] max-h-[40vh] nd:hidden">
                  {
                    states.company?.image
                      ? <Image
                        path={process.env.REACT_APP_BUCKET_URL + states.company?.image?.imageThumbUrl}
                      />
                      : <Image path={ImgNoImageAvaible} />
                  }
                </div>
                <div className="flex flex-col gap-8 nd:flex nd:flex-col w-full">
                  <div className="inline-flex gap-5 nd:mt-10">
                    <div className="w-full flex flex-col gap-2">
                      <label htmlFor="initDate" className="w-auto whitespace-nowrap">Data Inicial:</label>
                      <Input
                        id={"initDate"}
                        name={"initDate"}
                        type={"date"}
                        setValue={states.setInitDatePeriod}
                        value={states.initDatePeriod}
                      />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label htmlFor="finalDtae" className="w-auto whitespace-nowrap">Data Final:</label>
                      <Input
                        id={"finalDtae"}
                        name={"finalDtae"}
                        type={"date"}
                        setValue={states.setEndDatePriod}
                        value={states.endDatePeriod}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-8 nd:flex nd:flex-col">
                    <div className="inline-flex gap-8 w-full nd:flex nd:flex-col">
                      <div className="flex flex-col gap-2">
                        <label htmlFor="totalProducts">Total de produtos</label>
                        <Input
                          id={"teste"}
                          name={"totalProducts"}
                          type={"number"}
                          readonly={true}
                          style="text-2xl font-bold text-center"
                          setValue={() => null}
                          value={states.totalProductPeriod?.toString()}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="totalCash">Total faturado do período</label>
                        <Input
                          id={"teste"}
                          name={"totalCash"}
                          type={"text"}
                          readonly={true}
                          style="text-2xl font-bold"
                          setValue={() => null}
                          value={states.totalPeriod}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="totalExpense">Total de despesa do período</label>
                        <Input
                          id={"teste"}
                          name={"totalExpense"}
                          type={"text"}
                          readonly={true}
                          style="text-2xl font-bold text-red-700"
                          setValue={() => null}
                          value={states.totalExpensePeriod}
                        />
                      </div>
                    </div>
                    <div className="inline-flex gap-8 w-full nd:flex nd:flex-col">
                      <div className="flex flex-col gap-2">
                        <label htmlFor="totalRecived">Total já recebido</label>
                        <Input
                          id={"teste"}
                          name={"totalRecived"}
                          type={"text"}
                          readonly={true}
                          style="text-2xl font-bold text-green-500"
                          setValue={() => null}
                          value={states.totalRecived}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="totalDue">Total à receber</label>
                        <Input
                          id={"teste"}
                          name={"totalDue"}
                          type={"text"}
                          readonly={true}
                          style="text-2xl font-bold text-blue-500"
                          setValue={() => null}
                          value={states.totalDue}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="percentProfit">Porcentagem de lucro do período</label>
                        <Input
                          id={"teste"}
                          name={"percentProfit"}
                          type={"text"}
                          readonly={true}
                          style="text-2xl font-bold text-orange-600 text-center"
                          setValue={() => null}
                          value={states.profitPercentPeriod}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </section>

              <section className="flex flex-col w-full gap-20">
                <div className="inline-flex w-full gap-8 nd:flex nd:flex-col">
                  <div className="w-1/2 nd:hidden">
                    <Bar data={states.dataProducts} options={states.optionsProducts} />
                  </div>
                  <div className="w-1/2 max-h-[45vh] flex justify-center nd:w-full nd:max-h-[100vh]">
                    <Pie data={states.dataRevenues} options={states.optionsRevenues} />
                  </div>
                </div>
                <div className="inline-flex w-full gap-8 nd:flex nd:flex-col nd:gap-8">
                  <div className="w-1/2 max-h-[45vh] flex justify-center nd:w-full nd:max-h-[100vh]">
                    <Bar data={states.dataInputs} options={states.optionsInputs} />
                  </div>
                  <div className="w-1/2 max-h-[45vh] flex justify-center nd:w-full nd:max-h-[100vh]">
                    <Line data={states.dataSellingXInputs} options={states.optionsCompareInputsXSell} />
                  </div>
                </div>
                <div className="inline-flex w-full gap-8 nd:hidden">
                  <div className="w-1/2">
                    <Bar data={states.dataUser} options={states.optionsUser} />
                  </div>
                  <div className="w-1/2">
                    <Bar data={states.data} options={states.options} />
                  </div>
                </div>

              </section>


            </div>
          </>
        }
      />
    </>
  )
}