

export const _ID = {
  tabInfos: "tabInfos",
  tabAddress: "tabAddress",
  tabLayout: "tabLayout",
  tabEmployees: "tabEmployees",
  tabProducts: "tabProducts",
  tabSubCategory: "tabSubCategory",
  tabPackaging: "tabPackaging",
  tabPhotos: "tabPhotos",
  tabRelated: "tabRelated",
  tabStock: "tabStock",
  tabOrders: "tabOrders",
  tabLGPD: "tabLGPD",
  tabBanner: "tabBanner",
  tabPayments: "tabPayments",
  tabNotes: "tabNotes",
  tabReceipt: "tabReceipt",
  moduleDashboard: "moduleDashboard",
  moduleCompany: "moduleCompany",
  moduleCategories: "moduleCategories",
  moduleProducts: "moduleProducts",
  moduleOrders: "moduleOrders",
  moduleStock: "moduleStock",
  moduleUsers: "moduleUsers",
  moduleSubscribers: "moduleSubscribers",
  moduleInputs: "moduleInputs",
  pageIndex: "pageIndex-",
}