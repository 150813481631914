import { Icons } from "../../../assets/icons/_index.icons";
import { formatDate } from "../../../utils/_index.utils";
import { TProductsFormHooksStates } from "../../_index.pages";

import {
  ProductsFormPhotosHooks,
  TProductsFormPhotosHooksStates
} from "./Hooks/ProductsFormPhotosHooks";

import {
  Image,
  Spinner,
} from "../../../components/_index.components";

export type TProductsFormPhotosProps = TProductsFormHooksStates & {};

export default function ProductsFormPhotos({ ...props }: TProductsFormPhotosProps) {

  const states: TProductsFormPhotosHooksStates = ProductsFormPhotosHooks({ ...props } as TProductsFormPhotosProps)

  return (
    <>
      <div className="inline-flex rounded-md w-full h-full pb-2 gap-4 nd:flex nd:flex-col">
        <div className="flex flex-col w-[65%] h-full ring-1 ring-slate-200 p-2 rounded-md relative nd:w-full">
          {
            !states.uploadingImg
              ? states.imageUrl &&
              <>
                <div
                  className={
                    `absolute right-4 cursor-pointer top-4 h-[4vh] w-auto inline-flex rounded-lg 
                    space-x-1 justify-center items-center p-1 px-5 text-[#a53e3e] ring-1 ring-[#a53e3e]`
                  }
                  onClick={() => states.removeProductPhoto(states.idProductPhotoSelected)}
                >
                  <Icons.delete
                    className="m-2 text-[#a53e3e]"
                    size={15}
                  />
                  <span>Excluir</span>
                </div>
                <Image
                  path={states.imageUrl}
                />
              </>

              : <Spinner />
          }
        </div>
        <div className="flex flex-col w-[35%] h-full ring-1 ring-slate-200 p-2 rounded-md nd:w-full">
          <div className="flex flex-col gap-2">
            <div className="inline-flex justify-between w-full items-center">
              <h3>Fotos</h3>
              <div
                className="w-auto cursor-pointer"
                // onClick={states.includeNewPhoto}>
                onClick={states.handleFile}>
                <Icons.register className="text-[#26c59f]" size={30} />
                <input
                  type="file"
                  hidden
                  ref={states.fileRef}
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      states.setNewProductPhoto(files[0]);
                      states.setFileKey(states.fileKey + 1)
                    }
                  }}
                // id={ID.company_file}
                />
              </div>
            </div>
            <hr className="h-px w-full" />
          </div>
          <div className="w-full grid grid-cols-4 p-2 gap-2 nd:grid-cols-2">
            {
              states.productPhotosList.length > 0 &&
              states.productPhotosList
                ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                ?.map((item, i) => (
                  <div
                    onClick={() => states.handleProductPhoto(item)}
                    key={i}
                    className={
                      `flex flex-col gap-2 w-full bg-slate-200 rounded-md h-[15vh] cursor-pointer p-1 justify-start items-center 
                      hover:filter hover:brightness-90 text-[#26c59f]
                    ${item?._id === states.idProductPhotoSelected ? "filter brightness-50" : null}`
                    }>
                    <div className="w-full min-w-full h-[15vh] max-h-[100px] rounded-md ring-2 ring-white bg-white">
                      {
                        item?.imageThumbUrl &&
                        <Image
                          path={process.env.REACT_APP_BUCKET_URL + item?.imageThumbUrl}
                        />
                      }
                    </div>
                    <p>{formatDate(item.created_at ?? new Date(), { onlyDate: true })}</p>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}