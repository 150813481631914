import { GiShoppingCart } from "react-icons/gi";

import {
  FaCheckCircle,
  FaRegSave,
  FaShareAlt,
  FaStreetView,
  FaUserCircle
} from "react-icons/fa";

import {
  RiCustomerService2Line,
  RiSubtractFill
} from "react-icons/ri";

import {
  BsInstagram,
  BsWhatsapp,
  BsFacebook,
  BsToggle2Off,
  BsToggle2On,
  BsGraphUpArrow,
  BsGift,
  BsToggleOn,
  BsToggleOff,
  BsBoxes,
} from "react-icons/bs";

import {
  GrFormNext,
  GrFormPrevious,
  GrLanguage
} from "react-icons/gr";

import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosSearch
} from "react-icons/io";


import {
  FiLogOut,
} from "react-icons/fi";

import { DiYii } from "react-icons/di";
import { IoBusinessOutline } from "react-icons/io5";

import {
  FaGear,
  FaPencil,
  FaUserLarge,
  FaUsersGear
} from "react-icons/fa6";

import { HiOutlineTrash } from "react-icons/hi";

import { SiElementary } from "react-icons/si";

import {
  MdClose,
  MdEdit,
  MdFavorite,
  MdOutlineRequestPage
} from "react-icons/md";

import {
  BiCategoryAlt,
  BiPlus
} from "react-icons/bi";

import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillSignature,
  AiOutlineCloudUpload
} from "react-icons/ai";

import { GoAlertFill } from "react-icons/go";

import {
  TiArrowBack
} from "react-icons/ti";

export const Icons = {
  shoppingCart: GiShoppingCart,
  profile: FaUserCircle,
  services: RiCustomerService2Line,
  next: GrFormNext,
  previous: GrFormPrevious,
  instagram: BsInstagram,
  whatsapp: BsWhatsapp,
  facebook: BsFacebook,
  retract: IoIosArrowBack,
  expand: IoIosArrowForward,
  language: GrLanguage,
  logout: FiLogOut,
  toggleOFF: BsToggle2Off,
  toggleON: BsToggle2On,
  ecommerce: DiYii,
  dashboard: BsGraphUpArrow,
  company: IoBusinessOutline,
  category: BiCategoryAlt,
  product: BsGift,
  user: FaUserLarge,
  upload: AiOutlineCloudUpload,
  register: BiPlus,
  close: MdClose,
  edit: MdEdit,
  street: FaStreetView,
  delete: HiOutlineTrash,
  save: FaRegSave,
  toggleOn: BsToggleOn,
  toggleOff: BsToggleOff,
  orders: MdOutlineRequestPage,
  stock: BsBoxes,
  subCategory: SiElementary,
  search: IoIosSearch,
  subtract: RiSubtractFill,
  eyeInvisible: AiFillEyeInvisible,
  eyeVisible: AiFillEye,
  favorite: MdFavorite,
  subscribers: AiFillSignature,
  check: FaCheckCircle,
  alert: GoAlertFill,
  input: GiShoppingCart,
  back: TiArrowBack,
  note: FaPencil,
  share: FaShareAlt,
  configs: FaGear,
  permissions: FaUsersGear,
};