import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// import ImgLogo from "../../assets/img/logo.png";
import ImgLogo from "../../assets/img/logoGergelim.svg";
import ImgLogin from "../../assets/img/login.jpg";

import { Icons } from '../../assets/icons/_index.icons';
import { useAuth } from '../_index.pages';
import { Spinner } from '../../components/_index.components';
import { RoutesPath } from '../../routes/AppRoutes.routes';

export default function LoginPage() {


  const { authState, login, loading } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [inEmail, setInEmail] = useState<boolean>(false);
  const [inPassword, setInPassword] = useState<boolean>(false);

  const [fieldType, setFieldType] = useState<string>("password");

  async function handleLogin(e: any) {
    e.preventDefault();

    const fields = [email, password];
    for (let i = 0; i < fields.length; i++) {
      let invalidField = false;

      if (!fields[0]) {
        setInEmail(true);
        invalidField = true
      };

      if (!fields[1]) {
        setInPassword(true);
        invalidField = true
      }

      if (invalidField) return toast.warning("Verifique os campos e tente novamente");
    }

    await login(email, password);
  };

  if (loading)
    return <Spinner />

  if (authState.isAuthenticated)
    return <Navigate to={RoutesPath.dashboard} replace={true} />;

  return (
    <div className='w-screen h-screen relative'>
      <img
        className="w-full h-full filter brightness-90"
        src={ImgLogin}
      />
      <form
        className='flex flex-col justify-center items-center bg-none w-full h-full p-20 nd:p-2 md:p-2 lg:p-2 absolute z-50 top-0 left-0'
        onSubmit={handleLogin}
      >
        <img
          className='w-[40%] md:w-[20%] lg:w-[20%] -mb-28 xl:hidden 2xl:hidden z-20 absolute right-17 top-10'
          src={ImgLogo}
        />
        <div
          className='flex grid-cols-2 nd:grid-cols-none md:grid-cols-none lg:grid-cols-none w-full h-full rounded-lg'
          style={{
            boxShadow: "0px 1px 4px 0px rgba(31, 23, 23, 0.25)",
            backdropFilter: "blur(5.5px)",
          }}
        >
          <div className="flex flex-col justify-center items-center bg-sec-light-background w-full rounded-l-md gap-32 py-80 nd:hidden md:hidden lg:hidden">
            <img
              className='w-[40%] -mb-28'
              src={ImgLogo}
            />
            <hr className='h-full' />
            <h1>
              <span className="text-2xl font-bold text-[#26c59f] xl:text-xl">Plantando inovação, colhendo o futuro</span>
              <br />
              <span className="text-5xl font-bold text-[#26c59f] xl:text-4xl">Tecnologia em cada semente</span>
            </h1>
          </div>
          <div className='flex xl:flex-col 2xl:flex-col justify-center items-center bg-light-background w-full rounded-r-md'>
            <div className='flex flex-col xl:w-[75%] w-[50%] nd:w-full md:w-full lg:w-full'>
              <div className='w-full flex text-center justify-center text-2xl'>
                <p className='text-[#26c59f] whitespace-nowrap nd:text-lg md:text-lg lg:text-lg'>
                  <strong>
                    Sistema de Gerenciamento de Conteúdo
                  </strong>
                </p>
              </div>
              <div className='flex flex-col w-full justify-center items-center p-6'>
                <input
                  className={`rounded-md px-2 w-full h-[5vh] ${inEmail ? "ring-2 ring-[#a53e3e]" : "ring-0"}`}
                  placeholder='Digite seu e-mail...'
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                    setInEmail(false);
                  }}
                />
                <div className='w-full h-[5vh] relative'>
                  <input
                    className={`rounded-md px-2 w-full mt-2 h-full ${inPassword ? "ring-2 ring-[#a53e3e]" : "ring-0"}`}
                    placeholder='Digite seu senha...'
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setInPassword(false);
                    }}
                    name="senha"
                    type={fieldType}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        handleLogin(e);
                      }
                    }}
                    style={{
                      borderRadius: "0.625rem",
                    }}
                  />
                  {fieldType === "password" ? (
                    <Icons.eyeInvisible
                      size={20}
                      className="absolute right-2 xl:top-4 top-6 cursor-pointer"
                      onClick={() =>
                        setFieldType((prevEstado: any) =>
                          prevEstado === "password"
                            ? "text"
                            : "password"
                        )
                      }
                    />
                  ) : (
                    <Icons.eyeVisible
                      size={20}
                      className="absolute right-2 top-6 cursor-pointer"
                      onClick={() =>
                        setFieldType((prevEstado: any) =>
                          prevEstado === "password"
                            ? "text"
                            : "password"
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className='flex justify-between items-center px-6 gap-6'>
                <button className="text-white w-auto">
                  <p className='text-blue-400 whitespace-nowrap'>Esqueci a senha</p>
                </button>
                <button
                  className="w-auto rounded-full bg-[#26c59f] p-1 px-10 text-white h-auto min-h-[5vh] flex justify-center items-center"
                  type="submit"
                >
                  <p className='text-lg'>Login</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};