
export const MODULES = {
  dashboard: "Dashboard",
  company: "Empresa",
  products: "Produtos",
  categories: "Categorias",
  users: "Usuários",
  orders: "Pedidos",
  stock: "Estoque",
  subscribers: "Assinantes",
  inputs: "Insumos",
};