import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { IImage } from "../../../../interfaces/IImage.i";

import {
  MediaRegister,
  RemoveMedia,
  UpdateCompanyBanner
} from "../../../../services/_index.services";

import { TCompanyFormBannerProps } from "../CompanyFormBanner";

type TCompanyFormBannerHooksProps = TCompanyFormBannerProps & {};
export type TCompanyFormBannerHooksStates = TCompanyFormBannerHooksProps & {
  companyBannerList: IImage[];
  idCompanyBannerSelected: string | null;
  uploadingImg: boolean;
  imageUrl: string | null;
  fileKey: number;
  fileRef: any;
  setCompanyBannerList: (d: IImage[]) => void;
  setIdCompanyBannerSelected: (d: string | null) => void;
  removeCompanyBanner: (d: string) => void;
  handleCompanyBanner: (d: IImage) => void;
  setNewCompanyBanner: (d: any) => void;
  setFileKey: (d: number) => void;
  handleFile: () => void;
};

export const CompanyFormBannerHooks = ({ ...props }: TCompanyFormBannerHooksProps): TCompanyFormBannerHooksStates => {
  const [companyBannerList, setCompanyBannerList] = useState<IImage[]>([]);
  const [idCompanyBannerSelected, setIdCompanyBannerSelected] = useState<string | null>(null);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const [objImg, setObjImg] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>(null);

  const [fileKey, setFileKey] = useState<number>(0);
  const [uploadingImg, setUploadingImg] = useState<boolean>(false);

  const [newCompanyBanner, setNewCompanyBanner] = useState<any>(null);

  useEffect(() => {
    if (!objImg)
      return;

    let fileName = objImg.imageUrl;

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${fileName}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }, [objImg]);


  useEffect(() => {
    let photos: IImage[] | undefined = props.featuredCompany?.layout?.banner;
    if (!photos || !photos.length)
      return;

    setCompanyBannerList(photos);
    setIdCompanyBannerSelected(photos[0]._id);

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${photos[0].imageUrl}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }, []);

  useEffect(() => {
    if (!newCompanyBanner || !props.featuredCompany?._id)
      return;

    setUploadingImg(true);
    const form = new FormData();
    form.append("file", newCompanyBanner);
    form.append("company", props.featuredCompany?._id);

    async function uploadMedia() {
      const resp = await MediaRegister(form);

      let company = props.featuredCompany;
      company.layout.banner = [...companyBannerList, resp];
      const answer = await UpdateCompanyBanner(props.featuredCompany._id, company);

      if (answer.success) {
        setCompanyBannerList([...companyBannerList, resp])
        setIdCompanyBannerSelected(resp._id);
        toast.success(answer.message);
      }

      if (resp) {
        setObjImg(resp);
        setFileKey(fileKey + 1);
      }
    };
    uploadMedia();
  }, [
    newCompanyBanner
  ]);

  function handleFile() {
    if (fileRef.current) {
      fileRef.current.click();
    };
  };

  function cleanForm() {
    // setSubCategoryName("");
    // setSubCatgoryStatus(true);
    return;
  };

  function handleCompanyBanner(item: IImage) {
    if (!item) return cleanForm();

    setIdCompanyBannerSelected(item._id);

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${item.imageUrl}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }

  async function removeCompanyBanner(_id: string) {
    setUploadingImg(true);
    const newList = companyBannerList.filter(item => item?._id !== _id) ?? [];

    setCompanyBannerList(newList);
    handleCompanyBanner(newList?.length ? newList[0] : null);

    const resp = await RemoveMedia(_id);

    toast.success(resp?.message);
  };


  return {
    ...props,
    companyBannerList,
    idCompanyBannerSelected,
    uploadingImg,
    imageUrl,
    fileKey,
    fileRef,
    setCompanyBannerList,
    setIdCompanyBannerSelected,
    removeCompanyBanner,
    handleCompanyBanner,
    setNewCompanyBanner,
    setFileKey,
    handleFile,
  }
}