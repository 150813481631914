import { useState } from "react";
import { TCategoriesEmphasisProps } from "../CategoriesEmphasis";
import { ICategory } from "../../../../interfaces/_index.interfaces";

type TCategoriesEmphasisHooksProps = TCategoriesEmphasisProps & {};
export type TCategoriesEmphasisHooksStates = TCategoriesEmphasisHooksProps & {
  selectedCategory: ICategory | null
  setSelectedCategory: (d: ICategory | null) => void;
};

export const CategoriesEmphasisHooks = ({ ...props }: TCategoriesEmphasisHooksProps): TCategoriesEmphasisHooksStates => {

  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);

  return {
    ...props,
    selectedCategory,
    setSelectedCategory,
  }
};