import { SubscribersDetailsNotesHooks, TSubscribersDetailsHooksStates, TSubscribersDetailsNotesHooksStates } from "../../_index.pages";

import {
  Button,
  Input,
  Spinner,
  Textarea
} from "../../../components/_index.components";
import { Icons } from "../../../assets/icons/_index.icons";
import { formatDate, GENERIC_ID_ } from "../../../utils/_index.utils";


export type TSubscribersDetailsNotesProps = TSubscribersDetailsHooksStates & {};

export default function SubscribersDetailsNotes({ ...props }: TSubscribersDetailsNotesProps) {

  const states: TSubscribersDetailsNotesHooksStates = SubscribersDetailsNotesHooks({ ...props } as TSubscribersDetailsNotesProps);

  return (
    <>
      <div className="w-full h-full p-5 flex flex-col gap-5 nd:p-0">
        <h1 className="text-2xl font-bold">Anotações</h1>
        {
          states.reloadList
            ? <Spinner />
            : states.notesList?.length
              ? <div className="inline-flex gap-8 nd:flex nd:flex-col xl:gap-4">
                <div className="w-9/12 h-full xl:w-[65%] border rounded-lg border-slate-200 flex flex-col p-5 gap-5 nd:w-full nd:p-2">
                  <div className="inline-flex w-full nd:flex nd:flex-col nd:gap-5">
                    <div className="w-1/2 h-auto nd:w-full">
                      <label htmlFor="title">Título</label>
                      <Input
                        id={"title"}
                        name={"title"}
                        type={"text"}
                        setValue={states.setFormTitle}
                        value={states.formTitle}
                        setInValue={states.setInFormTitle}
                        inValue={states.inFormTitle}
                      />
                    </div>
                    <span className="flex gap-5 w-1/2 justify-end items-end xl:flex xl:flex-col xl:gap-0 nd:w-full nd:flex-col nd:gap-0">
                      <p className="whitespace-nowrap">Data de registro da anotação:</p>
                      <p className="whitespace-nowrap">
                        <strong>
                          {formatDate(states.emphasisNote?.created_at)}
                        </strong>
                      </p>
                    </span>
                  </div>
                  <div className="w-full h-auto max-h-[25vh]">
                    <label htmlFor="notes">Nota</label>
                    <Textarea
                      id={"notes"}
                      name={"notes"}
                      setValue={states.setFormNote}
                      value={states.formNote}
                      setInValue={states.setInFormNote}
                      inValue={states.inFormNote}
                    />
                  </div>
                  {
                    states.emphasisNote?._id.includes(GENERIC_ID_) &&
                    <div className="flex w-full justify-end mt-10">
                      <Button
                        icon={Icons.save}
                        text="Salvar"
                        _onClick={states.handleNoteSave}
                        customStyle="h-[4vh] w-auto inline-flex rounded-lg gap-5 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
                        customStyleIcon="text-white"
                      />
                    </div>
                  }
                </div>
                <div className="w-1/4 xl:w-[35%] h-full border rounded-lg border-slate-200 flex flex-col p-5 gap-2 nd:w-full nd:p-2">
                  <div className="flex flex-col gap-2 -mt-3 nd:mt-0">
                    <div className="inline-flex justify-between w-full items-center">
                      <h3>Anotações</h3>
                      <div
                        className="w-auto cursor-pointer"
                        onClick={states.includeNewNote}>
                        <Icons.register className="text-[#26c59f]" size={30} />
                      </div>
                    </div>
                    <hr className="h-px w-full" />
                  </div>
                  <ul className="w-full">
                    {
                      states.notesList
                        ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                        ?.map((item, i) => (
                          <li
                            key={i}
                            className={
                              `inline-flex gap-5 xl:gap-2 nd:gap-2 cursor-pointer items-center hover:bg-[#C5FFF2] 
                              hover:text-black w-full p-2 rounded-lg overflow-hidden
                              ${item?._id === states.emphasisNote?._id ? "bg-[#26c59f] text-white" : "bg-none"}`
                            }
                            onClick={() => states.setEmphasisNote(item)}
                          >
                            <Icons.note />
                            <p>-</p>
                            <p className="overflow-hidden text-ellipsis whitespace-nowrap">{item?.title}</p>
                          </li>
                        ))

                    }
                  </ul>
                </div>
              </div>
              : <div className="w-full h-full flex justify-center items-center">
                <div
                  className="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white cursor-pointer"
                  onClick={states.includeNewNote}>
                  <Icons.register size={30} />
                  <span className="text-xl">Incluir</span>
                </div>
              </div>

        }
      </div>
    </>
  )

};