import {
  ProductsFormRelatedHooks,
  TProductsFormHooksStates,
  TProductsFormRelatedHooksStates
} from "../../_index.pages";

import {
  Image,
} from "../../../components/_index.components";

export type TProductsFormRelatedProps = TProductsFormHooksStates & {};

export default function ProductsFormRelated({ ...props }: TProductsFormRelatedProps) {

  const states: TProductsFormRelatedHooksStates = ProductsFormRelatedHooks({ ...props } as TProductsFormRelatedProps);

  return (
    <>
      <div className="w-full max-h-full overflow-auto flex flex-col gap-3">
        <h2 className="font-bold text-xl">Produtos Relacionados</h2>
        <div className="w-auto h-auto">
          <p className="text-lg">Produtos relacionados é uma tática inteligente para impulsionar as vendas online.</p>
          <p>Identificamos estes itens similares ao produto principal que está sendo visualizado. Eles podem variar em preço, marca e detalhes, mas é possível que o cliente goste de visualiza-los.</p>
        </div>
        <div className="flex flex-col w-full h-full p-2 gap-4">
          <div className="w-full h-full overflow-scroll grid grid-cols-10 gap-2 nd:grid-cols-2">
            {
              states.productsList
                ?.filter(prod => (
                  prod.category === states.featuredProduct.category &&
                  prod._id !== states.featuredProduct._id
                ))
                ?.map((item, i) => (
                  <div
                    // onClick={() => states.handleProductPhoto(item)}
                    key={i}
                    className={
                      `flex flex-col gap-2 w-full bg-slate-200 rounded-md h-[20vh] cursor-pointer p-1 justify-start items-center 
                      hover:filter hover:brightness-90 text-[#26c59f]`
                    }>
                    <div className="w-full min-w-full h-full rounded-md ring-2 ring-white bg-white">
                      {
                        item.image?.length &&
                        <Image
                          path={process.env.REACT_APP_BUCKET_URL + item.image[0]?.imageThumbUrl}
                        />
                      }
                    </div>
                    <p className="whitespace-nowrap overflow-hidden text-ellipsis w-full text-center px-2">
                      {item.name}
                    </p>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}