import { useEffect, useState } from "react";
import { IProductStock } from "../../../interfaces/IProduct.i";
import { GetProductList, GetProductListStock } from "../../../services/_index.services";
import HooksModal from "../../../hooks/modal.hooks";
import { useAuth } from "../../_index.pages";

type TStockPageHooksProps = {};
export type TStockPageHooksStates = TStockPageHooksProps & {
  searchBar: string;
  featuredProduct: IProductStock;
  reloadList: boolean;
  balanceStockProductsTotal: number;
  productsListDisplayed: IProductStock[];
  productsList: IProductStock[];
  itensPerPage: number;
  totalItens: number;
  currentPage: number;
  open: boolean;
  filterStatus: string;
  featuredRoute: string;
  setFeaturedRoute: (d: string) => void;
  setFilterStatus: (d: string) => void;
  close: () => void;
  toggle: () => void;
  setCurrentPage: (d: number) => void;
  setReloadList: (d: boolean) => void;
  setFeaturedProduct: (d: IProductStock) => void;
  setSearchBar: (d: string) => void;
};


export const StockPageHooks = ({ ...props }: TStockPageHooksProps): TStockPageHooksStates => {
  const { authState } = useAuth();
  const [featuredRoute, setFeaturedRoute] = useState<string>("");
  const { close, open, toggle } = HooksModal();

  const [searchBar, setSearchBar] = useState<string>("");

  const [featuredProduct, setFeaturedProduct] = useState<IProductStock>();


  const [balanceStockProductsTotal, setBalanceStockProductsTotal] = useState<number>(0);

  const [productsList, setProductsList] = useState<IProductStock[]>([]);
  const [productsListDisplayed, setProductsListDisplayed] = useState<IProductStock[]>([]);

  const [reloadList, setReloadList] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [serachedItem, setSearchedItem] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("true")


  const itensPerPage = 10;
  const totalItens = productsList?.length;

  const indexOfLastIten = currentPage * itensPerPage;
  const indexOfFirstIten = indexOfLastIten - itensPerPage;

  useEffect(() => {
    const newListCategories: IProductStock[] = serachedItem
      ? productsList
        ?.filter((item) => {
          const buscaUpperCase = serachedItem.toUpperCase();
          const { name, brand } = item;
          return (
            name?.toUpperCase().includes(buscaUpperCase) ||
            brand?.toUpperCase().includes(buscaUpperCase)
          );
        })
        .slice()
      : productsList?.slice(indexOfFirstIten, indexOfLastIten);

    setProductsListDisplayed(newListCategories);
  }, [serachedItem, productsList, indexOfFirstIten, indexOfLastIten]);


  useEffect(() => {
    if (!reloadList || !authState?.user)
      return;

    async function getProductStockList() {

      let visible = filterStatus !== "Todos" ? filterStatus : null;

      let resp = await GetProductListStock({ company: authState.user?.company, visible: visible });
      setProductsList(resp);
      setReloadList(false);
    };

    getProductStockList();
  }, [
    reloadList,
    authState
  ]);

  useEffect(() => {
    setReloadList(true);
  }, [filterStatus])

  return {
    ...props,
    searchBar,
    featuredProduct,
    reloadList,
    balanceStockProductsTotal,
    productsListDisplayed,
    productsList,
    itensPerPage,
    totalItens,
    currentPage,
    open,
    filterStatus,
    featuredRoute,
    setFeaturedRoute,
    setFilterStatus,
    close,
    toggle,
    setCurrentPage,
    setReloadList,
    setFeaturedProduct,
    setSearchBar,
  };
};