import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { IStock } from "../interfaces/_index.interfaces";

export async function StockRegister(stock: IStock) {
  try {
    const response = await api.post("stock", stock);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetStock(_id: string) {
  try {
    const response = await api.get("stock/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};


export async function GetStockCtrlProduct(_id: string) {
  try {
    const response = await api.get("stock/ctrl/" + _id)
    const data = response.data.data.stock

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetStockList() {
  try {
    const response = await api.get("stock")
    const data = response.data.data.stock_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TStocksFilter = {
  company?: string;
  product?: string;
  user?: string;
}

export async function GetStockListWithFilter(filter: TStocksFilter) {
  try {
    const response = await api.post("stock/filter", filter)
    const data = response.data.data.stock_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};
