import { TextareaHooks } from "../_index.components";

export type TTextareaProps = {
  spellCheck?: boolean;
  className?: string;
  placeholder?: string;
  value: string;
  name?: string;
  id: string;
  setValue: (d: any) => void;
  setInValue?: (d: boolean) => void;
  inValue?: boolean;
};

export default function Textarea(props: TTextareaProps) {
  const limiteCaracter = 1000;

  const states = TextareaHooks({ ...props } as TTextareaProps);
  return (
    <>
      <div className="relative w-full h-auto bg-slate-200 rounded-md">
        <textarea
          spellCheck={states.spellCheck ? states.spellCheck : false}
          className={states.className ? states.className : states.classeTextarea}
          placeholder={states.placeholder ? states.placeholder : states.placeholderTextarea}
          value={states.value}
          onChange={(e) => {
            props.setValue(e.target.value)

            if (e.target.value)
              props.setInValue && props.setInValue(false);
          }}
          name={states.name}
          id={states.id}
        />
        <div className="absolute bottom-0 right-0 text-[10px] p-2">
          <p
            className={
              states.value?.length >= limiteCaracter
                ? "text-red-600 font-bold"
                : "text-primary"
            }
          >
            {
              states.value?.length}/{limiteCaracter
            }
          </p>
        </div>
      </div>
    </>
  )
};