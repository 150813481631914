import { TCompanyInfosFormProps } from "../CompanyInfosForm"


type TCompanyInfosFormHooksProps = TCompanyInfosFormProps & {}
export type TCompanyInfosFormHooksStates = TCompanyInfosFormHooksProps & {

}

export const CompanyInfosFormHooks = ({ ...props }: TCompanyInfosFormHooksProps): TCompanyInfosFormHooksStates => {

  return {
    ...props,
  }
}