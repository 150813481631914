import { Icons } from "../../assets/icons/_index.icons";
import {
  Input,
  Button,
  Image,
  Spinner,
  Pagination,
  Modal,
} from "../../components/_index.components";

import {
  _ID,
  formatDate
} from "../../utils/_index.utils";

import {
  ProductsEmphasis,
  ProductsForm,
  ProductsPageHooks,
  StructurePages,
  TProductsPageHooksStates,
  tabList
} from "../_index.pages";

import ImgNoImageAvaible from "../../assets/img/noImage.png";
import { RoutesPath } from "../../routes/AppRoutes.routes";

export type TProductsPageProps = {};

export default function ProductsPage({ ...props }: TProductsPageProps) {

  const states: TProductsPageHooksStates = ProductsPageHooks({ props } as TProductsPageProps);

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full flex flex-col gap-5 relative overflow-hidden nd:p-0">
              <section className="w-full h-auto inline-flex gap-8 justify-center items-center p-5 nd:p-2">
                <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                  <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                  <Input
                    id={"teste"}
                    name={"search"}
                    placeholder="Pesquisar"
                    type={"text"}
                    setValue={states.setSearchBar}
                    value={states.searchBar}
                    style="pl-10"
                  />
                </div>
                <Button
                  icon={Icons.search}
                  text="Filtros"
                  _onClick={() => {
                    states.setFeaturedProduct && states.setFeaturedProduct(null);

                    // return states.toggle && states.toggle()
                  }}
                  customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 text-[#26c59f] ring-1 ring-[#26c59f]"
                  customStyleIcon="text-[#26c59f]"
                />
              </section>
              {
                (states.productsListDisplayed?.length > 0) &&
                <section className="w-full h-[15vh] nd:w-full nd:h-[20vh] flex flex-col gap-1 p-5 nd:p-2">
                  <h4>Produtos em destaque no site</h4>
                  <ProductsEmphasis {...states} />
                </section>
              }
              <div className="w-full h-full flex flex-col gap-3 mt-5  p-5 nd:p-2">
                <div className="w-full inline-flex justify-between items-center">
                  <h1 className="text-2xl">
                    <strong>
                      Produtos
                    </strong>
                  </h1>
                  <Button
                    icon={Icons.register}
                    text="Cadastrar"
                    _onClick={() => {
                      states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos);
                      states.setFeaturedProduct && states.setFeaturedProduct(null);
                      states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.productNew);
                    }}
                    customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 justify-center items-center p-1 px-5 bg-[#26c59f] text-white"
                    customStyleIcon="text-white"
                  />
                </div>
                <div className="w-full h-full gap-2 flex flex-col">
                  {
                    !states.reloadList
                      ? states.productsListDisplayed?.map((item, i) => (
                        <div
                          key={i}
                          className="w-full h-auto min-h-[10vh] max-h-[15vh] rounded-md justify-between inline-flex p-2 gap-8 hover:text-[#26c59f] cursor-pointer bg-slate-200"
                          style={{
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                            backdropFilter: "blur(5.5px)",
                          }}
                          onClick={() => {
                            states.setFeaturedTab && states.setFeaturedTab(_ID.tabInfos)
                            states.setFeaturedProduct && states.setFeaturedProduct(item);
                            states.setFeaturedRoute && states.setFeaturedRoute(RoutesPath.productNew);
                          }}
                        >
                          <div className="inline-flex gap-4 w-auto">
                            <div className="aspect-square w-full max-w-[15vh] h-full max-h-[15vh] rounded-md ring-2 ring-white bg-white">
                              {
                                (item.image?.length > 0)
                                  ? <Image
                                    path={process.env.REACT_APP_BUCKET_URL + item.image[0].imageThumbUrl}
                                  />
                                  : <Image path={ImgNoImageAvaible} />
                              }

                            </div>
                            <div className="w-auto h-full flex flex-col">
                              <span>
                                <strong>
                                  {item.name}
                                </strong>
                              </span>
                              <p
                                className="text-lg text-gray-500 nd:hidden overflow-hidden text-ellipsis line-clamp-3 xl:line-clamp-2"
                                dangerouslySetInnerHTML={{ __html: item?.description }}
                              />
                            </div>
                          </div>

                          <div className="w-auto h-full flex flex-col p-2">
                            <div className="inline-flex gap-4 nd:hidden">
                              <span>Registrado:</span>
                              <span>{formatDate(item.createdAt, { onlyDate: true })}</span>
                            </div>
                            <div className="inline-flex gap-4 nd:hidden">
                              <span>Atualizado:</span>
                              <span>{formatDate(item.updatedAt, { onlyDate: true })}</span>
                            </div>
                            <div className="inline-flex gap-4 justify-between">
                              <span className="nd:hidden">Visibilidade:</span>
                              <div
                                className={
                                  `${item.visible ? "bg-green-300" : "bg-red-400"} 
                                flex justify-center items-center w-auto min-w-[50%] max-h-[75%] h-auto rounded-md text-white p-1`
                                }
                              >
                                <p>
                                  {
                                    item.visible
                                      ? "Visivel"
                                      : "Inativo"
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      : <Spinner />
                  }
                </div>
              </div>
              {
                (states.productsListDisplayed?.length > 0) &&
                <Pagination
                  itensPerPage={states.itensPerPage}
                  totalItens={states.totalItens}
                  setCurrentPage={states.setCurrentPage}
                  currentPage={states.currentPage}
                />
              }
              {
                states.featuredRoute === RoutesPath.productNew &&
                <div className="w-full h-full max-w-full flex flex-col absolute p-2 z-10 bg-slate-100 overflow-x-hidden gap-4 nd:p-2  ">
                  <h1 className="text-bold text-3xl">{states.featuredProduct?._id ? states.featuredProduct?.name : "Novo Produto"}</h1>
                  <nav className="inline-flex justify-between w-full">
                    <ul className="inline-flex text-gray-400 gap-3">
                      <li>
                        <button
                          onClick={() => {
                            states.setFeaturedProduct(null);
                            states.setFeaturedRoute(RoutesPath.products);
                          }}
                        >
                          <span>Produtos</span>
                        </button>
                      </li>
                      <li>&gt;</li>
                      <li className="text-black">{states.featuredProduct?._id ? states.featuredProduct?.name : "Novo"}</li>
                    </ul>
                    <Button
                      text="Voltar"
                      _onClick={() => {
                        states.setFeaturedProduct(null);
                        states.setFeaturedRoute(RoutesPath.products);
                      }}
                      icon={Icons.back}
                    />
                  </nav>
                  <div className="mt-1 z-20 absolute nd:right-2 w-full pr-5 nd:mt-24 nd:pr-0">
                    <div className="inline-flex w-full justify-end pr-20 nd:pr-0">
                      {
                        tabList.map((item, i) => {
                          if ((
                            !states.featuredProduct?._id &&
                            (
                              item._id === _ID.tabPhotos ||
                              item._id === _ID.tabRelated ||
                              item._id === _ID.tabStock
                            )
                          )) return;


                          return (
                            <div
                              onClick={() => states.setFeaturedTab(item._id)}
                              className={
                                `cursor-pointer hover:text-[#67a0cf] ring-slate-200 ring-1 hover:ring-[#67a0cf] p-2 rounded-t-md
                                ${item._id === states.featuredTab ? "text-[#26c59f]" : "text-primary"}`
                              }
                              key={i}>
                              <p>
                                {
                                  item.nome
                                }
                              </p>
                            </div>
                          )
                        })
                      }
                    </div>
                    <hr className="h-px" />
                  </div>
                  <ProductsForm
                    {...states}
                  />
                </div>
              }
            </div>
          </>
        }
      />
    </>
  )
};