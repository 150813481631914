import { useEffect, useState } from "react";
import { Icons } from "../../../../assets/icons/_index.icons";
import { TMenuSystemModulesProps } from "../MenuSystemModules";
import { HooksModal } from "../../../../hooks/_index.hooks";
import { MODULES } from "../../../../utils/modules.utils";
import { RoutesPath } from "../../../../routes/AppRoutes.routes";
import { CompanyPages, DashboardPages } from "../../../../pages/_index.pages";
import { _ID } from "../../../../utils/_id.utils";

// import {
//   ChamadosPagina,
//   ConfiguracoesPagina,
//   EquipamentosPagina
// } from "../../../../pages/_index.pages";

type TMenuSistemaModulosHooksProps = TMenuSystemModulesProps & {}
type TMenuSistemaModulosHooksStates = TMenuSistemaModulosHooksProps & {
  modulesList: IModules[],
  featuredModule: IModules
  open: boolean;
  hoveredItem: IModules | null;
  setHoveredItem: (data: IModules | null) => void;
  toggle: () => void;
  close: () => void;
  setFeaturedModule: (data: IModules) => void;
}

interface IModules {
  _id: string | null;
  module: string;
  onClick: any;
  icon: any;
  subdirectory: string;
};

export const MenuSystemModulesHooks = (props: TMenuSistemaModulosHooksProps): TMenuSistemaModulosHooksStates => {
  const { open, toggle, close } = HooksModal();

  const [hoveredItem, setHoveredItem] = useState<IModules | null>(null);

  const modulesList: IModules[] = [
    {
      _id: _ID.moduleDashboard,
      module: MODULES.dashboard,
      onClick: DashboardPages,
      icon: Icons.dashboard,
      subdirectory: RoutesPath.dashboard,
    },
    {
      _id: _ID.moduleCompany,
      module: MODULES.company,
      onClick: CompanyPages,
      icon: Icons.company,
      subdirectory: RoutesPath.company
    },
    {
      _id: _ID.moduleCategories,
      module: MODULES.categories,
      onClick: null,
      icon: Icons.category,
      subdirectory: RoutesPath.categories
    },
    {
      _id: _ID.moduleProducts,
      module: MODULES.products,
      onClick: null,
      icon: Icons.product,
      subdirectory: RoutesPath.products
    },
    {
      _id: _ID.moduleOrders,
      module: MODULES.orders,
      onClick: null,
      icon: Icons.orders,
      subdirectory: RoutesPath.orders
    },
    {
      _id: _ID.moduleStock,
      module: MODULES.stock,
      onClick: null,
      icon: Icons.stock,
      subdirectory: RoutesPath.stock
    },
    {
      _id: _ID.moduleUsers,
      module: MODULES.users,
      onClick: null,
      icon: Icons.user,
      subdirectory: RoutesPath.users
    },
    {
      _id: _ID.moduleSubscribers,
      module: MODULES.subscribers,
      onClick: null,
      icon: Icons.subscribers,
      subdirectory: RoutesPath.subscribers
    },
    {
      _id: _ID.moduleInputs,
      module: MODULES.inputs,
      onClick: null,
      icon: Icons.input,
      subdirectory: RoutesPath.inputs
    },
  ];

  const [featuredModule, setFeaturedModule] = useState<IModules | undefined>({} as IModules);

  useEffect(() => {
    const locationPath = window.location.pathname;
    let module = modulesList.find(item => item.subdirectory === locationPath);

    setFeaturedModule(
      module ??
      modulesList.find(item => item.subdirectory === RoutesPath.dashboard)
    );

  }, [window.location.pathname]);

  return {
    ...props,
    modulesList,
    featuredModule,
    open,
    hoveredItem,
    setHoveredItem,
    toggle,
    close,
    setFeaturedModule,
  } as TMenuSistemaModulosHooksStates;
}
