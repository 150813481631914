import { toast } from "react-toastify";

import { api } from "./_axios.services";

import { IInput } from "../interfaces/_index.interfaces";


export type TGetInputsListProps = {
  company: string;
  purchaseDate?: Date;
}

export async function GetInputsList(item: TGetInputsListProps) {
  try {
    const response = await api.get("inputs", { params: item });
    const data: IInput[] = response.data.data.inputs_list;

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetInput(_id: string) {
  try {

    const response = await api.get("inputs/" + _id)
    const data: IInput = response.data.data.input

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};


export async function CreateInput(item: IInput) {
  try {

    const response = await api.post("inputs", item)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateInput(item: IInput) {
  try {

    const response = await api.put("inputs", item)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};