const INPUT_CATEGORIES = {
  DISPOSIBLE: "DISPOSIBLE",
  FRUIT: "FRUIT",
  CONFECTONERY: "CONFECTONERY",
  DAIRY: "DAIRY",
};

export const INPUT_CATEGORIES_LIST = [
  {
    value: INPUT_CATEGORIES.DISPOSIBLE,
    label: "Descartávis"
  },
  {
    value: INPUT_CATEGORIES.FRUIT,
    label: "Frutas"
  },
  {
    value: INPUT_CATEGORIES.CONFECTONERY,
    label: "Confeitaria"
  },
  {
    value: INPUT_CATEGORIES.DAIRY,
    label: "Laticínios"
  },
]