import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { IUser } from "../interfaces/IUser.i";

export async function UserRegister(user: IUser) {
  try {
    const response = await api.post("users", user);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetUser(_id: string) {
  try {
    const response = await api.get("users/" + _id)
    const data = response.data.data.user;

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetUserList(item?: any) {
  try {
    let query: string = "";
    if (item?.company) query = `?company=${item.company}`

    const response = await api.get("users" + query)
    const data = response.data.data.user_list;


    let newList = [];
    if (Array.isArray(data)) {
      newList = data.map(({ name, lastname, _id, ...obj }) => ({
        value: _id,
        label: name + ' ' + lastname,
        name,
        _id,
        lastname,
        ...obj
      }));
    };

    return newList;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function RemoveUser(_id: string) {
  try {
    const response = await api.delete("users/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateUser(_id: string, user: IUser) {
  try {
    const response = await api.put("users/" + _id, user)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};