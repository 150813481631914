import {
  ECOMMERCE_CMS_SEEDZ_TECH,
  LOCALSTORAGE_ROUTES,
} from '../../utils/_index.utils';


import { TEMA_PADRAO } from '../../layout/index.layout';
import { MenuSystemModules } from './Partials/_index.partials';


export type TMenuProps = {
  setActiveMenu: (data: boolean) => void;
  activeMenu: boolean;
};

const theme = localStorage.getItem(
  ECOMMERCE_CMS_SEEDZ_TECH + LOCALSTORAGE_ROUTES.theme
) || TEMA_PADRAO;

export default function SystemMenu(props: TMenuProps) {
  return (
    <>
      <div className='w-full h-full justify-between items-center flex flex-col bg-gray-200 p-6 nd:p-2 nd:items-center nd:justify-center nd:w-screen'>
        <div className='flex flex-col w-full'>
          <MenuSystemModules
            setActiveMenu={props.setActiveMenu}
            activeMenu={props.activeMenu}
          />
        </div>
        <div className="px-2 cursor-pointer flex justify-center items-center pb-10 flex-col nd:hidden">
          <div className="flex justify-center items-end w-full text-gray-400">
            <p className={`${props.activeMenu ? "text-md" : "text-[10px]"}`}>
              {
                process.env.REACT_APP_VERSION
              }
            </p>
          </div>
        </div>
      </div>

    </>
  );
}

