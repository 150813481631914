import { TCatgoriesFormInfosProps } from "../CategoriesFormInfos";


type TCatgoriesFormInfosHooksProps = TCatgoriesFormInfosProps & {};
export type TCatgoriesFormInfosHooksStates = TCatgoriesFormInfosHooksProps & {

};


export const CatgoriesFormInfosHooks = ({ ...props }: TCatgoriesFormInfosHooksProps): TCatgoriesFormInfosHooksStates => {



  return {
    ...props,

  };
};