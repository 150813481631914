import { Icons } from "../../assets/icons/_index.icons";
import { formatDate } from "../../utils/date.utils";
import { StructurePages } from "../_index.pages";
import { InputsNew, InputsPagesHooks, TInputsPagesHooksStates } from "./_index.inputs";

import {
  Button,
  Input,
  Pagination,
  Selection,
  Spinner,
} from "../../components/_index.components";
import { formatCurrency } from "../../utils/currence.utils";
import { Navigate } from "react-router-dom";
import { RoutesPath } from "../../routes/AppRoutes.routes";


export type TInputsPagesProps = {};

export default function InputsPages({ ...props }) {

  const states: TInputsPagesHooksStates = InputsPagesHooks({ ...props } as TInputsPagesProps);

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-screen flex flex-col p-8 gap-5 relative overflow-hidden nd:p-2">
              <h1 className="text-bold text-3xl">Insumos</h1>
              <section className="flex flex-col gap-5">
                <div className="w-full h-auto inline-flex gap-8 justify-center items-center">
                  <div className="rounded-lg ring-1 ring-header bg-datagrid-background w-full relative">
                    <Icons.search className="absolute t-1 l-1 h-full text-slate-300 ml-2 z-10" size={30} />
                    <Input
                      id={"teste"}
                      name={"search"}
                      placeholder="Pesquisar"
                      type={"text"}
                      setValue={states.setSearchBar}
                      value={states.searchBar}
                      style="pl-10 w-1/5"
                    />
                  </div>
                  <Button
                    icon={Icons.register}
                    text="Novo Cadastro"
                    _onClick={() => states.setFeaturedRoute(RoutesPath.inputsNew)}
                    customStyle="h-[4vh] w-auto inline-flex rounded-lg space-x-1 whitespace-nowrap justify-center items-center p-1 px-5 text-white bg-[#26C59F] nd:text-xs"
                    customStyleIcon="text-white"
                  />
                </div>
              </section>
              {
                !states.reloadList
                  ? <table className="w-full nd:text-xs">
                    <thead className="text-[#8B8B8B]">
                      <th>Ingrediente</th>
                      <th className="nd:hidden">Valor Unitário</th>
                      <th>Qtde.</th>
                      <th>Valor Total</th>
                      <th>Data Compra</th>
                      {/* <th /> */}
                    </thead>
                    <tbody>
                      {
                        states.inputsListDisplayed
                          ?.sort((a, b) => new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime())
                          ?.map((item, i) => (
                            <tr
                              key={i}
                              className="border border-slate-200 rounded-b-lg hover:bg-[#C5FFF2] cursor-pointer"
                              onMouseEnter={() => states.setFocusedLine(item)}
                              onClick={() => {
                                states.setFeaturedInput && states.setFeaturedInput(item);
                                states.setFeaturedRoute(RoutesPath.inputsNew);
                              }}
                            >
                              <td className="p-2">{item.name}</td>
                              <td className="text-center p-2 nd:hidden">{formatCurrency(item.unitValue)}</td>
                              <td className="text-center p-2">{item.amount}</td>
                              <td className="text-center p-2">{formatCurrency(item.amount * item.unitValue)}</td>
                              {/* <td className="text-center p-2">{(item.amount * item.unitValue).toFixed(2)}</td> */}
                              <td className="text-center p-2">
                                {formatDate(new Date(item.purchaseDate).setDate(new Date(item.purchaseDate).getDate() + 1), { onlyDate: true })}
                              </td>
                              {/* <th className="p-2 relative">
                            <button
                              onClick={() => {
                                states.setShowMore(!states.showMore)
                                states.setShowMoreItem(item)
                              }}
                            >
                              <Icons.delete color="#cf1414" />
                            </button>
                            {
                              (
                                (
                                  states.showMore &&
                                  (
                                    states.showMoreItem?.codMotorista === item._id
                                  ) && (
                                    <div
                                      ref={states.ref}
                                      className="absolute w-[6vw] h-[10vh] z-10 bg-datagrid-background -mt-2 -ml-20 rounded-lg shadow-2xl"
                                    >
                                      <ul className="flex flex-col items-end justify-end w-full h-full p-1 gap-3">
                                        <li className="cursor-pointer w-full flex text-start hover:bg-sec-background p-1 rounded-lg">
                                          <p className="font-normal">Editar</p>
                                        </li>
                                        <li className="cursor-pointer w-full flex text-start hover:bg-sec-background p-1 rounded-lg">
                                          <p className="text-[#FF3B3B] font-normal">Deletar</p>
                                        </li>
                                      </ul>
                                    </div>
                                  )
                                )
                              )
                            }
                          </th> */}
                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                  : <Spinner />
              }
              {
                states.featuredRoute === RoutesPath.inputsNew && <InputsNew {...states} />
              }
            </div>
            {
              (
                (states.inputsListDisplayed?.length > 0) &&
                (states.featuredRoute !== RoutesPath.inputsNew)
              ) && (
                <Pagination
                  itensPerPage={states.itensPerPage}
                  totalItens={states.totalItens}
                  setCurrentPage={states.setCurrentPage}
                  currentPage={states.currentPage}
                />
              )
            }
          </>

        }
      />
    </>
  )
};