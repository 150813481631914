import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import jsPDF from "jspdf";

import { TSubscribersReceiptProps } from "../SubscribersReceipt";

import {
  ICompany,
  IPayment,
  ISubscribeOrders
} from "../../../../interfaces/_index.interfaces";

import {
  GetCompany,
  GetPaymentList,
  GetSubscribersOrders
} from "../../../../services/_index.services";
import { convertDate, pastDaysCalculateDate } from "../../../../utils/_index.utils";


type TSubscribersReceiptHooksProps = TSubscribersReceiptProps & {};
export type TSubscribersReceiptHooksStates = TSubscribersReceiptHooksProps & {
  subscriberOrders: ISubscribeOrders[];
  subscriberPayments: IPayment[];
  company: ICompany;
  releaseReceipt: boolean;
  initDatePeriod: string;
  endDatePeriod: string;
  totalAmountDue: number;
  totalConsumed: number;
  totalAmountPaid: number;
  receiptRef: React.RefObject<HTMLDivElement>;
  setReleaseReceipt: (d: boolean) => void;
  setInitDatePeriod: (d: string) => void;
  setEndDatePriod: (d: string) => void;
  generateReceiptPDF: () => void;
};

export const SubscribersReceiptHooks = ({ ...props }: TSubscribersReceiptHooksProps): TSubscribersReceiptHooksStates => {
  const receiptRef = useRef<HTMLDivElement>(null);

  const [company, setCompany] = useState<ICompany>();
  const [subscriberOrders, setSubscribersOrders] = useState<ISubscribeOrders[]>([]);
  const [subscriberPayments, setSubscriberPayments] = useState<IPayment[]>([]);
  const [releaseReceipt, setReleaseReceipt] = useState<boolean>(false);
  const [releaseFindCompany, setReaseFindCompany] = useState<boolean>(false);

  const [initDatePeriod, setInitDatePeriod] = useState<string>(convertDate(pastDaysCalculateDate(15)));
  const [endDatePeriod, setEndDatePriod] = useState<string>(convertDate(new Date()));

  const [totalAmountDue, setTotalAmountDue] = useState<number>(0);
  const [totalConsumed, setTotalConsumed] = useState<number>(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);

  useEffect(() => {
    if (!props.featuredSubscribe)
      return;

    function areDatesValid() {
      const start = new Date(initDatePeriod);
      const end = new Date(endDatePeriod);
      return !isNaN(start.getTime()) && !isNaN(end.getTime());
    };

    if (!areDatesValid())
      return;

    setReleaseReceipt(false);
    async function getSubscribeOrders() {
      const resp = await GetSubscribersOrders({
        company: props.featuredSubscribe?.user?.company,
        user: props.featuredSubscribe?.user?._id,
        initDate: initDatePeriod,
        endDate: endDatePeriod,
      });

      setSubscribersOrders(resp?.products ?? []);
      setTotalConsumed(
        resp?.products
          ?.reduce((sum, item) => sum + item.totalValue, 0)
      );
    };

    async function getPaymentsList() {
      const resp = await GetPaymentList({
        user: props.featuredSubscribe?.user?._id,
        company: props.featuredSubscribe?.user?.company,
        initDate: initDatePeriod,
        endDate: endDatePeriod,
      });

      setSubscriberPayments(resp);
      setTotalAmountPaid(
        resp?.reduce((sum, item) => sum + item.value, 0)
      );

      setReaseFindCompany(true);

      if (releaseFindCompany)
        setReleaseReceipt(true);
    };

    getSubscribeOrders();
    getPaymentsList();
  }, [
    props.featuredSubscribe,
    props.authState,
    initDatePeriod,
    endDatePeriod,
  ]);

  useEffect(() => {
    if (!props.authState || !releaseFindCompany)
      return;

    async function getCompany() {
      const resp = await GetCompany(props.authState?.user?.company);
      setCompany(resp?.data?.company);

      setReleaseReceipt(true);
    };

    getCompany();
  }, [
    props.authState,
    releaseFindCompany
  ]);

  useEffect(() => {
    setTotalAmountDue(
      totalConsumed - totalAmountPaid
    );
  }, [
    totalConsumed,
    totalAmountPaid,
  ]);

  async function generateReceiptPDF() {
    if (receiptRef.current) {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      pdf.html(receiptRef.current, {
        callback: (pdf) => {
          const blob = pdf.output('blob');
          const file = new File([blob], 'recibo.pdf', { type: 'application/pdf' });

          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator.share({
              files: [file],
              title: 'Recibo Compartilhado',
              text: 'Aqui está o recibo solicitado.',
            }).catch((error) => {
              toast.error(error);
              console.error('Erro ao compartilhar:', error);
            });
          } else {
            toast.error("O navegador não suporta compartilhamento de arquivos.");
            console.error('O navegador não suporta compartilhamento de arquivos.');
          }
        },
        x: 10,
        y: 10,
        width: 500,
        windowWidth: 1200,
      });
    }
  };

  return {
    ...props,
    subscriberOrders,
    subscriberPayments,
    company,
    releaseReceipt,
    receiptRef,
    initDatePeriod,
    endDatePeriod,
    totalAmountDue,
    totalConsumed,
    totalAmountPaid,
    setInitDatePeriod,
    setEndDatePriod,
    setReleaseReceipt,
    generateReceiptPDF,
  };
};