import { useEffect, useState } from "react";

import { TSubscribersDetailsOrdersProps } from "../SubscribersDetailsOrders";
import { ISubscribeOrders } from "../../../../interfaces/ISubscribers.i";
import { GetSubscribersOrders } from "../../../../services/subscribers.services";

type TSubscribersDetailsOrdersHooksProps = TSubscribersDetailsOrdersProps & {};
export type TSubscribersDetailsOrdersHooksStates = TSubscribersDetailsOrdersHooksProps & {
  subscriberOrders: ISubscribeOrders[];
  loadingOrdersList: boolean;
  setLoadingOrdersList: (d: boolean) => void;
};

export const SubscribersDetailsOrdersHooks = ({ ...props }: TSubscribersDetailsOrdersHooksProps): TSubscribersDetailsOrdersHooksStates => {

  const [subscriberOrders, setSubscribersOrders] = useState<ISubscribeOrders[]>();
  const [loadingOrdersList, setLoadingOrdersList] = useState<boolean>(true);

  useEffect(() => {
    if (!props.featuredSubscribe)
      return;

    async function getSubscribeOrders() {
      const resp = await GetSubscribersOrders({
        company: props.featuredSubscribe?.user?.company,
        user: props.featuredSubscribe?.user?._id,
      });

      setSubscribersOrders(resp?.products ?? []);
      setLoadingOrdersList(false);
    };

    getSubscribeOrders();
  }, [props.featuredSubscribe]);

  return {
    ...props,
    subscriberOrders,
    loadingOrdersList,
    setLoadingOrdersList,
  };
};