import { TUsersFormsOrdersProps } from "../UsersFormOrders";

type TUsersFormsOrdersHooksProps = TUsersFormsOrdersProps & {};
export type TUsersFormsOrdersHooksStates = TUsersFormsOrdersHooksProps & {

};

export const UsersFormsOrdersHooks = ({ ...props }: TUsersFormsOrdersHooksProps): TUsersFormsOrdersHooksStates => {


  return {
    ...props,
  }
};