import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { INote } from "../interfaces/_index.interfaces";

export async function NoteRegister(notes: INote) {
  try {
    const response = await api.post("notes", notes);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetNote(_id: string) {
  try {
    const response = await api.get("notes/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

type TGetNoteListProps = {
  company: string;
  user: string;
};

export async function GetNoteList(props: TGetNoteListProps) {
  try {
    const response = await api.get("notes", { params: props })
    const data = response.data.data.notes_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TNotesFilter = {
  company?: string;
  product?: string;
  user?: string;
}

export async function GetNoteListWithFilter(filter: TNotesFilter) {
  try {
    const response = await api.post("notes/filter", filter)
    const data = response.data.data.notes_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};
