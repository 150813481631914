import { useEffect, useState } from "react";
import { TSubscribersDetailsPaymentsProps } from "../SubscribersDetailsPayments";
import { IPayment } from "../../../../interfaces/IPayment.i";
import { GetPaymentList } from "../../../../services/payment.services";

type TSubscribersDetailsPaymentsHooksProps = TSubscribersDetailsPaymentsProps & {};
export type TSubscribersDetailsPaymentsHooksStates = TSubscribersDetailsPaymentsHooksProps & {
  subscriberPayments: IPayment[];
  loadingPayments: boolean;
};

export const SubscribersDetailsPaymentsHooks = ({ ...props }: TSubscribersDetailsPaymentsHooksProps): TSubscribersDetailsPaymentsHooksStates => {

  const [subscriberPayments, setSubscriberPayments] = useState<IPayment[]>([]);
  const [loadingPayments, setLoadingPayments] = useState<boolean>(true);

  useEffect(() => {
    if (!props.featuredSubscribe?._id)
      return;

    async function getPaymentsList() {
      const resp = await GetPaymentList({
        user: props.featuredSubscribe?.user?._id,
        company: props.featuredSubscribe?.user?.company
      });

      setSubscriberPayments(resp);
      setLoadingPayments(false);
    };

    getPaymentsList();
  }, [props.featuredSubscribe?._id]);

  return {
    ...props,
    subscriberPayments,
    loadingPayments,
  };
};