import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type TTextEditorProps = {
  value: string;
  onChange: (item: string) => void;
  inValue?: boolean;
  style?: string;
  setInValue?: (d: boolean) => void;
};

export default function TextEditor(props: TTextEditorProps) {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (props.value)
      setText(props.value);

  }, [props.value]);

  useEffect(() => {
    function collectDataOnSelectBox() {
      if (typeof props.onChange === 'function') {
        props.onChange(text);
      };
    };

    collectDataOnSelectBox();
  }, [text]);

  const modulos = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formatos = [
    'header', 'font',
    'list', 'bullet',
    'bold', 'italic', 'underline',
    'link', 'image'
  ];

  return (
    <ReactQuill
      className={props.style ?? `h-auto p-1 rounded-md w-full bg-slate-200 ${props.inValue ? "ring-2 ring-negative" : null}`}
      value={text}
      onChange={setText}
      modules={modulos}
      formats={formatos}
      placeholder="..."
    />
  );
};