import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { IPayment } from "../interfaces/IPayment.i";

type TGetPaymentListFilter = {
  company?: string;
  user?: string;
  card?: string;
  initDate?: string;
  endDate?: string;
};

export async function GetPaymentList(item: TGetPaymentListFilter) {
  try {
    const response = await api.get("payment", { params: item })
    const data: IPayment[] = response.data.data.payments_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetPayment(_id: string) {
  try {

    const response = await api.get("payment/" + _id)
    const data: IPayment = response.data.data.payment

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function CreatePayment(item: IPayment) {
  try {
    const response = await api.post("payment", item)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};