import { Navigate } from "react-router-dom";
import { TMenuProps } from "../SystemMenu";
import { MenuSystemModulesHooks } from "./_index.partials";


export type TMenuSystemModulesProps = TMenuProps & {
  activeMenu: boolean;
};

export default function MenuSystemModules(props: TMenuSystemModulesProps) {

  const states = MenuSystemModulesHooks({ ...props } as TMenuSystemModulesProps);

  return (
    <>
      {
        states.featuredModule &&
        <Navigate to={states.featuredModule.subdirectory} replace={true} />
      }
      <div className={
        `pt-10 w-auto min-w-8 min-h-8 flex flex-col space-y-6 justify-center 
        nd:flex-row nd:pt-0 nd:space-y-0 nd:justify-between nd:gap-2
        ${states.activeMenu ? "items-start" : "items-center"} p-2`
      }>
        {
          states.modulesList?.map((item, i) => (
            <div
              key={i}
              className={`relative inline-flex space-x-4 w-full
              ${states.activeMenu ? "justify-start items-start" : "justify-center items-center"}
            `}
              onMouseEnter={() => states.setHoveredItem && states.setHoveredItem(item)}
              onMouseLeave={() => states.setHoveredItem && states.setHoveredItem(null)}
            >
              <button
                className={
                  `inline-flex space-x-4 w-full h-full nd:w-6
                ${states.activeMenu ? "justify-start items-start" : "justify-center items-center"}
                `
                }
                onClick={() => states.setFeaturedModule(item)}
              >
                {
                  (
                    states.activeMenu && states.featuredModule?.module === item.module
                  ) ? <div className="left-0 w-[4%] h-full bg-[#26c59f] z-10" />
                    : <></>
                }
                <item.icon
                  className={
                    `w-auto h-auto 2xl:w-[25px] 2xl:h-[25px] nd:w-full ${states.featuredModule?.module === item.module
                      ? "text-[#26c59f]"
                      : "text-[#7F7F7F]"
                    }`
                  }
                />
                {
                  states.activeMenu
                    ?
                    <p className={
                      `${states.featuredModule?.module === item.module
                        ? "text-[#26c59f]"
                        : "text-[#7F7F7F]"
                      } whitespace-nowrap`
                    }>
                      {
                        item.module
                      }
                    </p>
                    : <></>
                }
                {
                  (
                    states.hoveredItem?._id === item._id &&
                    !states.activeMenu
                  ) &&
                  <div className="absolute min-w-[6vw] left-[0.5vw] top-0 mt-6 bg-[header-background] p-2 rounded shadow-lg z-50">
                    <p className="text-[#26c59f]">{item.module}</p>
                  </div>
                }
              </button>
            </div>
          ))
        }
      </div>
    </>
  )
};