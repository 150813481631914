import {
  TUsersFormHooksStates,
  TUsersFormsOrdersHooksStates,
  UsersFormsOrdersHooks
} from "../../_index.pages";

export type TUsersFormsOrdersProps = TUsersFormHooksStates & {};

export default function UsersFormsOrders({ ...props }: TUsersFormsOrdersProps) {

  const states: TUsersFormsOrdersHooksStates = UsersFormsOrdersHooks({ ...props } as TUsersFormsOrdersProps);

  return (
    <>
      <div className="w-full h-full flex flex-col gap-3">
        <div>Pedidos</div>
      </div>
    </>
  )
};